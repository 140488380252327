import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Box, Grid, Paper, IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js'
import { getOwnershipList } from './data.js'

export default function ListOwnerships(props = {searchFilter: undefined, rowsPerPage: 15, pageIndex: 0 }) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [dataRows, setDataRows] = useState([])
  const roleDetails = useContext(RoleContext)


  const listColumns = useMemo(() => [
    {
      accessorKey: 'ownershipName',
      header: 'Name',
      size: 100,
      grow: true,
      editable: false,
      filterVariant: 'text',
      Cell: ({ row }) => (
        <Link to={"/ownership/edit/" + row.original.uuid + '/details'}>
          {row.original.ownershipName}
        </Link>
      )
    },
    {
      accessorKey: 'ownershipId',
      header: 'ID',
      size: 75,
      grow: false,
      editable: false,
      filterVariant: 'text',
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'address',
      header: 'Address',
      editable: false,
      filterVariant: 'text',
      size: 175,
      grow: true,
      Cell: ({ row }) => (
        <Fragment>{[row.original.hqAddress, row.original.hqCity, row.original.hqState].filter(n => n && n !== '').join(', ')}</Fragment>
      )
    },
    {
      accessorKey: 'isProducing',
      header: 'Producing',
      size: 80,
      grow: true,
      editable: false,
      filterVariant: 'text',
      Cell: ({ row }) => {
        return row.original.isProducing === 'Y' ? 'Yes' : 'No'
      },
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    }
  ])


  useEffect(() => {
    (async () => {
      if (isMounted()) {
        props.startLoading()
        const search = props.searchFilter ?? {
          isActive: true
        }
        const list = await getOwnershipList(search)
        if(list) setDataRows(list)
        props.stopLoading()
      }
    })()
  }, [])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      sorting: [{ id: 'ownershipName', desc: false }],
      pagination: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 15
      }
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });

  //  <AddRecordDialog title={"Add Employee User"} content={addRecordForm} open={props.showAddRecord} hideDialog={hideAddRecord} createRecord={addAssessment} buttonLoading={buttonLoading}/>
  return (
    <Grid container rowSpacing={2} >
      <Grid item xs={true} md={true}>
        <MaterialReactTable table={table}/>
      </Grid>
    </Grid>
  )

}