
export const eventStatuses = ["Active", "Closed"]
export const rcaCapChoices = [
  { id: 1, label: "Waiting" },
  { id: 2, label: "Received" },
  { id: 3, label: "Not Needed" }
]
export const coqStatuses = [
  { id: 1, label: "Pending" },
  { id: 2, label: "N/A" },
  { id: 3, label: "Enter Cost" },
]
export const lessonsLearnedChoices = [
  { id: 1, label: "Waiting" },
  { id: 2, label: "Received" },
  { id: 3, label: "N/A" }
]

export const capValidationChoices = [
  { id: 1, label: "Pending" },
  { id: 2, label: "Completed" },
  { id: 3, label: "N/A" }
]

const mapChoices = (list) => {
  return list.reduce((s, item) => {
    s[item.id] = item.label
    return s
  }, {})
}

export const RCA_CAP_CHOICE = mapChoices(rcaCapChoices)
export const COQ_STATUS = mapChoices(coqStatuses)
export const LESSONS_LEARNED_CHOICE = mapChoices(lessonsLearnedChoices)
export const CAP_VALIDATION_CHOICE = mapChoices(capValidationChoices)

export const rcaCapIsOverdue = (givenDate) => {
  const date = new Date(givenDate).getTime();
  const currentDate = new Date().getTime();
  const diffTime = currentDate - date;
  const diffDays = diffTime / (1000 * 60 * 60 * 24);
  return diffDays >= 7;
};