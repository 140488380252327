import { default as React, useContext, useEffect, useState, useCallback } from 'react'
import { Card, Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import Category from '../../form/Category';
import { MuiTelInput } from 'mui-tel-input';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { validateFieldChange, componentTextValue, checkPhoneError } from '../../form/Form'
import Autocomplete from '@mui/material/Autocomplete';
import CertificationControl from '../profile-components/CertificationControl';
import { useNavigate } from 'react-router-dom';
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import EventBus from '../../utils/EventBus'
import { getOpenAssessment, saveManufacturerProfile } from '../data';

export default function ManufacturerProfileSection(props = {
  manufacturerProfile: undefined, 
  setManufacturerProfile: undefined,
  openAssessment: undefined,
  manufacturer: undefined, 
  editMode: false, 
  setSectionIsEditable: undefined, 
  onStateValidation: undefined, 
  startLoading: undefined, 
  stopLoading: undefined
}) {
  const navigate = useNavigate()
  const [valid, setValid] = useState(false)
  const roleDetails = useContext(RoleContext)
  const [manufacturerProfile, setManufacturerProfile] = useState()

  const { 
    manufacturerProfile: pageManufacturerProfile, 
    setManufacturerProfile: setPageManufacturerProfile, 
    openAssessment, editMode, manufacturer, setSectionIsEditable, 
    onStateValidation, startLoading, stopLoading } = props

  const validateForm = (_record = false) => {
    let _valid = false
    
    if ( !_record ) _record = manufacturerProfile

    const validations = [
      true
    ]
    _valid = validations.every(v => {
      return v
    })

    setValid(_valid)
    if (props.onStateValidation !== undefined) {
      props.onStateValidation(_record, _valid)
    }
    return _valid
  }

  const [fieldErrors, setFieldErrors] = useState({})
  const setError = (value, field, validator) => {
    console.log('setError', value, field, validator)
    const isError = checkError(value, validator)
    const _errors = {...fieldErrors}
    if (isError) {
      _errors[field] = true
    } else {
      delete _errors[field]
    }
    setFieldErrors(_errors)
  }

  const REQUIRED_TEXT = '* Required'
  const INVALID_PHONE_TEXT = 'Invalid/Incomplete Phone Format'
  const checkError = (value, validator) => {
    let isError = [undefined, null, ''].includes(value)
    let message = REQUIRED_TEXT
    if (!isError && validator !== undefined) {
      [isError, message] = validator(value)
    }
    return [isError, message]
  }

  useEffect(() => {
    if (manufacturerProfile) {
      const _errors = {...fieldErrors}

      const requiredFields = [
        'manufacturerPhone', 'plantManagerName','plantManagerEmail',
        'qaManagerName', 'qaManagerEmail',
        'fdaRegistrationNumber','processWater','effluentTreatment',
        'has9001','has22000','has14001','has18001',
        'manufacturerCertificates','allergensManufactured',
        'supplierGuidingPrinciplesStatus','supplierGuidingPrinciplesRating'
      ]

      requiredFields.forEach((f) => {
        const [isError, message] = checkError(manufacturerProfile[f])
        if (isError) {
          _errors[f] = message
        } else {
          delete _errors[f]
        }
      })
      const dateFields = [
        'fdaRegistrationDate',
        'iso9001LastCertificationDate',
        'fssc22000LastCertificationDate',
        'iso14001LastCertificationDate',
        'ohsas18001LastCertificationDate',
        'supplierGuidingPrinciplesDate',
        // 'lastGaoQ1AuditDate',
        // 'lastGaoE1S1AuditDate'
      ]
      dateFields.forEach((f) => {
        const [isError, message] = checkError(manufacturerProfile[f], (d) => {
          try {
            if (d?.$d === 'Invalid Date') throw new Error()
            const _date = new Date(d)
            _date.toISOString()
            return [false]
          } catch (e) {
            return [true, 'Invalid Date']
          }
        })

        if (isError) {
          _errors[f] = message
        } else {
          delete _errors[f]
        }
      })

      const requiredPhoneFields = [
        'manufacturerPhone'
      ]
      requiredPhoneFields.forEach((f) => {
        const [isError, message] = checkError(manufacturerProfile[f], (p) => {
          return [checkPhoneError(p), INVALID_PHONE_TEXT]
        })

        if (isError) {
          _errors[f] = message
        } else {
          delete _errors[f]
        }
      })

      const otherPhoneFields = [
        'manufacturerFax',
        'plantManagerPhone',
        'qaManagerPhone'
      ]

      otherPhoneFields.forEach((f) => {
        let isError, message
        if (!componentTextValue(manufacturerProfile[f] === '')) {
          [isError, message] = [checkPhoneError(manufacturerProfile[f]), INVALID_PHONE_TEXT]
        }

        if (isError) {
          _errors[f] = message
        } else {
          delete _errors[f]
        }
      })

      setFieldErrors(_errors)
    }
  },[manufacturerProfile])

  const onChange = ((e) => {
    const { id, name, value } = e.target
    console.log(id, name, value)
    validateFieldChange(id ?? name,value, manufacturerProfile, setManufacturerProfile, validateForm)
  })

  const handleCheckboxChange = (id, event) => {
    // console.log(event)
    let newValue = !manufacturerProfile[id]
    // console.log(newValue)
    manufacturerProfile[id] = newValue
    setManufacturerProfile({...manufacturerProfile}, {[[id]]: newValue})
    // console.log(manufacturerProfile)
  }

  const optionValue = (option, value) => {
    if (option == value) {
      return true
    }
    else if (option && option.value && option.value == value) {
      return true
    }
    else if (option && option.label && option.label == value) {
      return true
    }
    else {
      return false
    }
  }

  const initializeProfile = ( _record ) => {
    if (_record) {
      setManufacturerProfile(structuredClone(_record))
    }
  }

  const handleSave = async () => {
    startLoading()
    try {
        console.log('Saving...')
        if (valid) {
          const result = await saveManufacturerProfile(manufacturerProfile)
          const { manufacturerProfile: instance, status } = result.data
          
          if (status === 'success') {
              props.onStateValidation(instance, false)
              setValid(false)
              setPageManufacturerProfile(instance)
          } else {
            throw new Error(result.error)
          }
        } else {
          throw new Error('Not valid for Save')
        }
    } catch (error) {
      console.error(error)
      // TODO present error to user
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    EventBus.on('saveRequested', handleSave)
    return () => {
      EventBus.removeListener('saveRequested', handleSave)
    }
  }, [manufacturerProfile, valid])

  useEffect(() => {
    initializeProfile(pageManufacturerProfile)

  }, [pageManufacturerProfile])

  const isEditable = () => {
    if (roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])) {
      return true
    }
    if (openAssessment && roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      return true
    }
    return false
  }
  useEffect(() => {
    const _edit = isEditable()
    setSectionIsEditable(_edit)
  }, [openAssessment, roleDetails])

  const facilityCertifications = ["Halal", "ISO", "Juice HAACCP", "Kosher", "Organic", "None"]
  const waterSources = ["Well", "Municipality", "Other"]
  const possibleAllergens = ["Milk", "Eggs", "Fish", "Shellfish - Crustacean", "Tree nuts", "Peanuts", "Wheat", "Soybeans", "Sesame", "Sulfites", "Mustard", "Yellow #5"]

  return (
    manufacturerProfile && (
    <Card sx={{ padding: '10px' }}>
        <Grid container rowGap={1}>
          <Grid item xs={12}>
            <Typography fontWeight='bold'>Plant Contact Details</Typography>
          </Grid>
          <Grid item xs={12} marginBottom={'5px'}>
            <Grid container columnGap={1} rowGap={1}>
                <Grid item xs={12} sm={1.5} alignContent={'center'}>
                  <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Plant Phone:</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MuiTelInput
                      disabled={!editMode}
                      size='small'
                      defaultCountry="US"
                      disableDropdown
                      forceCallingCode 
                      value={componentTextValue(manufacturerProfile.manufacturerPhone)}
                      onChange={(event)=>{
                        onChange({ target: {
                          id: 'manufacturerPhone',
                          value: event
                        }})
                      }}
                      // onBlur={()=>setError(manufacturerProfile.manufacturerPhone, "manufacturerPhone", checkPhoneError)}
                      placeholder="Phone"
                      error={!!fieldErrors.manufacturerPhone}
                      helperText={fieldErrors.manufacturerPhone}
                    />
                </Grid>
                <Grid item xs={12} sm={1} alignContent={'center'}>
                  <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Fax:</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MuiTelInput
                    disabled={!editMode}
                    size='small'
                    defaultCountry="US"
                    disableDropdown
                    forceCallingCode 
                    value={manufacturerProfile.manufacturerFax} 
                    onChange={(event)=>{
                      onChange({ target: {
                        id: 'manufacturerFax',
                        value: event
                      }})
                    }}
                    placeholder="Fax"
                    error={!!fieldErrors.manufacturerFax}
                    helperText={fieldErrors.manufacturerFax}
                  />
                </Grid>
              
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ paddingLeft: '10px'}}>Plant Manager:</Typography>
            </Grid>
            <Grid container columnGap={1} rowGap={1}>
            <Grid item xs={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Name:</Typography>
              </Grid>
              <Grid item xs={8}>
              <TextField size='small'
                fullWidth 
                disabled={!editMode}
                id="plantManagerName" 
                placeholder="Plant Manager" 
                value={componentTextValue(manufacturerProfile.plantManagerName)} 
                onChange={onChange}
                error={!!fieldErrors.plantManagerName}
                helperText={fieldErrors.plantManagerName}
                />
              </Grid>
              <Grid item xs={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Email:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small'
                  fullWidth 
                  disabled={!editMode}
                  id="plantManagerEmail"
                  placeholder="Plant Manager Email" 
                  error={!!fieldErrors.plantManagerEmail} 
                  onChange={onChange}
                  value={componentTextValue(manufacturerProfile.plantManagerEmail)}
                  helperText={fieldErrors.plantManagerEmail}
                  />
              </Grid>
              <Grid item xs={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Phone:</Typography>
              </Grid>
              <Grid item xs={8}>
                <MuiTelInput
                    disabled={!editMode}
                    size='small'
                    defaultCountry="US"
                    disableDropdown
                    forceCallingCode 
                    value={componentTextValue(manufacturerProfile.plantManagerPhone)}
                    onChange={(event)=>{
                      onChange({ target: {
                        id: 'plantManagerPhone',
                        value: event
                      }})
                    }}
                    // onBlur={()=>setError(manufacturerProfile.manufacturerPhone, "manufacturerPhone", checkPhoneError)}
                    placeholder="Phone"
                    error={!!fieldErrors.plantManagerPhone}
                    helperText={fieldErrors.plantManagerPhone}
                  />
              </Grid>              
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ paddingLeft: '10px'}}>Corrective Action Facilitator:</Typography>
            </Grid>
            <Grid container columnGap={1} rowGap={1}>
              <Grid item xs={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Name:</Typography>
              </Grid>
              <Grid item xs={8}>
              <TextField size='small'
                fullWidth 
                disabled={!editMode}
                id="qaManagerName" 
                placeholder="Name" 
                value={componentTextValue(manufacturerProfile.qaManagerName)} 
                onChange={onChange}
                error={!!fieldErrors.qaManagerName}
                helperText={fieldErrors.qaManagerName}
                />
              </Grid>
              <Grid item xs={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Email:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small'
                  fullWidth 
                  disabled={!editMode}
                  id="qaManagerEmail"
                  placeholder="Email" 
                  error={!!fieldErrors.qaManagerEmail} 
                  onChange={onChange}
                  value={componentTextValue(manufacturerProfile.qaManagerEmail)}
                  helperText={fieldErrors.qaManagerEmail}
                  />
              </Grid>
              <Grid item xs={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Phone:</Typography>
              </Grid>
              <Grid item xs={8}>
                <MuiTelInput
                  disabled={!editMode}
                  size='small'
                  defaultCountry="US"
                  disableDropdown
                  forceCallingCode 
                  value={componentTextValue(manufacturerProfile.qaManagerPhone)}
                  onChange={(event)=>{
                    onChange({ target: {
                      id: 'qaManagerPhone',
                      value: event
                    }})
                  }}
                  // onBlur={()=>setError(manufacturerProfile.manufacturerPhone, "manufacturerPhone", checkPhoneError)}
                  placeholder="Phone"
                  error={!!fieldErrors.qaManagerPhone}
                  helperText={fieldErrors.qaManagerPhone}
                  />
              </Grid>              
            </Grid>
          </Grid>
          <Grid item xs={12}><hr />
            <Typography fontWeight='bold'>FDA Registration</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnGap={1} rowGap={1}>
              <Grid item xs={12} sm={2.5} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Registration Number:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerProfile.fdaRegistrationNumber}</Typography>
                }
                { editMode && 
                  <TextField size='small'
                  required
                  error={ !!fieldErrors.fdaRegistrationNumber }
                  id="fdaRegistrationNumber"
                  value={componentTextValue(manufacturerProfile.fdaRegistrationNumber)}
                  helperText={fieldErrors.fdaRegistrationNumber ? REQUIRED_TEXT : false}
                  onChange={onChange}
                />
                }
              </Grid>
              <Grid item xs={12} sm={2.5} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Registration Date:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                { !editMode &&
                  <Typography fontWeight='regular'> { manufacturerProfile.fdaRegistrationDate || ''}</Typography>
                }
                { editMode && 
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={!editMode}
                      format="MM/DD/YYYY"
                      id="fdaRegistrationDate"
                      name="fdaRegistrationDate"
                      value={manufacturerProfile.fdaRegistrationDate ? dayjs(manufacturerProfile.fdaRegistrationDate) : ''}
                      slotProps={{
                        textField: {
                          size: 'small',
                          required: true,
                          error: (!!fieldErrors.fdaRegistrationDate),
                          helperText: (fieldErrors.fdaRegistrationDate)
                        },
                      }}
                      onChange={(value) => 
                        onChange({
                          target: {
                            id: 'fdaRegistrationDate',
                            value
                          }
                        })
                      }
                    />
                  </LocalizationProvider>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}><hr />
            <Typography fontWeight='bold'>Process Water</Typography>
          </Grid>
          <Grid item xs={12} paddingLeft={3} paddingRight={3}>
            <Grid container gap={1}>
              <Grid item xs={12} sm={1} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary">Source:</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
              <Autocomplete 
                disabled={!editMode}
                // isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
                multiple
                size='small'
                openOnFocus
                id="processWater"
                onChange={(_event, values) => { 
                  validateFieldChange("processWater", values.join(','), manufacturerProfile, setManufacturerProfile, validateForm)
                }}
                options={waterSources} 
                value={manufacturerProfile.processWater ? manufacturerProfile.processWater.split(',') : []} 
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!fieldErrors.processWater}
                    helperText={fieldErrors.processWater}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ paddingBottom: '3px'}}>
                  Describe the effluent treatment and destination:
                </Typography>
                <TextField size='small'
                  inputProps={{
                    maxLength: '255'
                  }}
                  multiline={true}
                  maxRows={3}
                  disabled={!editMode}
                  fullWidth 
                  id="effluentTreatment"
                  onChange={onChange}
                  value={componentTextValue(manufacturerProfile.effluentTreatment)}
                  error={!!fieldErrors.effluentTreatment}
                  helperText={`${manufacturerProfile.effluentTreatment?.length ?? 0}/255 ${fieldErrors.effluentTreatment ?? ''}`}
                  />
              </Grid>
          </Grid>
          </Grid>

          <Grid item xs={12}><hr />
            <Typography fontWeight='bold'>Do you have any of the following certifications?</Typography>
          </Grid>
            <Grid item xs={12}>
            <CertificationControl
                fieldErrors={fieldErrors}
                editMode={editMode}
                manufacturerProfile={manufacturerProfile}
                onChange={onChange}
                setError={setError}
                required
                title="ISO 9001"
                certificationField="has9001"
                certificationDateField="iso9001LastCertificationDate"
              />
              <CertificationControl
                fieldErrors={fieldErrors}
                editMode={editMode}
                manufacturerProfile={manufacturerProfile}
                onChange={onChange}
                setError={setError}
                required
                title="FSSC 22000 v4.1"
                certificationField="has22000"
                certificationDateField="fssc22000LastCertificationDate"
              />
              <CertificationControl
                fieldErrors={fieldErrors}
                editMode={editMode}
                manufacturerProfile={manufacturerProfile}
                onChange={onChange}
                setError={setError}
                required
                title="ISO 14001"
                certificationField="has14001"
                certificationDateField="iso14001LastCertificationDate"
              />
              <CertificationControl
                fieldErrors={fieldErrors}
                editMode={editMode}
                manufacturerProfile={manufacturerProfile}
                onChange={onChange}
                setError={setError}
                required
                title="ISO 45001 (OHSAS 18001)"
                certificationField="has18001"
                certificationDateField="ohsas18001LastCertificationDate"
              />
              
              <Grid container spacing={1} padding={.5}>
                <Grid item xs={12} sm={3} alignContent={'center'}>
                  <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Facility Certifications *</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Autocomplete 
                        isOptionEqualToValue={(option, value)=>{optionValue(option, value)}}
                        disabled={!editMode}
                        multiple
                        id="manufacturerCertificates"
                        size='small'
                        options={facilityCertifications}
                        //getOptionLabel={(option) => option.label}
                        openOnFocus
                        value={manufacturerProfile.manufacturerCertificates ? manufacturerProfile.manufacturerCertificates.split(',') : []}
                        onChange={(_event, values) => { 
                          validateFieldChange("manufacturerCertificates", values.join(','), manufacturerProfile, setManufacturerProfile, validateForm)
                          setError(values, "manufacturerCertificates", checkError)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            error={!!fieldErrors.manufacturerCertificates}
                            helperText={fieldErrors.manufacturerCertificates}
                          />
                        )}
                      />
                </Grid>
              </Grid>
              
          </Grid>
          <Grid item xs={12}><hr />
            <Typography fontWeight='bold'>Audits</Typography>
          </Grid>
          <Grid container columnGap={1} rowGap={1}>
            <Grid item xs={12} sm={3} alignContent={'center'}>
              <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Last GAO Q1 Audit</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>                    
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker  
                disabled={!editMode}
                format="MM/DD/YYYY" 
                // required
                name="lastGaoQ1AuditDate"
                slotProps={{
                  textField: {
                    size: 'small',
                    error: (!!fieldErrors.lastGaoQ1AuditDate)
                  }
                }}
                value={manufacturerProfile.lastGaoQ1AuditDate ? dayjs(manufacturerProfile.lastGaoQ1AuditDate) : ''} 
                onChange={(value) => 
                  onChange({
                    target: {
                      id: 'lastGaoQ1AuditDate',
                      value
                    }
                  })
                }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3} alignContent={'center'}>
              <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Last GAO E1, S1 Audit</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>                    
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker  
                disabled={!editMode}
                format="MM/DD/YYYY" 
                // required
                name="lastGaoE1S1AuditDate"
                slotProps={{
                  textField: {
                    size: 'small',
                    error: (!!fieldErrors.lastGaoE1S1AuditDate)
                  }
                }}
                value={manufacturerProfile.lastGaoE1S1AuditDate ? dayjs(manufacturerProfile.lastGaoE1S1AuditDate) : ''}
                onChange={(value) => 
                  onChange({
                    target: {
                      id: 'lastGaoE1S1AuditDate',
                      value
                    }
                  })
                }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3} alignContent={'center'}>
              <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Other Third Party Audits</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
                <TextField size='small' 
                disabled={!editMode}
                  fullWidth 
                  id="otherThirdPartyAudits" 
                  placeholder="e.g. AIB, GMA-SAFE, Silliker"
                  value={componentTextValue(manufacturerProfile.otherThirdPartyAudits)}
                  onChange={onChange}
                />
            </Grid>
          </Grid>
          
          <Grid item xs={12}><hr />
            <Typography fontWeight='bold'>Personnel</Typography>
          </Grid>
          <Grid container columnGap={1} rowGap={1}>
            <Grid item xs={12} sm={3} alignContent={'center'}>
              <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Key Plant Positions Open</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField size='small' 
                disabled={!editMode}
                fullWidth 
                id="keyPositionsOpen"
                value={componentTextValue(manufacturerProfile.keyPositionsOpen)}
                onChange={(event) => {
                  const _val = event.target.value === undefined ? 0 : Math.abs(event.target.value)
                  validateFieldChange("keyPositionsOpen", _val, manufacturerProfile, setManufacturerProfile, validateForm)
                }}
                error={manufacturerProfile.keyPositionsOpen === undefined}
                helperText={fieldErrors.keyPositionsOpen}
                type="number" />
            </Grid>
            <Grid item xs={12} sm={3} alignContent={'center'}>
              <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>{'Key Plant Positions < 1yr'}</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField size='small' 
                disabled={!editMode}
                fullWidth 
                id="keyPositionsOpen1Year" 
                onChange={(event) => {
                  const _val = event.target.value === undefined ? 0 : Math.abs(event.target.value)
                  validateFieldChange("keyPositionsOpen1Year", _val, manufacturerProfile, setManufacturerProfile, validateForm)
                }}
                value={componentTextValue(manufacturerProfile.keyPositionsOpen1Year)}
                type="number" 
                error={manufacturerProfile.keyPositionsOpen1Year === undefined}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}><hr/>
            <Typography fontWeight='bold'>Other Processes</Typography>
          </Grid>
          <Grid item xs={12} paddingLeft={5}>
            <Stack rowGap={1}>
              <Stack direction={'row'} columnGap={1} alignItems={'center'}>
                <FormGroup  id="co2Cylinder">
                  <FormControlLabel control={
                    <Checkbox 
                      value={Boolean(manufacturerProfile.co2Cylinder ?? false).valueOf()}
                      checked={Boolean(manufacturerProfile.co2Cylinder ?? false).valueOf()}
                      onChange={(event, checked) => onChange({ target: {id: 'co2Cylinder', value: checked }})}
                      disabled={!editMode}
                      />} 
                      label="CO2 Cylinder Filling" />
                </FormGroup>
                <TextField size='small'
                  id="co2CylinderLine" 
                  hidden={!manufacturerProfile.co2Cylinder}
                  disabled={!editMode}
                  label="Line" 
                  value={componentTextValue(manufacturerProfile.co2CylinderLine)}
                  onChange={onChange}
                  error={manufacturerProfile.co2Cylinder && componentTextValue(manufacturerProfile.co2CylinderLine) === ''}
                  helperText={
                    (manufacturerProfile.co2Cylinder && componentTextValue(manufacturerProfile.co2CylinderLine) === '') ? REQUIRED_TEXT : false
                    }
                  />
              </Stack>
              <Stack direction={'row'} columnGap={1} alignItems={'center'}>
                <FormGroup  id="ilbm">
                  <FormControlLabel control={
                    <Checkbox
                      disabled={!editMode}
                      value={Boolean(manufacturerProfile.ilbm ?? false).valueOf()}
                      checked={Boolean(manufacturerProfile.ilbm ?? false).valueOf()}
                      onChange={(event, checked) => onChange({ target: {id: 'ilbm', value: checked }})}
                      />} 
                      label="In Line Blow Molding (ILBM)" />
                </FormGroup>
                <TextField size='small' 
                  disabled={!editMode}
                  hidden={!manufacturerProfile.ilbm}
                  id="ilbmLine" 
                  label="Line" 
                  value={componentTextValue(manufacturerProfile.ilbmLine)}
                  onChange={onChange}
                  error={manufacturerProfile.ilbm && componentTextValue(manufacturerProfile.ilbmLine) === ''}
                  helperText={
                    (manufacturerProfile.ilbm && componentTextValue(manufacturerProfile.ilbmLine) === '') ? REQUIRED_TEXT : false
                    }
                  />
              </Stack>
              <Stack direction={'row'} columnGap={1} alignItems={'center'}>
                <FormGroup  id="ionizedAirRinsing">
                  <FormControlLabel control={
                    <Checkbox 
                      disabled={!editMode}
                      value={Boolean(manufacturerProfile.ionizedAirRinsing ?? false).valueOf()}
                      checked={Boolean(manufacturerProfile.ionizedAirRinsing ?? false).valueOf()}
                      onChange={(event, checked) => onChange({ target: {id: 'ionizedAirRinsing', value: checked }})}
                      // onChange={(event) => handleCheckboxChange("ionizedAirRinsing", event)}
                      />} 
                      label="Ionized Air Rinsing" />
                </FormGroup>
                <TextField size='small' 
                  disabled={!editMode}
                  hidden={!manufacturerProfile.ionizedAirRinsing}
                  id="ionizedAirRinsingLine" 
                  label="Line" 
                  value={componentTextValue(manufacturerProfile.ionizedAirRinsingLine)}
                  onChange={onChange}
                  error={manufacturerProfile.ionizedAirRinsing && fieldErrors.ionizedAirRinsingLine}
                  helperText={
                    (manufacturerProfile.ionizedAirRinsing && fieldErrors.ionizedAirRinsingLine) ? REQUIRED_TEXT : false
                    }
                  />
              </Stack>
            </Stack>
            
          </Grid>


          <Grid item xs={12}>
            <hr /><Typography className='tis-form-title'>Food Allergens and Sensitivities</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container gap={1}>
            <Grid item xs={12} sm={7} alignContent={'center'}>
              <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'left'}}>Are allergens or allergen containing products manufactured in this facility?</Typography>
            </Grid>

            <Grid item xs={12} sm={1}>
              <FormControl size='small' fullWidth error={fieldErrors.allergensManufactured}>
                <InputLabel id="allergensManufactured"></InputLabel>
                <Select
                  name="allergensManufactured"
                  disabled={!editMode}
                  error={!!fieldErrors.allergensManufactured}
                  value={componentTextValue(manufacturerProfile.allergensManufactured)}
                  onChange={onChange}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
                <FormHelperText hidden={componentTextValue(manufacturerProfile.allergensManufactured) !== ''} >{REQUIRED_TEXT}</FormHelperText>
              </FormControl>
            </Grid>
            { manufacturerProfile.allergensManufactured && <>
              <Grid item xs={12} sm={6} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Which lines are allergen containing products produced on?</Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField size='small' 
                  disabled={!editMode}
                  id="allergenLines"
                  error={componentTextValue(manufacturerProfile.allergenLines) === ''}
                  onChange={onChange}
                  value={componentTextValue(manufacturerProfile.allergenLines)}
                  helperText={componentTextValue(manufacturerProfile.allergenLines) === '' ? REQUIRED_TEXT : undefined}
                />
              </Grid>
              <Grid item xs={12} sm={6} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>List of allergens in produced products:</Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Autocomplete 
                  fullWidth
                  isOptionEqualToValue={(option, value) => { optionValue(option, value) }}
                  disabled={!editMode}
                  multiple
                  size='small'
                  id="allergens"
                  options={possibleAllergens}
                  value={manufacturerProfile.allergens ? manufacturerProfile.allergens.split(',') : []}
                  onChange={(_event, values) => {
                    const _uniques = [...new Set(values)].join(',')
                    validateFieldChange("allergens", _uniques, manufacturerProfile, setManufacturerProfile, validateForm)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={componentTextValue(manufacturerProfile.allergens) === ''}
                      helperText={componentTextValue(manufacturerProfile.allergens) === '' ? REQUIRED_TEXT : undefined}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>List of allergens that could be present in distributed products:</Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Autocomplete 
                  fullWidth
                  disabled={!editMode}
                  isOptionEqualToValue={(option, value) => { optionValue(option, value) }}
                  multiple
                  size='small'
                  id="allergensPresentInDist"
                  options={possibleAllergens}
                  value={manufacturerProfile.allergensPresentInDist ? manufacturerProfile.allergensPresentInDist.split(',') : []}
                  onChange={(_event, values) => {
                    validateFieldChange("allergensPresentInDist", values.join(','), manufacturerProfile, setManufacturerProfile, validateForm)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={componentTextValue(manufacturerProfile.allergensPresentInDist) === ''}
                      helperText={componentTextValue(manufacturerProfile.allergensPresentInDist) === '' ? REQUIRED_TEXT : undefined}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              </>
            }
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <hr /><Typography className='tis-form-title'>Supplier Guiding Principles</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container gap={1}>
              <Grid item xs={12} sm={1} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Status:</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                  <FormControl size='small' className='tis-form-field'
                    fullWidth
                    >
                    <Select
                      name="supplierGuidingPrinciplesStatus"
                      error={!!fieldErrors.supplierGuidingPrinciplesStatus}
                      disabled={!editMode}                      
                      value={componentTextValue(manufacturerProfile.supplierGuidingPrinciplesStatus)}
                      onChange={onChange}
                    >
                      <MenuItem value={"Not Scheduled"}>Not Scheduled</MenuItem>
                      <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                      <MenuItem value={"Completed"}>Completed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {(["Scheduled","Completed"].includes(manufacturerProfile.supplierGuidingPrinciplesStatus)) && 
                  <>
                  <Grid item xs={12} sm={3} alignContent={'center'}>
                    <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Date Scheduled or Completed:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DatePicker className='tis-form-field' 
                        disabled={!editMode}
                        format="MM/DD/YYYY" 
                        id="supplierGuidingPrinciplesDate"
                        value={manufacturerProfile.supplierGuidingPrinciplesDate ? dayjs(manufacturerProfile.supplierGuidingPrinciplesDate) : ''}  
                        slotProps={{
                          textField: {
                            size: 'small',
                            required: true,
                            error: (!!fieldErrors.supplierGuidingPrinciplesDate),
                            helperText: fieldErrors.supplierGuidingPrinciplesDate,
                          },
                        }}
                        onChange={(value) => 
                          onChange({
                            target: {
                              id: 'supplierGuidingPrinciplesDate',
                              value
                            }
                          })
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={1} alignContent={'center'}>
                    <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Rating:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl size='small' className='tis-form-field'
                        fullWidth error={!!fieldErrors.supplierGuidingPrinciplesRating}
                        >
                        <Select
                          name="supplierGuidingPrinciplesRating"
                          disabled={!editMode}
                          value={componentTextValue(manufacturerProfile.supplierGuidingPrinciplesRating)}
                          onChange={onChange}
                          error={!!fieldErrors.supplierGuidingPrinciplesRating}
                        >
                          <MenuItem value={"Pass"}>Pass</MenuItem>
                          <MenuItem value={"Fail"}>Fail</MenuItem>
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} alignContent={'center'}>
                    <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Third Party Auditor:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField size='small' className='tis-form-field'
                      disabled={!editMode}
                      fullWidth 
                      id="thirdPartyAuditor" 
                      label="Third Party Auditor"
                      onChange={onChange}
                      value={componentTextValue(manufacturerProfile.thirdPartyAuditor)}
                      />
                  </Grid>
                  </>
                  }

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <hr /><Typography className='tis-form-title'>Co-manufacturers</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container gap={1}>
              <Grid item xs={12} sm={8.5} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Relative to other customers of this facility, the volume shipped to the Coca-Cola system is:</Typography>
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <TextField size='small' className='tis-form-field'
                  disabled={!editMode} 
                  fullWidth
                  id="volumeShipped"
                  placeholder="Volume shipped"
                  value={componentTextValue(manufacturerProfile.volumeShipped)}
                  onChange={(event) => {
                    const _val = event.target.value === undefined ? 0 : Math.abs(event.target.value)
                    validateFieldChange("volumeShipped", _val, manufacturerProfile, setManufacturerProfile, validateForm)
                  }}
                  type="number"
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Card >
    )
  )
}