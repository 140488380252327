import { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, Button, Link, Stack, CircularProgress, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { addRow, checkColumnError } from "../form/Form";
import ConfirmDialog from "../form/ConfirmDialog";
import {
  getOrCreateAssociatedContact,
  getAssociatedContactByEmail,
  createContactAssociation,
  deleteContactAssociation,
  updateContact,
  createContactNotification,
  deleteContactNotification,
} from './data'
import ContactFormModal from './ContactFormModal'

export default function ContactTable(props = {
  contactsData: undefined,
  handleDeleteItem: undefined,
  itemType: 'contact',
  group: '',
  setContactsData: undefined,
  editMode: false
}) {
  const { loading, contactTypes, parentType, facilityOrOwnership = {}, isInternal } = props

  const [_contactsRows, _setContactsRows] = useState([])
  const contactsRows = props.contacts || _contactsRows
  const setContactsRows = props.setContacts || _setContactsRows

  const [tempRow, setTempRow] = useState({})
  const [showCreatePanel, setShowCreatePanel] = useState(false)
  const [showEditPanel, setShowEditPanel] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})
  const [deleteDialog, setDeleteDialog] = useState(false)

  const [contactAssociationName, notificationsName] = parentType === 'ownership' ? ['OwnershipContacts', 'OwnershipContactNotifications'] : ['ManufacturerContacts', 'ManufacturerContactNotifications']

  const handleContact = async (contact, existingContact, newContactsRows) => {
    let newContact
    if (existingContact) {
      newContact = newContactsRows.find(r => r.id === contact.id)
      const updatedContact = await updateContact(contact)
      const idx = newContactsRows.indexOf(newContact)
      newContact = { ...newContact, ...updatedContact }
      newContactsRows.splice(idx, 1, newContact)
    } else {
      newContact = await getOrCreateAssociatedContact(facilityOrOwnership.uuid, parentType, contact)
      if (newContact[contactAssociationName]?.length) {
        if (isInternal !== undefined) {
          newContact[contactAssociationName] = newContact[contactAssociationName].filter(oc => oc.ContactType.isInternal === isInternal)
        }
      }
      newContactsRows.push(newContact)
    }
    return newContact
  }

  const handleAssociations = async (contact, existingContact, newContact) => {
    const existingContactAssociations = existingContact?.[contactAssociationName] || []
    const existingContactTypeIds = existingContactAssociations.map(eoc => eoc?.ContactType.id)

    const newContactAssociations = contact?.[contactAssociationName] || []
    const newContactTypeIds = newContactAssociations.map(noc => noc?.ContactType.id)

    for (const newContactTypeId of newContactTypeIds) {
      if (newContactTypeId) {
        // if the contact doesnt already have this contact type create it
        if (!existingContactTypeIds.includes(newContactTypeId)) {
          const newContactAssociation = await createContactAssociation(parentType, facilityOrOwnership?.uuid, {
            contactTypeId: newContactTypeId,
            contactId: newContact.id
          })
          newContactAssociation.ContactType = contactTypes.find(ct => ct.id === newContactTypeId)
          newContact[contactAssociationName] = newContact[contactAssociationName] || []
          newContact[contactAssociationName].push(newContactAssociation)
        }
      }
    }

    const contactTypeIdsToDelete = existingContactTypeIds.filter(id => !newContactTypeIds.includes(id))
    for (const contactTypeIdToDelete of contactTypeIdsToDelete) {
      const contactIdToDelete = existingContact?.[contactAssociationName]?.find(f => f.ContactType.id === contactTypeIdToDelete)?.id
      await deleteContactAssociation(parentType, facilityOrOwnership?.uuid, contactIdToDelete)
      newContact[contactAssociationName].splice(newContact[contactAssociationName].indexOf(contactTypeIdToDelete), 1)
    }
  }

  const handleNotifications = async (contact, existingContact, newContact) => {
    const existingNotifications = existingContact?.[notificationsName] || []
    const newNotifications = contact[notificationsName] || []
    
    if (existingNotifications.length || newNotifications.length) {
      const existingNotificationTypes = existingNotifications.map(n => n.notificationType)
      const newNotificationTypes = newNotifications.map(n => n.notificationType)
      const notificationTypesToDelete = existingNotificationTypes.filter(name => !newNotificationTypes.includes(name))

      for (const newNotificationType of newNotificationTypes) {
        if (newNotificationType) {
          // if the contact doesnt already have this notification create it
          if (!existingNotificationTypes.includes(newNotificationType)) {
            const newNotification = await createContactNotification({
              contactId: newContact.id,
              facilityOrOwnershipUuid: facilityOrOwnership.uuid,
              parentType,
              notificationType: newNotificationType
            })
            newContact[notificationsName] = newContact[notificationsName] || []
            newContact[notificationsName].push(newNotification)
          }
        }
      }

      for (const notificationTypeToDelete of notificationTypesToDelete) {
        const contactNotificationToDelete = existingContact?.[notificationsName]?.find(f => f.notificationType === notificationTypeToDelete)
        const contactNotificationIdToDelete = existingContact?.[notificationsName]?.find(f => f.notificationType === notificationTypeToDelete)?.id
        await deleteContactNotification({ contactNotificationId: contactNotificationIdToDelete, parentType })
        newContact[notificationsName].splice(newContact[notificationsName].indexOf(contactNotificationToDelete), 1)
      }
    }
  } 

  const onSubmit = async (contact) => {

    const newContactsRows = structuredClone(contactsRows)
    const existingContact = contactsRows.find(r => r.id === contact.id)
    const newContact = await handleContact(contact, existingContact, newContactsRows)

    await handleAssociations(contact, existingContact, newContact)
    await handleNotifications(contact, existingContact, newContact)

    if(props.afterSave) props.afterSave()
  }

  useEffect(() => {
    const getRows = async () => {
      if (props.contacts) {
        setContactsRows(props.contacts)
      } else {
        throw new Error('Must provide contacts rows.')
      }
    }
    if (facilityOrOwnership.uuid) {
      getRows()
    }
  }, [facilityOrOwnership.uuid, props.contacts])


  const hideDeleteDialog = () => {
    setTempRow({})
    setDeleteDialog(false)
  }

  const handleConfirmDelete = async () => {
    const promises = tempRow[contactAssociationName].map((OwnershipContact) => {
      return deleteContactAssociation(parentType, facilityOrOwnership.uuid, OwnershipContact.id)
    })
    await Promise.all(promises)
    const _contactsRows = structuredClone(contactsRows)
    const contactRowToSplice = _contactsRows.find(f => f.id === tempRow.id)
    _contactsRows.splice(_contactsRows.indexOf(contactRowToSplice), 1)
    setContactsRows(_contactsRows)
    hideDeleteDialog()
  }

  const editRow = (row) => {
    setTempRow(row.original)
    setShowEditPanel(true)
  }

  const deleteRow = (row) => {
    setTempRow(row.original)
    setDeleteDialog(true)
  }

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        variant: "outlined",
        onBlur: (event) => {
          checkColumnError(cell.id, event.target.value, validationErrors, setValidationErrors)
        },
      };
    },
    [validationErrors],
  )

  const contactsColumns = useMemo(() => {
    const notificationTypesMap = {
      'trend': 'Trends'
    }
    const editColumn = {
      id: 'edit', size: 80, header: 'Actions', columnDefType: 'display', Cell: (
        ({ cell, row }) => (
          <Stack direction='row' spacing={2}>
            <Link onClick={() => editRow(row)}><EditIcon /></Link>
            <Link onClick={() => deleteRow(row)}><DeleteIcon /></Link>
          </Stack>
        )
      )
    }

    const set = [
      {
        accessorKey: 'name', header: 'Name', size: 160, editable: true, required: true,
        Cell: ({cell}) => (<Typography variant='body2' whiteSpace={'pre-wrap'}>{cell.getValue()}</Typography>),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: contactAssociationName, header: 'Contact Types', size: 160, editable: true, required: true,
        customType: 'Select',
        editSelectOptions: contactTypes,
        editVariant: 'select',
        Cell: ({ row }) => {
          return (
            <Stack>
              {row.original[contactAssociationName]
                .map(contactAssociation => {
                  const ctId = contactAssociation.contactTypeId
                  const name = contactTypes.find(ct => ct.id === ctId)?.name
                  return <Typography variant='body2'
                    key={ctId}
                  >
                    {name}
                  </Typography>
                })}
            </Stack>
          );
        }
      },
      {
        id: 'contactInfo', header: 'Contact Info', size: 160,
        Cell: ({row}) => {
          const o = row.original
          const phone = [o.phone,(o.extension ? ` ${o.extension}` : '')].join('')
          return (
            <Stack>{[o.email, o.jobTitle, phone].map((v, i) => (
              <Typography variant='body2' key={i}>{v}</Typography>
            ))}
            </Stack>
          )
        },
      },
      {
        accessorKey: 'jobTitle', header: 'Title/Job Position', size: 160, editable: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      { accessorKey: 'phone', header: 'Phone', size: 120, editable: true, required: false},
      {
        accessorKey: 'extension', header: 'Ext.', size: 40, editable: true, required: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        })
      },
      {
        accessorKey: 'email', header: 'Email', size: 160, editable: true, required: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        })
      },
      {
        accessorKey: notificationsName, header: 'Notify', size: 75, editable: true, required: true,
        Cell: ({ row }) => {
          return (
            <Stack>
              {row.original?.[notificationsName]?.map((notification, i) => (
                <Typography variant='body2' key={i}>{notificationTypesMap[notification.notificationType]}</Typography>
              ))}
            </Stack>
          );
        }
      },
      {
        accessorKey: 'note', header: 'Notes', size: 160, editable: true, required: false,
        Cell: ({cell}) => (<Typography variant='body2' whiteSpace={'pre-wrap'}>{cell.getValue()}</Typography>),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        })
      },

    ]
    if (props.editMode) set.push(editColumn)
    return set
  }, [contactTypes, getCommonEditTextFieldProps, props.editMode])


  return (
    <div className='tis-form-table'>
      {deleteDialog && <ConfirmDialog title="Delete this item?" text="This action is immediate and cannot be undone. Do you want to delete this item?"
        open={deleteDialog}
        hideDialog={hideDeleteDialog}
        handleConfirm={handleConfirmDelete}
        yesLabel="Delete"
        yesButtonStyle={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
        yesButtonVariant='filled'
        noLabel="Keep"
        noButtonStyle={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold' }}
        noButtonVariant='filled'
      />}
      <MaterialReactTable
        muiTableBodyProps={loading ? {
          children: [
            <tr>
              <td colspan={contactsColumns?.length}>
                <div style={{
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                  textAlign: 'start',
                  width: '100%',
                }}
                >
                  <CircularProgress />
                </div>
              </td>
            </tr>
          ],
        } : undefined
          // {
          //   sx: {
          //     '& tr > td > p': {
          //       display: 'none'
          //     }
          //   }
          // }
        }
        columns={contactsColumns}
        data={contactsRows || []}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={true}
        enableBottomToolbar={false}
        muiTableBodyCellProps={{
          sx: { verticalAlign: 'top !important'}
        }}
        muiTableContainerProps={{
          sx: { minHeight: 200, maxHeight: 400 }
        }}
        renderTopToolbar={({ table }) => <Box>
          {props.editMode &&
            <Button color="primary" size="medium" startIcon={<AddIcon />}
              onClick={() => setShowCreatePanel(true)}
            >
              Add New Contact
            </Button>
          }
        </Box>}
        initialState={{
          density: 'compact',
          sorting: [{
            id: 'name',
            desc: false
          }],
          columnVisibility: {
            'email': false,
            'extension': false,
            'phone': false,
            'jobTitle': false
          }
        }}
      />
      {showCreatePanel && <ContactFormModal
        mode='add'
        columns={contactsColumns}
        open={!!contactsColumns}
        contactOptions={contactsRows}
        onClose={() => setShowCreatePanel(false)}
        onSubmit={onSubmit}
        addRow={addRow}
        rowSetter={props.setContactsData}
        setDirty={props.setDirty}
        parentType={props.parentType}
        facilityOrOwnershipUuid={props.facilityOrOwnership?.uuid}
        contactTypeOptions={contactTypes}
        contactLookup={(email) => getAssociatedContactByEmail(facilityOrOwnership.uuid, parentType, email)}
        isInternal={isInternal}
        contactAssociationName={contactAssociationName}
        notificationsName={notificationsName}
      />}
      <ContactFormModal
        mode='edit'
        columns={contactsColumns}
        open={showEditPanel}
        getCurrentValues={() => tempRow}
        onClose={() => setShowEditPanel(false)}
        onSubmit={onSubmit}
        rowSetter={props.setContactsData}
        setDirty={props.setDirty}
        parentType={props.parentType}
        facilityOrOwnershipUuid={props.facilityOrOwnership?.uuid}
        contactTypeOptions={contactTypes}
        isInternal={isInternal}
        contactAssociationName={contactAssociationName}
        notificationsName={notificationsName}
      />
    </div>
  )
}