import { getRequestConfig } from '../auth/auth';
import { ApiGateway } from '../config/config';
import axios from 'axios';

export const loadOwnership = async (ownershipUuid, requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }

  const basePath = `${ApiGateway.facilityManagement}/ownership/search`
  const search = { uuid: ownershipUuid }
  const response = await axios.post(basePath, search, requestConfig)
  if (response.data) {
    console.log('response.data', response.data)
    const { results } = response.data
    return results[0]
  }
}

export const saveOwnership = async (ownershipUuid, ownershipDetail, requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }

  const basePath = `${ApiGateway.facilityManagement}/ownership${ownershipUuid ? '/' + ownershipUuid : ''}`
  const payload = { ...ownershipDetail }
  const response = await axios.put(basePath, payload, requestConfig)
  if (response.data) {
    const { status, instance, created } = response.data
    console.log(`Save: ${status}`,`Instance: ${JSON.stringify(instance,null,2)}`, `Created: ${created}`)
    return response.data
  }
}

const cleanOwnershipSearch = (search) => {
  if (search.isActive !== undefined) {
    if (search.isActive) {
      search.isActive = '1'
    } else {
      search.isActive = '0'
    }
  }
  return search
}

export const searchOwnerships = async (search) => {
  const requestConfig = await getRequestConfig()
  const basePath = `${ApiGateway.facilityManagement}/ownership/search`

  const response = await axios.post(basePath, cleanOwnershipSearch(search), requestConfig)
  if (response.data) {
    const result = response.data
    return result
  }
}

export const getOwnershipList = async (search) => {
  const requestConfig = await getRequestConfig()
  const basePath = `${ApiGateway.facilityManagement}/ownership/search`

  const response = await axios.post(basePath, cleanOwnershipSearch(search), requestConfig)
  if (response.data) {
    const { results } = response.data
    return results
  }
}