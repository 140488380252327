import { default as React, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Factory } from '@mui/icons-material'
import ListManufacturers from './ListManufacturers'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { Box } from '@mui/system'
import { Paper, Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'

function TabPanel (props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default function ListManufacturersPage (props = {}) {
  const roleDetails = useContext(RoleContext)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams({ view: 'active', page: '1'})

  const tabChange = (event, toTab) => {
    setSearchParams({view: toTab === 1 ? 'inactive' : 'active'})
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><Factory/>&nbsp;Manufacturers</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const addRecord = () => {
    navigate('/manufacturer/create')
  }

  useEffect(() => {
    setSelectedTab(searchParams.get('view') === 'inactive' ? 1 : 0)
  }, [searchParams])

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} 
    commandBar={
      <ListViewCommandBar 
        showFilters={true}
        toggleFilters={toggleFilters} 
        addRecord={addRecord} 
        showAdd={roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])}              
      />
    }>
      <Paper sx={{margin: '0 auto 1em auto' }}>
        { roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR]) && 
          <>
          <Tabs value={selectedTab} onChange={tabChange}>          
              <Tab label='Active' id={`tab-0`}></Tab>
              <Tab label='Inactive' id={`tab-1`}></Tab>
          </Tabs>
          <TabPanel selectedTab={selectedTab} thisTab={0}>
            <ListManufacturers showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} searchFilter={{isActive: true}}/>
          </TabPanel>
          <TabPanel selectedTab={selectedTab} thisTab={1}>
          <ListManufacturers showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} searchFilter={{isActive: false}}/>
          </TabPanel>
          </>
        }
        { roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && 
          <ListManufacturers showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} searchFilter={{isActive: true}}/>
        }
      </Paper>
  </Page>
  )
}