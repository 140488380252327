import { default as React, useCallback, useContext, useEffect, useState } from 'react'
import { Card } from '@mui/material'

import ContactTable from '../../facilityManagement/ContactTable';
import { ParentType, getEntityContacts, getContactTypes } from '../../facilityManagement/data'
import { ROLES } from '../../auth/role.js'
import RoleContext from '../../auth/RoleContext.js'
import TabPanel from '../../form/TabPanel'

export default function OwnershipContactsSection(props = {}) {
  const { ownership = {} } = props

  const roleDetails = useContext(RoleContext)

  const [internalContacts, setInternalContacts] = React.useState([]);
  const [externalContacts, setExternalContacts] = React.useState([]);
  const [contactTypes, setContactTypes] = React.useState([]);
  const [loading, setLoading] = useState(true)

  const loadSection = async (ownUuid) => {
    setLoading(true)
    const [_internalContacts, _externalContacts, _contactTypes] = await Promise.all([
      getEntityContacts({
        facilityOrOwnershipUuid: ownUuid,
        parentType: ParentType.ownership,
        isInternal: true,
        category: 'ownership'
      }),
      getEntityContacts({
        facilityOrOwnershipUuid: ownUuid,
        parentType: ParentType.ownership,
        isInternal: false,
        category: 'ownership'
      }),
      getContactTypes(ParentType.ownership)
    ])
    setInternalContacts(_internalContacts)
    setExternalContacts(_externalContacts)
    setContactTypes(_contactTypes)
    setLoading(false)
  }

  useEffect(() => {
    (async () => {
      if (ownership?.uuid) loadSection(ownership?.uuid)
    })()
  }, [ownership])

  const editingEnabled = roleDetails.hasRole([
    ROLES.BOTTLER_CORP,
    ROLES.QSE_MGR,
    ROLES.MFI_ADMIN,
    ROLES.ADMIN
  ])

  const afterSave = useCallback(() => {
    (async () => {
      if (ownership?.uuid) loadSection(ownership?.uuid)
    })()
  }, [ownership])

  return (

    <Card sx={{ padding: '12px' }}>

      <TabPanel
        tabs={[
          {
            label: "Ownership Contacts",
            el: <ContactTable
              loading={loading}
              parentType={ParentType.ownership}
              isInternal={false}
              facilityOrOwnership={ownership}
              editMode={editingEnabled}
              contacts={externalContacts}
              setContacts={setExternalContacts}
              contactTypes={contactTypes.filter(ct => !ct.isInternal)}
              afterSave={afterSave}
            />
          },
          {
            label: "Coca-Cola Contacts",
            el: <ContactTable
              loading={loading}
              parentType={ParentType.ownership}
              isInternal={true}
              facilityOrOwnership={ownership}
              editMode={editingEnabled}
              contacts={internalContacts}
              setContacts={setInternalContacts}
              contactTypes={contactTypes.filter(ct => ct.isInternal)}
              afterSave={afterSave}
            />
          },
        ]}
      />

    </Card>
  )
}