import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils.js'
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table'
import { Box, Grid, Paper, IconButton, Button} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';
import { TroubleshootOutlined, SubdirectoryArrowRight } from '@mui/icons-material';

export default function ListFitnessIndexes(props = {searchFilter: undefined, rowsPerPage: 100, pageIndex: 0 }) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [dataRows, setDataRows] = useState([])
  
  const roleDetails = useContext(RoleContext)

  const listColumns = useMemo(() => [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 180,
      editable: false,
      filterVariant: 'text',
      grow: false,
      Cell: ({row}) => {
        const id = row.original.id
        return (
          <Link to={ `/fitness-indexes/${id}` }>
            {id}
          </Link>
        )
      }
    },
    {
      accessorKey: 'Manufacturer.manufacturerName',
      header: 'Plant',
      editable: false,
      filterVariant: 'text',
      grow: true,
      Cell: ({row}) => {
        return (
          <Link to={ `/manufacturer/profile/edit/${row.original.Manfacturer?.id}` }>
            {row.original.Manufacturer?.manufacturerName}
          </Link>
        )
      }
    },
    {
      accessorKey: 'region',
      header: 'Region',
      editable: false,
      filterVariant: 'text',
      size: 110,
      grow: true,
      Cell: ({ cell, row }) => {
        return row.original.region
      }
    },
    {
      accessorKey: 'Manufacturer.Ownership.ownershipName',
      header: 'Ownership',
      size: 110,
      grow: true,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'Manager.firstName',
      header: 'Manager',
      editable: false,
      grow: true,
      maxSize: 150,
      filterVariant: 'text',
      Cell: ({row}) => (
          <span style={{textTransform: 'capitalize'}}>{([row.original.Manager?.firstName ?? '', row.original.Manager?.lastName ?? ''].join(' ')).trim().toLowerCase()}</span>
          )
    },
    {
      accessorKey: 'score',
      header: 'Score',
      size: 110,
      grow: false,
      editable: false,
      filterVariant: 'number',
    },
    {
      accessorKey: 'updatedDate',
      header: 'Updated On',
      size: 110,
      grow: false,
      editable: false,
      filterVariant: 'date',
    },
  ])

  const getFitnessIndexList = async() => {
    props.startLoading()

    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.complaintTrends}/search` //@TODO: change to ApiGateway.fitnessIndexes
    const search = props.searchFilter ?? {}
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setDataRows(results)
    }
      
    props.stopLoading()
  }

  

  useEffect(() => {
    if (isMounted()) {
      getFitnessIndexList()
    }
  }, [])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      pagination: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 100
      }
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });


  return (
    <div className='App'>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>
        <Grid container rowSpacing={2} >
          <Grid item xs={true} md={true}>            
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )

}