import { useState, useMemo, useEffect } from 'react'
import { validateColumns, checkColumnError } from '../form/Form'
import { Grid, FormControlLabel, Checkbox, Autocomplete, FormHelperText, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

export default function ContactFormModal({ contactAssociationName, notificationsName, isInternal, contactLookup, mode, contactOptions, getCurrentValues, open, columns, onClose, addRow, rowSetter, onSubmit, parentType, facilityOrOwnershipUuid, contactTypeOptions }) {
  const [values, setValues] = useState(mode === 'add' ? () =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}) : getCurrentValues()
  );

  useEffect(() => {
    if (getCurrentValues) {
      setValues(getCurrentValues())
    }
  }, [getCurrentValues])

  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [existingContact, setExistingContact] = useState(null)
  const [emailSearches, setEmailSearches] = useState([])
  const [dirty, setDirty] = useState(false)

  const handleSubmit = async () => {
    const columnsValid = validateColumns(columns.filter(c => c.header !== 'Notifications'), values, errors, setErrors)

    if (values[contactAssociationName]?.length && columnsValid) {
      setSaving(true)
      if (onSubmit) {
        await onSubmit(values)
      } else {
        addRow(rowSetter, values)
      }
      setErrors({});
      setValues({});
      setSaving(false)
      onClose();
    }
    if (!values[contactAssociationName]?.length) {
      setErrors({ ...errors, [contactAssociationName]: `At least one Contact Type is required` })
    }
  };

  const disallowedEmails = contactOptions?.map(o => o.email.toLowerCase())

  const contactTypeValues = useMemo(() => {
    if (values[contactAssociationName]?.length) {
      return values[contactAssociationName]?.map(oc => {
        return contactTypeOptions.find(cto => cto.id === oc.ContactType?.id)
      })
    } else {
      return []
    }
  }, [contactAssociationName, values, contactTypeOptions])

  const trendsNotificationChecked = useMemo(() => {
    if (!values?.[notificationsName]?.length) return false
    return values?.[notificationsName]?.some(notification => {
      return notification.notificationType === 'trend'
    }) || false
  }, [values, notificationsName])

  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogTitle textAlign="center">{mode.charAt(0).toUpperCase() + mode.slice(1)} Record</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            margin={1}
            spacing={2}
          >
            <TextField
              key='email'
              label='Email'
              name='email'
              size='small'
              disabled={loading || mode === 'edit' || saving}
              value={values?.email || ''}
              onChange={(e) => {
                const email = e.target.value?.toLowerCase() || ''
                setValues({ ...values, [e.target.name]: email })
              }}
              error={!!errors.email}
              helperText={errors.email}
              onBlur={() => {
                checkColumnError('email', values.email, errors, setErrors, true)
                if (disallowedEmails?.includes(values.email)) {
                  setErrors({ ...errors, email: `Contact with email ${values.email} already exists` })
                } else {
                  const alreadySearched = emailSearches.find(f => f.email === values.email)
                  if (alreadySearched?.id) {
                    setValues(alreadySearched)
                    setExistingContact(alreadySearched)
                  } else if (alreadySearched?.email) {
                    setValues({ ...values, email: values.email })
                  } else if (values.email?.indexOf('@') > -1) {
                    (async () => {
                      setLoading(true)
                      const existing = await contactLookup(values.email)
                      setEmailSearches([...emailSearches, { existing, email: values.email }])
                      if (existing) {
                        if (isInternal !== undefined) {
                          existing[contactAssociationName] = existing[contactAssociationName].filter(oc => oc.ContactType.isInternal === isInternal)
                        }
                        setValues(existing)
                        setExistingContact(existing)
                      }
                      setLoading(false)
                    })()
                  }
                }
              }}
            />

            <TextField
              key='name'
              label='Name'
              name='name'
              size='small'
              disabled={loading || existingContact || saving}
              value={values?.name || ''}
              onChange={(e) => {
                setDirty(true)
                setValues({ ...values, [e.target.name]: e.target.value })
              }}
              error={!!errors.name}
              helperText={errors.name}
              onBlur={() => checkColumnError('name', values.name, errors, setErrors, true)}
            />

            <FormControl error={errors[contactAssociationName]}
              size='small'>

              <Autocomplete
                disabled={loading || saving}
                value={contactTypeValues}
                options={contactTypeOptions}
                getOptionLabel={option => option?.name}
                renderInput={(params) => <TextField {...params} label="Contact Types" />}
                multiple
                onChange={(e, newValue) => {
                  setDirty(true)
                  setValues({ ...values, [contactAssociationName]: newValue.map(ContactType => ({ ContactType })) })
                }}
                onBlur={(e) => {
                  if (!values[contactAssociationName]?.length) {
                    setErrors({ ...errors, [contactAssociationName]: `At least one contact type is required` })
                  }
                }}
                size='small'
              />
              <FormHelperText error={!values[contactAssociationName]?.length}>{errors[contactAssociationName]}</FormHelperText>
            </FormControl>

            <Grid container>

              <Grid item xs={6}>
                <Stack
                  margin={1}
                  spacing={2}
                >
                  <TextField
                    key='jobTitle'
                    label='Job Title'
                    name='jobTitle'
                    size='small'
                    disabled={loading || existingContact || saving}
                    value={values?.jobTitle || ''}
                    onChange={(e) => {
                      setDirty(true)
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }}
                    error={!!errors.jobTitle}
                    helperText={errors.jobTitle}
                    onBlur={() => checkColumnError('jobTitle', values.jobTitle, errors, setErrors, true)}
                  />

                  <MuiTelInput
                    size='small'
                    defaultCountry='US'
                    disableDropdown
                    forceCallingCode
                    value={values?.phone || ''}
                    disabled={loading || existingContact || saving}
                    onChange={(event) => {
                      setDirty(true)
                      setValues({ ...values, phone: event })
                    }}
                    label='Phone'
                    placeholder='Phone'
                    name='phone'
                    key='phone'
                    error={!!errors.phone}
                    helperText={errors.phone}
                    onBlur={() => checkColumnError('phone', values.phone, errors, setErrors, false)}
                  />

                  <TextField
                    key='extension'
                    label='Extension'
                    name='extension'
                    value={values?.extension || ''}
                    disabled={loading || existingContact || saving}
                    size='small'
                    onChange={(e) => {
                      setDirty(true)
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }}
                    error={!!errors.extension}
                    helperText={errors.extension}
                    onBlur={() => checkColumnError('extension', values.extension, errors, setErrors, false)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack
                  margin={1}
                  spacing={2}
                >
                  <TextField
                    multiline
                    key='note'
                    label='Note'
                    name='note'
                    value={values?.note || ''}
                    disabled={loading || existingContact || saving}
                    size='small'
                    onChange={(e) => {
                      setDirty(true)
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }}
                    error={!!errors.note}
                    helperText={`${values?.note?.length ?? 0}/255 ${errors.note ?? ''}`}
                    inputProps={{
                      maxLength: '255'
                    }}
                    onBlur={() => checkColumnError('note', values.note, errors, setErrors, false)}
                  />
                </Stack>
              </Grid>
            </Grid>

            <FormControlLabel
              control={<Checkbox
                checked={trendsNotificationChecked}
                disabled={loading || existingContact || saving}
                onChange={(e) => {
                  setDirty(true)
                  const { checked } = e.target
                  values[notificationsName] = values[notificationsName] || []
                  const plantTrendsItem = values?.[notificationsName].find(n => n.notificationType === 'trend')
                  const notifications = [...values?.[notificationsName]]
                  if (plantTrendsItem && checked) return
                  if (plantTrendsItem && !checked) notifications.splice(notifications.indexOf(plantTrendsItem), 1)
                  if (!plantTrendsItem && checked) notifications.push({ notificationType: 'trend' })
                  setValues({ ...values, [notificationsName]: notifications })
                }}
              />}
              label="Recieves Plant Trend Notifications"
            />

          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => { setDirty(false); setErrors({}); setValues({}); onClose(); }}>Cancel</Button>
        <LoadingButton
          size="small"
          color="primary"
          onClick={handleSubmit}
          loading={saving}
          disabled={!dirty}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          <span>{mode === 'add' ? 'Add New' : 'Save'} Contact</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};