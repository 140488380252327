import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material"
import { checkDateError, componentTextValue } from '../../form/Form'

export default function CertificationControl(props = {}) {
  const {
    fieldErrors,
    editMode,
    manufacturerProfile,
    onChange,
    setError,
    required,
    title,
    certificationField,
    certificationDateField,
  } = props

  return (<Grid container spacing={1} padding={.5}>
    <Grid item xs={12} sm={3}>
      <Typography color="text.secondary" sx={{ paddingTop: '6px', textAlign: 'right', fontWeight: 'bold'}}>{[title, (required ? ' *':'')].join('')}</Typography>
    </Grid>
    <Grid item xs={12} sm={2}>
      <FormControl size='small'
        fullWidth
        error={required && !!fieldErrors[certificationField]}
        >
        <Select
          id={certificationField}
          name={certificationField}
          disabled={!editMode}
          
          value={componentTextValue(manufacturerProfile[certificationField])}
          onChange={(e) => {
            onChange(e)
          }}
          
        >
          <MenuItem value={1}>Yes</MenuItem>
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={2}>Not Applicable</MenuItem>
        </Select>
        <FormHelperText hidden={!fieldErrors[certificationField]} >{fieldErrors[certificationField]}</FormHelperText>
      </FormControl>
    </Grid>
    { (manufacturerProfile[certificationField] === 1) && <>
    <Grid item xs={12} sm={2}>
      <Typography color="text.secondary" sx={{ paddingTop: '6px', textAlign: 'right', fontWeight: 'bold'}}>Date Certified:</Typography>
    </Grid>
    <Grid item xs={12} sm={2}>
      
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={!editMode}
            format="MM/DD/YYYY"
            id={certificationDateField}
            name={certificationDateField}
            value={dayjs(manufacturerProfile[certificationDateField]) || ''}
            slotProps={{
              textField: {
                size: 'small',
                required: true,
                error: (manufacturerProfile[certificationField] === 1 && !!fieldErrors[certificationDateField]),
                helperText: (fieldErrors[certificationDateField])
              },
            }}
            onChange={(value) => {
              onChange({ target: { name: certificationDateField, value } })
            }}
          />
        </LocalizationProvider>
    </Grid>
    </>
    }
  </Grid>)
}