import { default as React, useContext, useEffect, useState, useCallback } from 'react'
import { Box, Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import EventBus from '../../utils/EventBus'
import { validateFieldChange } from '../../form/Form'
import { searchManufacturers, saveManufacturer } from '../data'
import { useNavigate } from 'react-router-dom'
import OwnershipSelectionControl from '../OwnershipSelectionControl'

export default function ManufacturerDetailsSection(props = { manufacturer: undefined, setManufacturer: undefined, editMode: false, setSectionIsEditable: undefined, onStateValidation: undefined}) {
  const navigate = useNavigate()
  const manufacturer = props.manufacturer
  const { setManufacturer, editMode, setSectionIsEditable } = props
  const [valid, setValid] = useState(false)
  const [manufacturerDetail, setManufacturerDetail] = useState({ status: 'Active', isNorthstar: false })
  const roleDetails = useContext(RoleContext)
  const conflictDefaults = { manufacturerName: undefined, manufacturerId: undefined }
  const [conflicts, setConflicts] = useState(conflictDefaults)
  
  const isEditable = () => {
    if (roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])) {
      return true
    }
    return false
  }

  const validateForm = (_record = false) => {
    let _valid = false
    

    if ( !_record ) _record = manufacturerDetail

    const validations = [
      _record.ownershipUuid !== undefined,
      _record.manufacturerName !== undefined,
      _record.manufacturerName !== '',
    ]
    _valid = validations.every(v => {
      return v
    })

    setValid(_valid)
    if (props.onStateValidation !== undefined) {
      props.onStateValidation(_record, _valid)
    }
    return _valid
  }

  const onChange = ((e) => {
      const { id, name, value } = e.target
      validateFieldChange(id ?? name,value, manufacturerDetail, setManufacturerDetail, validateForm)
  })

  const initializeDetail = ( _record ) => {
    if (_record) {
      setManufacturerDetail(structuredClone(_record))
    }
  }

  const checkConflicts = async ( _record ) => {
    setConflicts(conflictDefaults)
    if ( ! _record ) _record = manufacturerDetail
    const search = {
      anyMatch: true,
      manufacturerName: _record.manufacturerName,
      manufacturerId: _record.manufacturerId
    }

    const result = await searchManufacturers(search)
    console.log(result)
    if (!result || !result.status || result.status === 'error') {
      throw new Error(result?.error ?? 'Could not validate due to unknown error')
    } else if (result.status === 'success' && result.results.length) {
      const hasConflict = !_record.uuid || result.results.some((o) => o.uuid !== _record.uuid)
      if (hasConflict) {
        let _conflicts = {}
        result.results.forEach((o) => {
          if ((_record.uuid ?? '') !== o.uuid) {
            ['manufacturerName', 'manufacturerId'].forEach((f) => {
              if ((_record[f] ?? '').toLowerCase() === o[f].toLowerCase()) {
                console.log(`${f} === ${(_record[f] ?? '').toLowerCase() === o[f].toLowerCase()}`)
                _conflicts = { ..._conflicts, [f]: 'exists'}
              }
            })
          }
        })
        setConflicts( { ...conflicts, ..._conflicts})
      }
      return hasConflict
    } 
    return false
  }

  const handleSave = async () => {
    console.log('Final validation...')
    try {
      const conflicts = await checkConflicts( manufacturerDetail )
      if (conflicts) {
        throw new Error('Conflict')
      } else {

        console.log('Saving...')
        if (valid) {
          const result = await saveManufacturer(manufacturerDetail.uuid, manufacturerDetail)
          if (result.status === 'success') {
            if (result.created) {
              navigate(`/manufacturer/edit/${result.instance.uuid}/details`)
            } else {
              props.onStateValidation(result.instance, false)
              setValid(false)
              setManufacturer(result.instance)
            }
          } else {
            throw new Error(result.error)
          }
        } else {
          throw new Error('Not valid for Save')
        }
      }
    } catch (error) {
      console.error(error)
      // TODO present error to user
    }
  }

  useEffect(() => {
    EventBus.on('saveRequested', handleSave)
    return () => {
      EventBus.removeListener('saveRequested', handleSave)
    }
  }, [manufacturerDetail, valid])

  useEffect(() => {
    if (props.manufacturer) {
      initializeDetail(props.manufacturer)
      if (setSectionIsEditable !== undefined) setSectionIsEditable(isEditable())
    }

  }, [props.manufacturer, props.editMode])

  return (
    <Card sx={{ padding: '12px' }}>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container columnGap={1} rowGap={0} spacing={1}>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Ownership:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.Ownership?.ownershipName}</Typography>
                }
                { editMode && 
                  <OwnershipSelectionControl
                    defaultOwnershipUuid={manufacturerDetail.Ownership?.uuid ?? undefined}
                    onSelectOwnership={undefined}
                    required={true}
                    requireOwnershipId={false}
                    includeLabel={false} />
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Facility Name:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.manufacturerName}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small' fullWidth
                  error={ (!manufacturerDetail.manufacturerName || manufacturerDetail.manufacturerName.trim() === '')}
                    id="manufacturerName"
                    variant='outlined'
                    value={manufacturerDetail.manufacturerName ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  { editMode && ((conflicts.manufacturerName ?? '') !== '') && 
                    <FormHelperText error={true}>Conflict { conflicts.manufacturerName }</FormHelperText>
                  }
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Bus. Name:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.manufacturerAltName}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small' fullWidth
                    id="manufacturerAltName"
                    variant='outlined'
                    value={manufacturerDetail.manufacturerAltName ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Address:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.manufacturerAddr}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small' fullWidth
                    id="manufacturerAddr"
                    variant='outlined'
                    value={manufacturerDetail.manufacturerAddr ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>City:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.manufacturerCity}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small' fullWidth
                    id="manufacturerCity"
                    variant='outlined'
                    value={manufacturerDetail.manufacturerCity ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>State:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.manufacturerState}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small' sx={{width: '50px'}}
                    inputProps={{ maxLength: 2 }}
                    id="manufacturerState"
                    variant='outlined'
                    value={manufacturerDetail.manufacturerState ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Zip Code:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.manufacturerZip}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small'
                    sx={{width: '125px'}}
                    inputProps={{ maxLength: 15 }}
                    id="manufacturerZip"
                    variant='outlined'
                    value={manufacturerDetail.manufacturerZip ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
            </Grid>
            
          </Grid>          
          <Grid item xs={12} md={6}>
            <Grid container columnGap={1} rowGap={0} spacing={1}>
              <Grid item xs={12} sm={4} alignContent={'center'}>
                  <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Type:</Typography>
                </Grid>
              <Grid item xs={12} sm={7}>
                  { !editMode &&
                    <Typography fontWeight='regular'> {manufacturerDetail.type}</Typography>
                  }
                  { editMode && 
                    <FormControl size='small' disabled={! editMode} fullWidth>
                    <Select name='status' size='small' fullWidth
                      error={(!manufacturerDetail.type || manufacturerDetail.type === '')}
                      value={manufacturerDetail.type ?? ''} 
                      onChange={onChange}
                      >
                        <MenuItem value='Producing Plant'>Producing Plant</MenuItem>
                        <MenuItem value='Co-packer'>Co-packer</MenuItem>
                        <MenuItem value='CPS'>CPS</MenuItem>
                        <MenuItem value='Distribution Center'>Distribution Center</MenuItem>
                        <MenuItem value='RTM'>RTM</MenuItem>
                        <MenuItem value='R&D'>R&D</MenuItem>
                        <MenuItem value='Re-packer'>Re-packer</MenuItem>
                    </Select>
                    </FormControl>
                  }
              </Grid>
              <Grid item xs={12} sm={4} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Production Code:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.productionCode}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small'
                    sx={{width: '125px'}}
                    inputProps={{ maxLength: 5 }}
                    id="productionCode"
                    variant='outlined'
                    value={manufacturerDetail.productionCode ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={4} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Global ID:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.manufacturerId}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small'
                    sx={{width: '125px'}}
                    inputProps={{ maxLength: 15 }}
                    id="manufacturerId"
                    variant='outlined'
                    value={manufacturerDetail.manufacturerId ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={4} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>CONA ID:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.conaId}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small'
                    sx={{width: '75px'}}
                    inputProps={{ maxLength: 4 }}
                    id="conaId"
                    variant='outlined'
                    value={manufacturerDetail.conaId ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={4} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>GLN #:</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
              { !editMode &&
                  <Typography fontWeight='regular'> {manufacturerDetail.globalLocationNum}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small'
                    sx={{width: '150px'}}
                    inputProps={{ maxLength: 15 }}
                    id="globalLocationNum"
                    variant='outlined'
                    value={manufacturerDetail.globalLocationNum ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={4} alignContent={'center'}>
                  <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Status:</Typography>
                </Grid>
              <Grid item xs={12} sm={7}>
                  { !editMode &&
                    <Typography fontWeight='regular'> {manufacturerDetail.status}</Typography>
                  }
                  { editMode && 
                    <FormControl size='small' disabled={! editMode}>
                    <Select name='status' size='small' sx={{ minWidth: '100px'}}
                      error={(!manufacturerDetail.status || manufacturerDetail.status === '')}
                      value={manufacturerDetail.status ?? ''} 
                      onChange={onChange}
                      >
                        <MenuItem value='Active'>Active</MenuItem>
                        <MenuItem value='Inactive'>Inactive</MenuItem>
                    </Select>
                    </FormControl>
                  }
              </Grid>
              <Grid item xs={12} sm={4} alignContent={'center'}>
                  <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Northstar:</Typography>
                </Grid>
              <Grid item xs={12} sm={7}>
                  { !editMode &&
                    <Typography fontWeight='regular'> {manufacturerDetail.isNorthstar ? 'Yes' : 'No'}</Typography>
                  }
                  { editMode && 
                    <FormControl size='small' disabled={! editMode}>
                    <Select name='status' size='small' sx={{ minWidth: '100px'}}
                      value={manufacturerDetail.isNorthstar} 
                      onChange={onChange}
                      >
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                    </Select>
                    </FormControl>
                  }
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      </Box>

    </Card>
  )
}