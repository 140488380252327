import { default as React, useContext, useEffect, useState, useMemo } from 'react'
import {  Paper } from '@mui/material'
import { Factory } from '@mui/icons-material'
import ConfirmDialog from '../form/ConfirmDialog'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate, useBlocker } from 'react-router-dom'
import RoleContext from '../auth/RoleContext';
import { ROLES } from '../auth/role';
import ManufacturerDetailsSection from './sections/ManufacturerDetailsSection'
import EventBus from '../utils/EventBus';

export default function CreateManufacturerPage(props = {}) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const roleDetails = useContext(RoleContext)
  const [isLoading, setIsLoading] = useState(true)
  const [tryAgainDialog, setTryAgainDialog] = useState(false)
  const [editMode, setEditMode] = useState(true)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [manufacturer, setManufacturer] = useState(false)

  const [isValidForSave, setIsValidForSave] = useState(false)
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isValidForSave &&
      currentLocation.pathname !== nextLocation.pathname
  )


  const breadcrumb = <div><span><Factory />&nbsp;{manufacturer?.manufacturerName || 'Manufacturer'}</span></div>

  const onSave = async () => {
    EventBus.emit('saveRequested')
    setIsValidForSave(false)
  }

  const onStateValidation = (inst, valid) => {
    setIsValidForSave(valid)
  }

  const tryAgainSave = async () => {
    // hideTryAgainDialog()
    await onSave()
  }

  const confirmCancel = () => {
    if (isValidForSave) { 
      setConfirmDialog(true)
    } else handleDiscard()
  }

  const handleRemain = () => {
    setConfirmDialog(false)
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
  }

  const handleDiscard = () => {
    // Create page only needs to invalidate changes and close
    setIsValidForSave(false)
    if (blocker.state === 'blocked') {
      blocker.proceed()
    }
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/manufacturer/list`)
    } else {
      navigate(-1)
    }
  }

  let saveUnsuccessfulError
  const getSaveUnsuccessfulText = () => {
    return `Could not save profile due to ${saveUnsuccessfulError}, do you want to try again?`
  }
  const hideTryAgainDialog = () => {
    setTryAgainDialog(false)
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const onEdit = () => {
    setEditMode(true)
  }

  const initializePage = async () => {
    startLoading()
    // 
    stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirmCancel()
    }
  }, [blocker.state])

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
      <FormViewCommandBar
        onSave={onSave}
        onEdit={onEdit}
        onCancel={handleClose}
        onClose={handleClose}
        enableSave={isValidForSave}
        showClose={!editMode}
        showCancel={editMode}
        showEdit={false}
        showSave={editMode}
      />
    }>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={handleRemain} handleConfirm={handleDiscard} yesLabel="Discard" noLabel="Stay on Page" />
      <ConfirmDialog title="Save was not successful" text={getSaveUnsuccessfulText()} open={tryAgainDialog} hideDialog={hideTryAgainDialog} handleConfirm={tryAgainSave} yesLabel="Try Again" noLabel="Cancel" />
      
      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto' }}
      >
        <ManufacturerDetailsSection editMode={editMode} onStateValidation={onStateValidation} />
      </Paper>
    </Page>
  )
}