import { default as React, useEffect, useState, useMemo, useCallback } from 'react'
import { Form, useNavigate, useParams } from 'react-router-dom'
import { TaskAlt } from '@mui/icons-material'
import { FormGroup, Switch, Box, Grid, Paper, IconButton, FormControl, FormLabel, Radio, FormControlLabel, RadioGroup, FormElement, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import AuthorizationDetailCard from './AuthorizationDetailCard'
import AuthorizationApprovalSection from './AuthorizationApprovalSection'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import { HIGHLIGHT_HEX } from './common.js'

export default function AuthorizationFormPage(props = {}) {
  const params = useParams()
  const { authorizationId } = params
  const { editable } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [mfrProfile, setMfrProfile] = useState({})
  const [authorization, setAuthorization] = useState()
  const [approvalCategories, setApprovalCategories] = useState([])
  const [approvalTypes, setApprovalTypes] = useState([])
  const [showChanges, setShowChanges] = useState(true)
  const [parent, setParent] = useState()
  const [changes, setChanges] = useState([])

  const ALERT_DURATION = 1000
  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const navigate = useNavigate()

  useEffect(() => {
    var body = document.body;
    if (body.className.indexOf('sidebar-collapse') > -1
      && body.className.indexOf('collapsed-by-preview') > -1) {
      body.className = body.className.replace('sidebar-collapse', '').replace('collapsed-by-preview', '');
    }
  }, [])

  const loadAuthorization = useCallback(async () => {
    if (authorizationId) {
      let response = []
      const requestConfig = await getRequestConfig()
      const basePath = `${ApiGateway.authorizations}/${authorizationId}`
      const { data } = await axios.get(basePath, requestConfig)
      const { result } = data
      if (result?.id) {
        setAuthorization(result)
        response.push(result)
        if (result.parentId) {
          const { data } = await axios.get(`${ApiGateway.authorizations}/${result.parentId}`, requestConfig)
          const { result: parentResult } = data
          response.push(parentResult)
          setParent(parentResult)
        } else {
          response.push(undefined)
        }

        if (result?.manufacturerId) {
          const requestConfig = await getRequestConfig()
          const basePath = `${ApiGateway.manufacturerProfile}/search`
          const search = { manufacturerId: result?.manufacturerId }
          const { data } = await axios.post(basePath, search, requestConfig)
          const { results } = data
          if (results?.length) {
            setMfrProfile(results[0])
            response.push(results[0])
          }
        }
      }
      return response
    } else {
      return []
    }
  }, [authorizationId])

  useEffect(() => {
    (async () => {
      const loadDefaultApprovals = async () => {
        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.authorization}/approval-types`
        const { data } = await axios.get(basePath, requestConfig)
        const { results } = data
        return results
      }
      const getApprovalCategories = async () => {
        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.authorization}/approval-categories`
        const { data } = await axios.get(basePath, requestConfig)
        const { results } = data
        return results
      }

      await Promise.all([
        loadDefaultApprovals().then(setApprovalTypes),
        getApprovalCategories().then(setApprovalCategories)
      ])

      await loadAuthorization()

    })()
  }, [loadAuthorization])

  const readOnly = useMemo(() => {
    return authorization?.workflowStatus === 'PUBLISHED' || !editable
  }, [authorization, editable])

  const breadcrumb = <span><TaskAlt />&nbsp;{readOnly ? '' : 'Edit '}Facility Authorization</span>

  const onSave = () => {

  }

  const confirmCancel = () => {

  }

  const onClose = () => {
    navigate('/facility-authorizations/list')
  }

  const notBlank = (val) => {
    return val !== undefined && val !== null && val !== ''
  }

  const authorizationValid = useMemo(() => {
    return (_authorization = authorization, _parent = parent, _mfrProfile = mfrProfile) => {
      const hasFile = _authorization?.isHistorical ? notBlank(_authorization?.AuthorizationFile) : true
      const hasStatus = notBlank(_authorization?.approvalStatus)
      const errors = [
        !hasFile ? 'Missing authorization letter' : undefined,
        !hasStatus ? 'Missing final approval status' : undefined,
      ]
      const hasMfrMgrInfo = notBlank(_mfrProfile?.plantManagerName) &&
        notBlank(_mfrProfile?.plantManagerPhone) &&
        notBlank(_mfrProfile?.plantManagerEmail)
      if (!hasMfrMgrInfo) {
        errors.push(`Manufacturer profile missing plant manager information`)
      }
      const hasMfrInfo = notBlank(_mfrProfile?.Manufacturer?.manufacturerName) &&
        notBlank(_mfrProfile?.Manufacturer?.manufacturerAddr) &&
        notBlank(_mfrProfile?.Manufacturer?.manufacturerCity) &&
        notBlank(_mfrProfile?.Manufacturer?.manufacturerState) &&
        notBlank(_mfrProfile?.Manufacturer?.manufacturerZip)
      if (!hasMfrInfo) {
        errors.push(`Manufacturer profile missing basic information`)
      }
      return [
        hasMfrMgrInfo &&
        hasMfrInfo &&
        hasFile &&
        hasStatus &&
        _authorization?.Approvals?.reduce((_valid, approval) => {
          const category = approvalCategories.find(c => c.id === approval.categoryId)
          const hasDescription = notBlank(approval.description)
          const hasDate = notBlank(approval.date)
          const hasApprovalStatus = notBlank(approval.approvalStatus)
          if (!hasDescription) errors.push(`The ${category?.label} section is missing a Description`)
          if (!hasDate) errors.push(`The ${category?.label} section is missing a Date`)
          if (!hasApprovalStatus) errors.push(`The ${category.label} section is missing an Approval Status`)
          return _valid &&
            hasDescription &&
            hasDate &&
            hasApprovalStatus
        }, true),
        errors
      ]
    }
  }, [mfrProfile, approvalCategories])

  const updateAuthorization = async (_authorization) => {
    setIsSaving(true)
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.authorizations}/${authorization?.id}`
    const { data } = await axios.put(basePath, _authorization, requestConfig)
    bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": ALERT_DURATION })
    setIsSaving(false)
  }

  const actionButtons = useMemo(() => {
    if (!authorization) return

    const onPreview = async () => {
      startLoading()
      const [_authorization, _parent, _mfrProfile] = await loadAuthorization()
      const [isValid, errors] = authorizationValid(_authorization, _parent, _mfrProfile)
      if (isValid) {
        stopLoading()
        navigate(`/facility-authorizations/${authorizationId}/preview`)
      } else {
        bus.emit(Event.ALERT, {
          "text": 'Not ready to publish',
          "style": "error",
          "showClose": true,
          "duration": 5000,
          "jsx": <>
            {errors.map(e => <div>{e}<br /></div>)}
          </>
        })
        stopLoading()
      }
    }

    const onPublish = async () => {
      startLoading()
      const [_authorization, _parent, _mfrProfile] = await loadAuthorization()
      const [isValid, errors] = authorizationValid(_authorization, _parent, _mfrProfile)
      if (isValid) {
        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.authorizations}/${authorizationId}`
        const { data } = await axios.put(basePath, {
          workflowStatus: 'PUBLISHED',
          approvalStatus: authorization?.approvalStatus,
          publishedDate: new Date().toISOString()
        }, requestConfig)
        stopLoading()
        bus.emit(Event.ALERT, { "text": 'Authorization Published', "style": "success", "duration": 2000 })
        navigate(`/facility-authorizations/${authorizationId}`)
      } else {
        bus.emit(Event.ALERT, {
          "text": 'Not ready to publish',
          "style": "error",
          "showClose": true,
          "duration": 5000,
          "jsx": <>
            {errors.map(e => <div>{e}<br /></div>)}
          </>
        })
        stopLoading()
      }
    }

    if (!readOnly) {
      if (!authorization?.isHistorical) {
        return [
          {
            label: "Preview",
            onClick: onPreview,
            enabled: true,
            show: true
          }
        ]
      }
      return [
        {
          label: "Publish",
          onClick: onPublish,
          enabled: true,
          show: true
        }
      ]
    }
  }, [authorizationId, authorization, readOnly, authorizationValid, navigate, loadAuthorization])

  const onChangeApprovalStatus = e => {
    const _authorization = { ...authorization }
    _authorization.approvalStatus = e.target.value
    setAuthorization(_authorization)
    updateAuthorization({ approvalStatus: e.target.value })
  }

  useEffect(() => {
    if(!parent?.id || !authorization?.id) {
      setChanges([])
    } else {
      function compareObjects(obj1 = { Approvals: [] }, obj2 = { Approvals: [] }, topLevelFields) {
        const differences = []
  
        // Compare specified top-level properties
        topLevelFields.forEach(key => {
          if (obj1[key] !== obj2[key]) {
            differences.push(key);
          }
        });
  
        return differences;
      }
      const topLevelFields = ['approvalStatus']
  
      const changes = compareObjects(parent, authorization, topLevelFields)
      setChanges(changes)
    }
  }, [authorization, parent])

  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
          commandBar={
            <FormViewCommandBar onSave={onSave} onCancel={confirmCancel}
              leftJsx={
                <FormGroup className='authorization-change-toggle'>
                  <FormControlLabel
                    disableTypography
                    className={`authorization-change-toggle${showChanges ? ' authorization-change-highlighted' : ''}`}
                    control={
                      <Switch
                        size='small'
                        checked={showChanges}
                        onChange={e => setShowChanges(!showChanges)}
                      />
                    }
                    labelPlacement='start'
                    label={
                      <Typography
                        color='text.secondary'
                        variant='body2'
                        fontWeight='bold'
                      >
                        {showChanges ? "CHANGES HIGHLGHTED" : "HIGHLIGHT CHANGES"}
                      </Typography>
                    }
                  />
                </FormGroup>
              }
              loading={isLoading || isSaving}
              enableSave={true}
              showClose={true}
              onClose={onClose}
              showCancel={false}
              showSave={false}
              actionButtons={actionButtons}
            />}>

          <AuthorizationDetailCard
            readOnly={readOnly}
            mfrProfile={mfrProfile}
            authorization={authorization || {}}
            approvalStatus={authorization?.approvalStatus}
            approvalStatusHighlighted={changes?.includes('approvalStatus') && showChanges}
            onChangeApprovalStatus={onChangeApprovalStatus}
          />

          {authorization ? approvalCategories.map(category => {
            const approvals = (authorization?.Approvals || []).filter(a => a.categoryId === category.id)
            if (!approvals.length && (readOnly || !editable)) return undefined
            return <AuthorizationApprovalSection
              showChanges={showChanges}
              onQueueFull={() => setIsSaving(true)}
              onQueueEmpty={() => setIsSaving(false)}
              alertDuration={ALERT_DURATION}
              readOnly={readOnly}
              key={`approval-section-${category.id}`}
              category={category}
              approvals={approvals}
              approvalTypes={(approvalTypes || []).filter(a => a.categoryId === category.id)}
              authorization={authorization}
              parentAuthorization={parent}
            />
          }) : undefined}

        </Page>
      )}
    </RoleContext.Consumer>
  )
}