import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { CSSTransition } from 'react-transition-group';

export default function MenuItem(props = {}) {

  const location = useLocation()
  const submenu1 = useRef(null)
  const submenu = useRef(null)
  const activePaths = props.activePaths || []
  const [selected, setSelected] = useState(false)

  let [isOpen, setIsOpen] = useState(false)
  let [submenuHeight, setSubmenuHeight] = useState(null)

  useEffect(() => {
    const _selected = props.selected !== undefined ? props.selected : activePaths.filter(path => {
      let re = path
      if (typeof path?.test !== 'function') re = new RegExp(path)
      return re.test(location.pathname)
    }).length > 0
    setSelected(_selected)
  }, [location.pathname])

  useEffect(() => {
    if (submenu?.current) {
      const height = submenu?.current?.clientHeight
      if (height > 0 && height >= submenuHeight) {
        setSubmenuHeight(height)
      }
    }
  }, [submenu?.current?.clientHeight])

  let className = isOpen ? 'treeview-menu visible' : 'treeview-menu'
  className += " " + props.theme

  let link = ('' + props.href).indexOf('#') > -1 ?
    <HashLink to={props.href}
    >

      {props.icon}
      <span>{props.title}</span>
    </HashLink>
    :
    <Link to={props.href}
    >
      {props.icon}
      <span>{props.title}</span>
    </Link>

  const expanded = props.expanded !== undefined && props.expanded !== null ? props.expanded : false

  function calculateAnimationSpeed(height, baseSpeed = props.animationSpeed || 300) {
    const referenceHeight = 100;  // A reference height in px (e.g., 100px) for the baseSpeed
    return (height / referenceHeight) * baseSpeed;
  }

  const styles = useMemo(() => {
    const animationSpeed = calculateAnimationSpeed(submenuHeight)
    const _styles =  `.${props.id}-sub-menu-enter {opacity: 0;max-height: 0px;}
    .${props.id}-sub-menu-enter-active {opacity: 0;max-height: 0px;}
    .${props.id}-sub-menu-enter-done {opacity: 1;max-height: ${submenuHeight}px;transition: opacity ${animationSpeed}ms, max-height ${animationSpeed}ms ease-out;}
    .${props.id}-sub-menu-exit {opacity: 1;max-height: ${submenuHeight}px;}
    .${props.id}-sub-menu-exit-active {opacity: 1;max-height: ${submenuHeight}px;}
    .${props.id}-sub-menu-exit-done {opacity: 0;max-height: 0px;transition: opacity ${animationSpeed}ms, max-height ${animationSpeed}ms ease-out;}
    `
    return _styles
  }, [props.id, submenuHeight])


  useEffect(() => {
    if( submenu1.current?.style && props.children) {
    submenu1.current.style.display = 'inline'
    const height = submenu1.current.clientHeight
    setSubmenuHeight(height + 50)
    submenu1.current.style.display = 'none'
    }
  }, [submenu1.current, props.children])

  return (
    <li className={`${selected ? 'menu-selected' : ''}${props.wrap ? 'whitespace-wrap' : ''}`}>
      <style>{styles}</style>
      {link}
      {props.children && <div ref={submenu1} style={{ display: 'none', overflow: 'hidden', position: 'absolute', zIndex: '-10000' }} ><ul className={className}>{props.children}</ul></div>}
      <CSSTransition
        nodeRef={submenu}
        in={expanded}
        timeout={0}
        classNames={`${props.id}-sub-menu`}
      >
        <div className={`${props.id}-sub-menu-enter`} ref={submenu} style={{ overflow: 'hidden' }} ><ul className={className}>{props.children}</ul></div>
      </CSSTransition>
    </li>
  )
}