import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import React, { Children, useRef, useState } from 'react'

export default function Category(props = {}) {
    const myRef = useRef(null)
    const internalProps = { expandIcon: <ExpandMoreIcon/>, ...props}

    const getId = (child) => {
        return `${props.category}.${child.props.section}` 
    }
    const [expanded, setExpanded] = useState(props.defaultExpanded ?? true)
    const [initialized, setInitialized] = useState(props.defaultExpanded ?? true)
    const [icon, setIcon] = useState((props.defaultExpanded ?? true) ? <ExpandLessIcon/> : <ExpandMoreIcon/>)

    const collapse = () => {
        setIcon(<ExpandMoreIcon/>)
        setExpanded(false)
        let pos = myRef.getBoundingClientRect()
        window.scrollTo({top: pos.top, left: 0, behavior: 'smooth'})
    }

    const toggle = () => {
        if(props.expandable === false) return
        setInitialized(true)
        setIcon(expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>)
        setExpanded(!expanded)
    }
 
    let title = <Typography className='tis-form-title'>{internalProps.title}</Typography>

    return (
        <Card className={`category${props.condensed ? ' condensed' : ''}`} sx = {{width: '100%'}}>
            <CardHeader style={props.centered !== undefined ? props.centered && { textAlign: 'center' } : {}} ref={myRef} title={title} onClick={toggle} action={props.expandable === undefined ? icon : props.expandable && icon}/>
            <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
                {initialized && <CardContent className="content">
                    {Children.toArray(internalProps.children).map((child, idx) => {
                        return React.cloneElement(child, {id: getId(child), category: props.category, defaultExpanded: idx === 0})
                    })}
                    <div hidden={internalProps.hideCollapse || false} className="tis-collapse" onClick={collapse}>Collapse {internalProps.title} <ExpandLessIcon/></div>
                </CardContent>}
            </Collapse>
        </Card>
    )
}