import ProductTable from '../profile-components/ProductTable';
import { default as React, useContext, useEffect, useState } from 'react'
import Category from '../../form/Category';
import { deleteChildItem, createOrUpdateMfrProfileChildItem } from '../data'
import { getRequestConfig } from '../../auth/auth';
import axios from 'axios';
import { ApiGateway } from '../../config/config';
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
export default function ManufacturerProfileChildItemSection(props = {}) {
  const roleDetails = useContext(RoleContext)
  const { 
    manufacturerProfile,
    childItemType,
    openAssessment, 
    editMode, 
    setSectionIsEditable
  } = props

  const productItemTypes = [
    {
      name: 'process-type-line',
      title: 'Currently manufactured in this location',
      columnSet: 'productsColumns'
    },
    {
      name: 'raw-material',
      title: 'Raw Materials',
      columnSet: 'rawMaterialsColumns'
    },
    {
      name: 'comanufactured-product',
      title: 'Co-manufactured on the same lines as products produced for The Coca-Cola Company',
      columnSet: 'comanufacturedColumns'
    },
    {
      name: 'backup-location',
      title: 'Business Continuity: List backup locations',
      columnSet: 'backupColumns'
    }
  ]
  
  const [rows, setRows] = useState([]);
  const [productItemType, setProductItemType] = useState(null)

  const loadMfrProfileChildItems = async (mfrProfileId, requestConfig) => {
    if (!requestConfig) {
      requestConfig = await getRequestConfig()
    }
    const basePath = `${ApiGateway.manufacturerProfile}/${mfrProfileId}/${childItemType}/`
    const responses = await axios.get(`${basePath}`, requestConfig)
    if (responses.data) setRows(responses.data.results)
  }

  useEffect(() => {
    if (childItemType) {
      setProductItemType(productItemTypes.find((p) => p.name === childItemType))
    }
    if (manufacturerProfile) loadMfrProfileChildItems(manufacturerProfile.id)
  }, [manufacturerProfile, childItemType])

  const isEditable = () => {
    if (roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])) {
      return true
    }
    if (openAssessment && roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      return true
    }
    return false
  }
  useEffect(() => {
    setSectionIsEditable(false)
  }, [])

  return productItemType && roleDetails && (
    <Category title={productItemType.title} expandable={false} condensed hideCollapse>
      <ProductTable
        columnSet={productItemType.columnSet}
        productsData={rows || []}
        handleSaveItem={(a, b, c) => createOrUpdateMfrProfileChildItem(a, b, c, manufacturerProfile.id)}
        handleDeleteItem={(a, b, c) => deleteChildItem(a, b, c, manufacturerProfile.id)}
        itemType={productItemType.name}
        setProductsData={setRows}
        editMode={isEditable()}
      />
    </Category>
  )
}