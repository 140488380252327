export const domain = 'https://mfi-api.dev.tis.coke.com'
export const azureClientId = 'c42a14e9-49d7-4f22-9eca-f28e500d270b'
export const azureTenantId = 'eb36d020-2c35-44de-bc7a-554cc979b26d'
export const apiKey = 'SyvAFAUua29HhpuJCtRRt7xwYNAIAp9o4hApYNLB'
export const stage = 'dev'
export const featureFlags = {
  '764709': true,
  '792789': true,
  '1896027': true,
  authorization: true,
  cpkBottledWater: true,
  mfi: true,
  facman: true,
  imFiltering: true,
}
