import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/css/index.css';
import App from './app/App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  {path: "*", element: <App />}
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
  // <Router>
  //   <App />
  // </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
