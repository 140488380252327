import { default as React, useEffect, useState, useMemo, useRef, makeStyles, useCallback } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import { UndoOutlined, TaskAlt, DeleteForever, CalendarMonth, CheckCircle, CancelOutlined, Radio } from '@mui/icons-material'
import { ThemeProvider, createTheme, Checkbox, Backdrop, CircularProgress, Popover, Button, Card, Divider, OutlinedInput, TextField, Autocomplete, Box, Grid, Paper, IconButton, FormControl, FormElement, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import ManufacturerAutocomplete from '../form/ManufacturerAutocomplete'
import QSEAutocomplete from '../form/QSEAutoComplete'
import WizardCommandBar from '../commandBar/WizardCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { DatePicker, MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FileListControl from '../form/FileListControl'
import { MaterialReactTable, useMaterialReactTable, GridActionsCellItem } from 'material-react-table'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { v4 as uuid } from 'uuid'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import { approvalStatuses, HIGHLIGHT_HEX, toFormattedDate } from './common.js'
import { toPascalCase } from '../utils/TextUtils.js'

class CallbackQueue {
  constructor(props) {
    const { onQueueFull, onQueueEmpty } = props
    this.onQueueFull = onQueueFull
    this.onQueueEmpty = onQueueEmpty
    this.props = props
    this.queue = [];
    this.processing = false; // To keep track if the queue is being processed
  }

  async enqueue(callback) {
    this.queue.push(callback);
    // Automatically start processing if not already doing so
    if (!this.processing) {
      await this.processQueue();
    }
  }

  async processQueue() {
    if (this.queue.length === 0) {
      this.onQueueEmpty()
      bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": 1000 })
      this.processing = false;
      return;
    }

    if (this.processing !== true) this.onQueueFull()
    bus.emit(Event.ALERT, { "text": 'Saving...', "style": "info", "duration": 10000 })

    this.processing = true;
    const callback = this.queue.shift();

    // Pass a function to the callback which will trigger the next callback
    await callback();
    await this.processQueue();
  }
}

export default function AuthorizationApprovalSection(props = {}) {
  const { category, approvalTypes: propsApprovalTypes = [], authorization, approvals: propsApprovals, readOnly } = props
  const [approvals, setApprovals] = useState()
  const [editableRow, setEditableRow] = useState('')
  const [focusedRowId, setFocusedRowId] = useState('')
  const [focusedFieldId, setFocusedFieldId] = useState('')
  const [focusedFieldValue, setFocusedFieldValue] = useState('')
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogApprovalDescription, setAddDialogApprovalDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [lastDeletedApproval, setLastDeletedApproval] = useState()
  const [showMultiSelect, setShowMultiSelect] = useState()
  const [selectedRows, setSelectedRows] = useState([])
  const [editDialogState, setEditDialogState] = useState({})
  const [changes, setChanges] = useState()

  const { showChanges, onItemProcessed, parentAuthorization } = props
  const queue = new CallbackQueue(props);

  useEffect(() => {
    if (!approvals && propsApprovals) {
      setApprovals(propsApprovals)
    }

  }, [approvals, propsApprovals])

  const approvalTypes = useMemo(() => {
    return propsApprovalTypes.filter(da => {
      return !approvals?.find(a => {
        return da.id === a.approvalTypeId
      })
    })
  }, [propsApprovalTypes, approvals])

  const paperRef = useRef(null)

  useEffect(() => {
    function compareObjects(lineItems1 = [], lineItems2 = [], lineItemFields) {
      const differences = {};

      // Helper function to compare line items based on specified fields
      function compareLineItems(item1, item2) {
        const itemDifferences = [];
        lineItemFields.forEach(key => {
          let val1 = item1[key]
          let val2 = item2[key]
          if(key === 'date') {
            val1 = toFormattedDate(item1[key])
            val2 = toFormattedDate(item2[key])
          }
          if (val1 !== val2) {
            itemDifferences.push(key);
          }
        });
        return itemDifferences;
      }

      // Create a map of lineItems from obj1 for easy lookup
      const lineItemMap1 = {};
      lineItems1.forEach(item => {
        lineItemMap1[`${item.displayOrder}`] = item;
      });

      // Compare line items
      lineItems2.forEach(item2 => {
        const item1 = lineItemMap1[item2.displayOrder];
        if (item1) {
          const itemDifferences = compareLineItems(item1, item2);

          if (itemDifferences.length > 0) {
            differences[item2.displayOrder] = itemDifferences;
          }
        } else {
          differences[item2.displayOrder] = ['new item'];
        }
      });

      return differences;
    }

    const lineItemFields = [
      'categoryId',
      'approvalTypeId',
      'description',
      'date',
      'approvalStatus',
      'line',
      'mostSensitiveProduct',
    ]
    const changes = compareObjects(
      parentAuthorization?.Approvals.filter(a => a.categoryId === category.id),
      approvals,
      lineItemFields
    )
    setChanges(changes)
  }, [parentAuthorization, approvals])

  const rowIsEditable = useCallback((row) => {
    const editable = (editableRow === row.original.id && !focusedRowId) || focusedRowId === row.original.id
    return editable
  }, [editableRow, focusedRowId])

  const rowIsFocused = useCallback((row, fieldId) => {
    const focused = row.original.id === focusedRowId && fieldId === focusedFieldId
    return focused
  }, [focusedRowId, focusedFieldId])

  const isRowDirty = useCallback((focused, row) => {
    if (!focused) return false
    return row.original[focusedFieldId] !== focusedFieldValue
  }, [focusedFieldId, focusedFieldValue])

  const deleteApproval = useMemo(() => {
    return async (approvalId) => {
      const _approvals = [...approvals]
      const _approval = _approvals?.find(a => a.id === approvalId)
      queue.enqueue(async () => {
        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.authorizations}/${authorization.id}/approvals/${_approval.id}`
        const { data } = await axios.delete(basePath, requestConfig)
        bus.emit(Event.ALERT, {
          "text": 'Approval Deleted',
          "style": "success",
          "duration": 5000,
          "showClose": true
        })
      })
      _approvals?.splice(_approvals?.indexOf(_approval), 1)
      setApprovals(_approvals)
      setEditableRow('')
      // setIsLoading(false)
    }
  }, [approvals, authorization.id, setEditableRow, setApprovals])

  const createApproval = useMemo(() => {
    return async (approval) => {
      queue.enqueue(async () => {
        const requestConfig = await getRequestConfig()
        const basePath = `${ApiGateway.authorizations}/${authorization.id}/approvals`
        const { data } = await axios.put(basePath, approval, requestConfig)
        bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": props.alertDuration || 1000 })
      })
      const _approvals = [...approvals, approval]
      setApprovals(_approvals)
    }
  }, [approvals, authorization.id])

  const updateOneOrMoreApprovals = useMemo(() => {
    return async (approval) => {
      const approvals = Array.isArray(approval) ? approval : [approval]
      queue.enqueue(async () => {
        const promises = approvals.map(a => {
          return new Promise((f, r) => {
            getRequestConfig()
              .then(requestConfig => {
                const basePath = `${ApiGateway.authorizations}/${authorization.id}/approvals/${a.id}`
                return axios.put(basePath, a, requestConfig)
              })
              .then(f)
              .catch(r)
          })
        })
        await Promise.all(promises)
        // bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": props.alertDuration || 1000 })
      })
    }
  }, [authorization.id, props.alertDuration])

  const handleChange = useMemo(() => {
    return (approvalId, fieldId, newValue) => {
      if (approvalId === focusedRowId) {
        return setFocusedFieldValue(newValue)
      }
      const _approvals = [...approvals]
      const _approval = _approvals?.find(a => a.id === approvalId)
      _approval[fieldId] = newValue
      updateOneOrMoreApprovals(_approval)
      setApprovals(_approvals)
    }
  }, [approvals, focusedRowId, updateOneOrMoreApprovals])

  const handleSelectRow = useMemo(() => {
    return (approvalId) => {
      const _selectedRows = [...selectedRows]
      const selectedIndex = _selectedRows.indexOf(approvalId)
      if (selectedIndex > -1) {
        _selectedRows.splice(selectedIndex, 1)
      } else {
        _selectedRows.push(approvalId)
      }
      setSelectedRows(_selectedRows)
    }
  }, [selectedRows])

  const selectionState = useMemo(() => {
    if (selectedRows.length === 0) return 'none'
    if (approvals?.length === selectedRows.length) return 'all'
    return 'some'
  }, [approvals, selectedRows])

  const handleSelectAllRows = useMemo(() => {
    return () => {
      if (selectionState === 'some' || selectionState === 'none') {
        const _selectedRows = approvals?.map(a => a.id)
        setSelectedRows(_selectedRows)
      }
      if (selectionState === 'all') {
        setSelectedRows([])
      }
    }
  }, [approvals, selectionState])


  const columns = useMemo(() => {

    const TextCell = (row, fieldId, actionsSize = 3) => {
      const rowChanges = changes?.[row.original.displayOrder] || []
      const highlighted = parentAuthorization !== undefined && showChanges ? rowChanges.filter(s => [fieldId, 'new item'].includes(s)).length > 0 : false
      const fieldValue = !row.original[fieldId] ? '' : row.original[fieldId]
      const editable = rowIsEditable(row)
      const focused = rowIsFocused(row, fieldId)
      const dirty = isRowDirty(focused, row)
      return <Grid container>
        <Grid item xs={dirty ? 12 - actionsSize : 12}>
          <FormControl fullWidth size='small'>
            <TextField
              className="authorization-approval-text-input"
              disabled={readOnly}
              sx={{
                '& .MuiInputBase-input': highlighted ? {
                  backgroundColor: HIGHLIGHT_HEX,
                  borderRadius: '4px',
                } : {},
                '& .MuiInputBase-root': {
                  paddingLeft: '0px !important',
                  paddingRight: '0px !important',
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                },
                "& fieldset": { border: 'none' },
              }}
              variant='outlined'
              InputProps={{
                style: { paddingTop: '0px', paddingBottom: '0px', fontSize: '0.875rem' }
              }}
              multiline
              fullWidth
              name={fieldId}
              value={focused ? focusedFieldValue : fieldValue || ''}
              onChange={(e) => handleChange(row.original.id, fieldId, e.target.value)}
              onFocus={(e) => {
                setFocusedFieldId(fieldId)
                setFocusedRowId(row.original.id)
                setFocusedFieldValue(fieldValue)
              }}
              onBlur={e => {
                setTimeout(() => {
                  setFocusedFieldId('')
                  setFocusedRowId('')
                  setFocusedFieldValue('')
                }, 100)
              }}
            />
          </FormControl>
        </Grid>
        {editable && dirty && <Grid item xs={actionsSize}>
          <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
            <UndoOutlined sx={{ cursor: 'pointer' }} fontSize='small' />
            <Divider style={{ color: '#a3a3a3', margin: '0' }} orientation="vertical" variant="middle" flexItem />
            <CheckCircle onClick={(e) => {
              const _approvals = [...approvals]
              const _approval = _approvals?.find(a => a.id === row.original.id)
              _approval[focusedFieldId] = focusedFieldValue
              setApprovals(_approvals)
              updateOneOrMoreApprovals(_approval)
              setEditableRow('')
              setFocusedFieldId('')
              setFocusedRowId('')
              setFocusedFieldValue('')
            }} sx={{ cursor: 'pointer' }} fontSize='small' color='success' />
          </div>
        </Grid >}
      </Grid >
    }

    const getColumns = (descriptionLabel, descriptionSize = 3, additionalColumns = []) => {
      return [
        {
          accessorKey: 'selected',
          minSize: 0.2,
          maxSize: 0.2,
          size: 0.2,
          Cell: ({ row }) => {
            return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                checked={selectedRows.includes(row.original.id)}
                onChange={() => handleSelectRow(row.original.id)}
                sx={{
                  color: 'gray',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
                size='small'
              />
            </div>
          },
          Header: ({ row }) => {
            return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                indeterminate={selectionState === 'some' ? true : undefined}
                checked={selectionState === 'all' ? true : selectionState === 'none' ? false : false}
                onChange={handleSelectAllRows}
                sx={{
                  color: 'gray',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
                size='small' />
            </div>
          }
        },
        {
          accessorKey: 'description',
          header: descriptionLabel,
          minSize: descriptionSize,
          size: descriptionSize,
          Cell: ({ row }) => TextCell(row, 'description', 2)
        },
        ...additionalColumns,
        {
          accessorKey: 'date',
          header: 'Date',
          minSize: 0.8,
          size: 0.8,
          Cell: ({ row }) => {
            const rowChanges = changes?.[row.original.displayOrder] || []
            const highlighted = parentAuthorization !== undefined && showChanges ? rowChanges.filter(s => ['date', 'new item'].includes(s)).length > 0 : false
            const approvalDate = row.original.date
            const editable = rowIsEditable(row)
            return <FormControl size="small" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={readOnly}
                  size="small"
                  sx={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    "& fieldset": { border: 'none' },
                  }}
                  format="MM/DD/YYYY"
                  value={dayjs(approvalDate)}
                  onChange={(date) => handleChange(row.original.id, 'date', date)}
                  slots={{
                    openPickerIcon: () => {
                      return <CalendarMonth
                        sx={{
                          fontSize: 24,
                          display: editable ? 'block' : 'none',
                        }}
                      />
                    }
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: highlighted ? {
                        '& fieldset': {
                          border: `1px solid ${HIGHLIGHT_HEX} !important`,
                        },
                        '& .MuiInputBase-root': {
                          backgroundColor: `${HIGHLIGHT_HEX} !important`,
                        },
                      } : {
                        '& fieldset': {
                          border: 'none'
                        }
                      },
                      InputProps: {
                        sx: {
                          height: '24px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          fontSize: '0.875rem'
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          }
        },
        {
          accessorKey: 'approvalStatus',
          header: 'Approval Status',
          minSize: 0.8,
          size: 0.8,
          Cell: ({ row }) => {
            const rowChanges = changes?.[row.original.displayOrder] || []
            const highlighted = parentAuthorization !== undefined && showChanges ? rowChanges.filter(s => ['approvalStatus', 'new item'].includes(s)).length > 0 : false
            const approvalStatus = !row.original.approvalStatus ? '' : row.original.approvalStatus
            const editable = rowIsEditable(row)
            return <FormControl fullWidth size='small'>
              <Select
                disabled={readOnly}
                variant='outlined'
                sx={{
                  "& ": highlighted ? {
                    backgroundColor: `${HIGHLIGHT_HEX} !important`
                  } : undefined,
                  "& fieldset": {
                    border: 'none',
                  },
                  height: '24px', fontSize: '0.875rem'
                }}
                size='small'
                fullWidth
                name="approvalStatus"
                value={approvalStatus || ''}
                slotProps={{
                  textField: {
                    size: 'small',
                    InputProps: {
                      sx: {
                        height: '24px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        fontSize: '0.875rem'
                      },
                    },
                  },
                }}
                input={<OutlinedInput sx={{ 
                  fontSize: '0.875rem'
                }} />}
                onChange={(e) => handleChange(row.original.id, 'approvalStatus', e.target.value)}
                IconComponent={editable ? undefined : () => null}
              >
                {approvalStatusOptions}
              </Select>
            </FormControl>
          }
        },
        {
          accessorKey: 'actions',
          header: '',
          minSize: 0.3,
          maxSize: 0.3,
          size: 0.3,
          Cell: ({ row }) => {
            if (editableRow === row.original.id) {
              return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <DeleteForever sx={{ cursor: 'pointer' }} onClick={(e) => deleteApproval(row.original.id)} />
              </div>
            }
          }
        },
      ]
    }

    const categories = {
      APPROVED_MANUFACTURING_PROCS: {
        columns: getColumns('Process')
      },
      OTHER_PROCESSES: {
        columns: getColumns('Process')
      },
      SAFETY_ENVIRONMENTAL_PGM_ASSESSMENT: {
        columns: getColumns('Program')
      },
      APPROVED_PRODUCTION_PROCS: {
        columns: getColumns('Process', 1, [
          {
            accessorKey: 'mostSensitiveProduct',
            header: 'Most Sensitive Product for Line',
            minSize: 1,
            size: 1,
            Cell: ({ row }) => TextCell(row, 'mostSensitiveProduct')
          },
          {
            accessorKey: 'line',
            header: 'Line',
            minSize: 1,
            size: 1,
            Cell: ({ row }) => TextCell(row, 'line')
          }
        ])
      },

      PROCESS_CHANGE_VALIDATIONS: {
        columns: getColumns('Approved Modifications to Operating Requirements', 2, [
          {
            accessorKey: 'line',
            header: 'Line',
            minSize: 1,
            size: 1,
            Cell: ({ row }) => TextCell(row, 'line')
          }
        ])
      },
      APPROVED_MOD_OPERATING_REQS: {
        columns: getColumns('Process')
      },
      MODIFIED_SANITATION_PROCS: {
        columns: getColumns('Process')
      },
      REDUCE_MONITORING_FREQUENCIES: {
        columns: getColumns('Process', 2, [
          {
            accessorKey: 'line',
            header: 'Line',
            minSize: 1,
            size: 1,
            Cell: ({ row }) => TextCell(row, 'line')
          }
        ])
      },
      EQUIPMENT_TECH_VALIDATION: {
        columns: getColumns('Process', 2, [
          {
            accessorKey: 'line',
            header: 'Line',
            minSize: 1,
            size: 1,
            Cell: ({ row }) => TextCell(row, 'line')
          }
        ])
      },
      APPROVED_NEW_EQUIPMENT_TECH: {
        columns: getColumns('Process')
      },
    }

    return categories[category.id]?.columns || []
  }, [
    approvals,
    category,
    editableRow,
    focusedFieldId,
    focusedFieldValue,
    isRowDirty,
    rowIsEditable,
    rowIsFocused,
    deleteApproval,
    handleChange,
    readOnly,
    updateOneOrMoreApprovals,
    selectedRows,
    handleSelectRow,
    handleSelectAllRows,
    selectionState,
    changes,
    showChanges
  ])

  const _setEditableRow = (id) => setEditableRow(id)
  const clearEditableRow = () => setEditableRow('')
  const _setMultiSelect = () => setShowMultiSelect(true)
  const clearMultiSelect = () => setShowMultiSelect(false)

  const table = useMaterialReactTable({
    columns,
    data: (approvals || []).toSorted((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)),
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableBottomToolbar: false,
    enableEditing: false,
    layoutMode: 'grid',
    renderTopToolbar: false,
    state: {
      columnVisibility: {
        selected: !readOnly
      }
    },
    muiTableHeadProps: readOnly ? {} : () => ({
      onMouseEnter: () => {
        clearEditableRow()
      },
    }),
    muiTableProps: readOnly ? {} : () => ({
      onMouseLeave: () => {
        clearEditableRow()
        clearMultiSelect()
      },
      onMouseEnter: _setMultiSelect
    }),
    muiTableBodyRowProps: readOnly ? {} : ({ row }) => {
      return {
        onMouseEnter: (event) => {
          _setEditableRow(row?.original?.id)
        },
        sx: {
          backgroundColor: props.showChanges ?
            parentAuthorization !== undefined && changes[row.original.displayOrder]?.includes('new item') ? HIGHLIGHT_HEX
              : rowIsEditable(row) ? '#e8e8e8' : 'white'
            : 'white',
          minHeight: '32px',
        },
      }
    },
    muiTableBodyCellProps: readOnly ? {} : ({ cell }) => {
      return {
        sx: {
          borderRight: '1px solid #e0e0e0', //add a border between columns
        },
      }
    },
  });

  const LoadingOverlay = () => {
    const { offsetLeft, offsetTop, clientWidth, clientHeight } = paperRef.current || {}
    return <Backdrop
      open={isLoading}
      sx={{
        position: 'absolute',
        left: offsetLeft,
        top: offsetTop,
        width: clientWidth,
        height: clientHeight,
        zIndex: 1,
        borderRadius: '5px',
      }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  }

  const onSubmitAdd = useMemo(() => {
    return (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries(formData.entries());
      const { approvalType } = formJson
      const approvalTypeDescription = approvalType === 'other' ? 'Other' : JSON.parse(approvalType || '')?.description
      const approvalTypeId = approvalType === 'other' ? undefined : JSON.parse(approvalType || '')?.id
      const highestDisplayOrder = approvals?.map(a => parseInt(a.displayOrder || '0'))
        .sort((a,b) => a - b )
        .pop() || 0
      const newApproval = {
        id: uuid(),
        displayOrder: highestDisplayOrder + 10,
        description: formJson.description || approvalTypeDescription,
        date: formJson.approvalDate,
        approvalStatus: formJson.approvalStatus,
        categoryId: category.id,
        approvalTypeId,
        mostSensitiveProduct: formJson.mostSensitiveProduct,
        line: formJson.line
      }

      setAddDialogApprovalDescription('')
      setAddDialogOpen(false)
      createApproval(newApproval)
    }
  }, [approvals, category, createApproval])

  const onSubmitEdit = useMemo(() => {
    return (event) => {
      event.preventDefault();
      const _approvals = [...approvals.filter(a => selectedRows.includes(a.id))].map(a => {
        if (editDialogState.approvalStatusChecked) a.approvalStatus = editDialogState.approvalStatus
        if (editDialogState.approvalDateChecked) a.date = editDialogState.approvalDate
        return a
      })
      updateOneOrMoreApprovals(_approvals)
      setEditDialogOpen(false)
      setEditDialogState({})
      setSelectedRows([])
    }
  }, [approvals, selectedRows, updateOneOrMoreApprovals, editDialogState])

  const approvalStatusOptions = Object.keys(approvalStatuses).map(status => {
    return <MenuItem key={status} value={status}>{toPascalCase(status)}</MenuItem>
  })

  return (
    <RoleContext.Consumer>
      {roleCtx => (<>
        <Dialog
          open={addDialogOpen}
          onClose={() => setAddDialogOpen(false)}
          PaperProps={{
            component: 'form',
            onSubmit: onSubmitAdd
          }}
        >
          <DialogTitle>Add Approval</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText>
                  {category.description}
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="approval-type-label">Approval Type</InputLabel>
                  <Select
                    variant='outlined'
                    size='small'
                    fullWidth
                    name="approvalType"
                    onChange={e => setAddDialogApprovalDescription(e.target.value)}
                  >
                    {approvalTypes.map(a =>
                      <MenuItem key={a.id} value={JSON.stringify(a)}>{a.description}</MenuItem>)}
                    <MenuItem key={'other'} value={'other'}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {addDialogApprovalDescription === 'other' && <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <TextField
                    label='Description'
                    variant='outlined'
                    multiline
                    size='small'
                    fullWidth
                    name="description"
                  />
                </FormControl>
              </Grid>}
              {category.id === 'APPROVED_PRODUCTION_PROCS' && <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <TextField
                    label='Most Sensitive Product for Line'
                    variant='outlined'
                    multiline
                    size='small'
                    fullWidth
                    name="mostSensitiveProduct"
                  />
                </FormControl>
              </Grid>}
              {[
                'PROCESS_CHANGE_VALIDATIONS',
                'REDUCE_MONITORING_FREQUENCIES',
                'EQUIPMENT_TECH_VALIDATION',
                'APPROVED_PRODUCTION_PROCS'
              ].includes(category.id) && <Grid item xs={12}>
                  <FormControl fullWidth size='small'>
                    <TextField
                      label='Line'
                      variant='outlined'
                      multiline
                      size='small'
                      fullWidth
                      name="line"
                    />
                  </FormControl>
                </Grid>}
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Approval Date"
                      fullWidth
                      defaultValue={dayjs()}
                      size="small"
                      name="approvalDate"
                      format="MM/DD/YYYY"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="approval-status-label">Approval Status</InputLabel>
                  <Select
                    fullWidth
                    variant='outlined'
                    size='small'
                    name="approvalStatus"
                  >
                    {approvalStatusOptions}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={() => {
              setAddDialogOpen(false)
            }}>Cancel</Button>
            <Button variant='outlined' type="submit">Add</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          PaperProps={{
            component: 'form',
            onSubmit: onSubmitEdit
          }}
        >
          <DialogTitle>Edit {selectedRows.length} selected approvals</DialogTitle>
          <DialogContent>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <FormControl fullWidth size='small'>
                  <Checkbox
                    checked={editDialogState.approvalDateChecked || false}
                    onChange={e => {
                      const newState = { ...editDialogState }
                      if (!e.target.checked) newState.approvalDate = null
                      newState.approvalDateChecked = e.target.checked
                      setEditDialogState(newState)
                    }}
                    name='approvalDateChecked'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <FormControl fullWidth size='small'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={!editDialogState.approvalDateChecked}
                      label="Approval Date"
                      fullWidth
                      value={dayjs(editDialogState.approvalDate)}
                      onChange={e => setEditDialogState({ ...editDialogState, approvalDate: e })}
                      size="small"
                      name="approvalDate"
                      format="MM/DD/YYYY"
                      slotProps={{
                        textField: { size: 'small' }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size='small'>
                  <Checkbox
                    checked={editDialogState.approvalStatusChecked || false}
                    onChange={e => {
                      const newState = { ...editDialogState }
                      if (!e.target.checked) newState.approvalStatus = null
                      newState.approvalStatusChecked = e.target.checked
                      setEditDialogState(newState)
                    }}
                    name='approvalStatusChecked'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="approval-status-label">Approval Status</InputLabel>
                  <Select
                    disabled={!editDialogState.approvalStatusChecked}
                    fullWidth
                    variant='outlined'
                    size='small'
                    name="approvalStatus"
                    value={editDialogState.approvalStatus || ''}
                    onChange={e => setEditDialogState({ ...editDialogState, approvalStatus: e.target.value })}
                  >
                    {approvalStatusOptions}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={() => {
              setEditDialogOpen(false)
              setEditDialogState({})
            }}>Cancel</Button>
            <Button variant='outlined' type="submit">Save</Button>
          </DialogActions>
        </Dialog>

        <Grid container sx={{ marginBottom: '4px' }}>
          <Grid item xs={10}>
            <Typography sx={{ float: 'left' }} variant="h6" color="text.secondary">{category.label}</Typography>
            {selectionState !== 'none' && <Button onClick={() => setEditDialogOpen(true)} sx={{ float: 'left', marginLeft: '10px' }} variant='outlined' size='small'>EDIT {selectedRows.length} row{selectedRows.length > 1 ? 's' : ''}</Button>}
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right' }}>
            {!readOnly && <Button
              size='small'
              variant='outlined'
              onClick={() => setAddDialogOpen(true)}
            >
              Add
            </Button>}
          </Grid>
        </Grid>
        <Paper ref={paperRef} elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', marginTop: '0', padding: "1em" }}>
          <LoadingOverlay />
          <Grid container columnGap={1} rowGap={0} spacing={1}>
            <Grid item xs={12}>
              <MaterialReactTable table={table} />
            </Grid>
          </Grid>
        </Paper>
      </>
      )}
    </RoleContext.Consumer>
  )
}