import { useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { Box } from '@mui/material'

function TabElement(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabElement.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function TabPanel(props = {}) {
  const { tabs = [] } = props
  const [currentTab, setCurrentTab] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const onTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

        <Tabs value={currentTab} onChange={onTabChange} aria-label="basic tabs example">
          {tabs.map((tab, i) => <Tab label={tab.label} {...a11yProps(i)} />)}
        </Tabs>
      </Box>

      {tabs.map((tab, i) => <TabElement value={currentTab} index={i}>
        {tab.el}
      </TabElement>)}

    </Box>
  )

}