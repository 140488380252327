import { useState } from "react"
import { getRequestConfig } from "../auth/auth"
import { ApiGateway } from "../config/config"
import { useEffect } from "react"
import axios from "axios"
import { Autocomplete, FormControl, Skeleton, TextField } from "@mui/material"
export default function OwnershipSelectionControl({
  defaultOwnershipId = undefined,
  defaultOwnershipUuid = undefined,
  onSelectOwnership = undefined,
  required = false,
  requireOwnershipId = false,
  includeLabel = true
}) {

  const [ownershipId, setOwnershipId] = useState(defaultOwnershipId)
  const [ownershipUuid, setOwnershipUuid] = useState(defaultOwnershipUuid ?? null)
  const [ownershipList, setOwnershipList] = useState([])
  const [ownershipOptions, setOwnershipOptions] = useState([])

  const getOwnershipList = async(requestConfig) => {
    if ( !requestConfig ) {
      requestConfig = await getRequestConfig()
    }
    
    const basePath = `${ApiGateway.ownership}/search`
    const search = { isActive: true }

    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setOwnershipList(results)
      const options = results.reduce((p,r) => { 
        if ( !requireOwnershipId || (r.ownershipId && r.ownershipId !== '')) {
          p.push({
            label: [r.ownershipId, r.ownershipName].join(' - '),
            value: r.uuid
          })
        }

        return p
      }, [])
      setOwnershipOptions(options)
    }
  }

  const getOwnershipOptionLabel = (option) => {
    if (typeof option === 'string' || !option.label ) {
      const ownership = ownershipOptions.find((p) => p.value === (option.value ?? option))
      option = !ownership ? undefined : { value: ownership.value, label: ownership.label }
    }
    return !option ? undefined : option.label
  }

  const handleSelectOwnership = async (event, value) => {
    const option = value ?? event.target.value
    const optValue = option?.value ?? null
    const _ownershipUuid = optValue
    let _ownershipId = undefined
    if (optValue) {
      const item = ownershipList.find((o) => o.uuid === _ownershipUuid)
      _ownershipId = item?.ownershipId ?? undefined
    }
    setOwnershipUuid(_ownershipUuid)
    setOwnershipId(_ownershipId)
    console.log(`${_ownershipUuid}, ${_ownershipId}`)
    
    if (onSelectOwnership) onSelectOwnership(_ownershipUuid, _ownershipId)
  }

  useEffect(() => {
    getOwnershipList()
  }, [])

  useEffect(() => {
    if (ownershipList.length) {
      if (defaultOwnershipId && !defaultOwnershipUuid) {
        const item = ownershipList.find((o) => o.ownershipId === defaultOwnershipId)
        if (item) {
          setOwnershipUuid(item.uuid)
        }
      }
    }
  }, [defaultOwnershipId, defaultOwnershipUuid, ownershipList])

return (
  <>
    { (ownershipOptions === undefined || ownershipOptions.length === 0) &&
      <Skeleton variant="rounded" animation="wave" height={38}/>
    }
    {(ownershipOptions !== undefined && ownershipOptions.length > 0) && 
      <FormControl fullWidth size='small'>
        <Autocomplete size='small' fullWidth id={`ownership_select`} 
          value={ownershipUuid}
          onChange={handleSelectOwnership}
          multiple={false}
          options={ownershipOptions}
          isOptionEqualToValue={(option, value) => (option.value ?? option) === value }
          getOptionLabel={(option) => getOwnershipOptionLabel(option)}
          openOnFocus
          renderInput={(params) => <TextField
              {...params}
              error={required && !ownershipUuid}
              required={required && !ownershipUuid}
              label={ includeLabel ? 'Select an Ownership' : undefined }
            />
          }
          >
          </Autocomplete>
      </FormControl>
    }
  </>
)
}