import { default as React, useEffect, useState, useMemo, useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material"
import { Box, Stack } from '@mui/system'
import { validateFieldChange } from '../form/Form'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { getRequestConfig } from '../auth/auth'
import axios from 'axios'
import { ApiGateway } from '../config/config'
import { LoadingButton } from '@mui/lab'
import { MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, MaterialReactTable } from 'material-react-table'
import { AddCircleOutline, AttachFileOutlined, Edit, Visibility, VisibilityOutlined } from '@mui/icons-material'
import FileListControl from '../form/FileListControl'

export default function ComplaintTrendEventsCard(props={
    trend: undefined,
    onEventSaved: undefined}) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

  const defaultEvent = { id: '', eventType: 'NOTE', eventDate: new Date(), note: '' }
  const [trend, setTrend] = useState(props.trend)
  const [trendEvent, setTrendEvent] = useState(defaultEvent)
  const [hasSaved, setHasSaved] = useState(false)
  const [valid, setValid] = useState(false)
  const [isFocusedEvent, setIsFocusedEvent] = useState(false)
  const [eventEditMode, setEventEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [submissionError, setSubmissionError] = useState('')
  const [eventList, setEventList] = useState([])

  const eventTypes = {
    NOTE: {
      label: 'Note',
      selectable: true
    },
    PLANT_NOTIFIED: {
      label: 'Plant Notified',
      selectable: true
    },
    PLANT_RESPONSE: {
      label: 'Plant Response',
      selectable: true
    },
    CLOSED: {
      label: 'Closed'
    },
    REOPENED: {
      label: 'Re-Opened'
    }
  }

  const validateForm = (_trendEvent) => {
    let _valid = true
    if ( _trendEvent === undefined ) _trendEvent = trendEvent
    if (_trendEvent.eventDate - new Date() > 0) {
      // event cannot be future dated
      _valid = false
    }
    if (_trendEvent.eventType === 'NOTE' && _trendEvent.note.length < 5) {
      // a note less than 5 chars is wasted storage
      _valid = false
    }
    setValid(_valid)
    return _valid
  }

  const newEventDialog = () => {
    setTrendEvent(defaultEvent)
    setEventEditMode(true)
    setIsFocusedEvent(true)
  }

  const closeEvent = (_hasSaved) => {
    setValid(false)
    setEventEditMode(false)
    setIsFocusedEvent(false)
    setTrendEvent(defaultEvent)
    if (hasSaved || _hasSaved) {
      props.onEventSaved()
      setHasSaved(false)
    }
  }

  const saveEvent = async ( withClose = false ) => {
    setIsLoading(true)
    setSubmissionError('')
    try {
      const requestConfig = await getRequestConfig()
      const pathSuffix = trendEvent.id === '' ? 'events' : `events/${trendEvent.id}`
      const path = `${ApiGateway.complaintTrends}/${trend.id}/${pathSuffix}`
      const eventData = {
        ...trendEvent
      }
      if (eventData.id === '') delete eventData.id
      const saveResult = await axios.put(path, eventData, requestConfig)
      
      const _event = saveResult.data
      setHasSaved(true)

      if ( withClose ) {
        closeEvent(true)
      } else {
        setTrendEvent(_event.instance)
      }
    } catch (error) {
      setSubmissionError(error.response?.data)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const eventListColumns = useMemo(() => [    
    {
      id: 'eventDate',
      accessorFn: (row) => new Date(row.eventDate).getTime(),
      header: 'Date',
      size: 100,
      grow: false,
      Cell: ({cell}) => {
        return new Date(cell.getValue()).toLocaleDateString('en-US', {timeZone: 'UTC'})
      }
    },
    {
      id: 'eventType',
      accessorFn: (row) => {
        return eventTypes[row.eventType]?.label ?? row.eventType
      },
      header: 'Event',
      size: 120,
      grow: false
    },
    {
      id: 'createdBy',
      accessorFn: (row) => {
        if (row.CreatedByName) return (<span style={{textTransform: 'capitalize'}}>{[row.CreatedByName.firstName, row.CreatedByName.lastName].join(' ').toLowerCase()}</span>)
        return row.createdBy
      },
      header: 'User',
      size: 175,
      grow: false
    },
    { 
      id: 'note',
      accessorKey: 'note',
      header: 'Note',
      grow: true,
      Cell: ({cell}) => (<Typography style={{whiteSpace: 'pre-wrap'}}>{cell.getValue()}</Typography>)
    },
    {
      id: 'files',
      columnDefType: 'display',
      header: '',
      size: 40,
      grow: false,
      Cell: ({ row }) => {
        return row.original.fileCount > 0 ? <Tooltip arrow placement='left' title={`${row.original.fileCount} file(s) attached`}>
          <IconButton sx={{padding: 0}}><AttachFileOutlined /></IconButton>
        </Tooltip>
        : undefined
      }
    },
    {
      id: 'actions',
      columnDefType: 'display',
      header: '',
      size: 50,
      grow: false,
      Cell: ({ row }) => {
        const editable = !['CLOSED','REOPENED'].includes(row.original.eventType)
        return editable ? <Box sx={{ display: 'flex', gap: '0rem' }}>
          <Tooltip arrow placement="left" title='View/Edit'>
              <Link onClick={() => {
                setTrendEvent(row.original)
                setIsFocusedEvent(true)
              }}>
                
              <IconButton sx={{padding: 0}}><VisibilityOutlined /></IconButton>
            </Link>
          </Tooltip>
        </Box> : undefined
      }
    },
  ])

  const renderToolbarInternalActions = ({ table }) => (
    <>
      {!isFocusedEvent && !trend?.isClosed &&
        <IconButton title='Add Event' onClick={() => newEventDialog()}>
          <AddCircleOutline />
        </IconButton>
      }
      <MRT_ToggleGlobalFilterButton table={table}/>
      <MRT_ToggleFullScreenButton table={table} />
    </>
  )

  useEffect(() => {
    setTrend(props.trend)
    setEventList(props.trend.ComplaintTrendEvents)
  }, [props.trend])

  return (
    <Stack paddingBottom={2}>
      { isFocusedEvent &&
      <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle variant='h6' >Trend {trend.trendNumber} {trend.plantCode} -  Timeline Event</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
        {submissionError && 
            <Typography className='tis-alert-error' sx={{ color: '#FFF', padding: '2'}} >Error occurred attempting to save: {submissionError}</Typography>
        }
        <Stack direction={'row'} spacing={2}>
        <Typography fontWeight='bold' color="text.secondary" width={50} sx={{alignContent: 'center'}}>Type:</Typography>
        { !eventEditMode && 
          <Typography sx={{alignContent: 'center'}}>{eventTypes[trendEvent.eventType]?.label ?? trendEvent.eventType}</Typography>
        }
        { eventEditMode &&
          <FormControl size='small'>
            <Select id='eventType' size='small' 
              value={trendEvent.eventType} 
              onChange={(event) => {
                validateFieldChange('eventType',event.target.value, trendEvent, setTrendEvent, validateForm)
              }}
              > {
                Object.entries(eventTypes).reduce((p, [k,l]) => {
                  if (l.selectable) p.push(
                    <MenuItem value={k}>{l.label}</MenuItem>
                  )
                  return p
                }, [])
              }
            </Select>
            </FormControl>
        }
            { ['PLANT_NOTIFIED','PLANT_RESPONSE'].includes(trendEvent.eventType) &&
            <>
            <Typography fontWeight='bold' color="text.secondary" sx={{alignContent: 'center'}}>Event Date:</Typography>
            { eventEditMode &&
            <FormControl size='small'>
            <DatePicker
              value={ dayjs(trendEvent.eventDate)}
              // timezone='utc'
              disableFuture
              slotProps={{
                textField: {
                  size: 'small'
                }
              }}
              onChange={(value) => {
                validateFieldChange('eventDate',value, trendEvent, setTrendEvent, validateForm)
              }}
            />
            </FormControl>
            }
            { !trendEvent.eventDate && eventEditMode &&
              <FormHelperText error={true}>This field is required, current or past dates only.</FormHelperText>
            }
            { !eventEditMode && 
              <Typography sx={{alignContent: 'center'}}>{new Date(trendEvent.eventDate).toLocaleDateString('en-US')}</Typography>
            }
            </>
          }
        </Stack>
        <Grid container columnGap={1}>
          <Grid item md={6.5} sm={12}>
          <Stack spacing={1}>
          <Typography fontWeight='bold' color="text.secondary" sx={{alignContent: 'center'}}>Note:</Typography>
          { eventEditMode &&
          <TextField fullWidth
            inputProps={{ maxLength: 2000 }}
            variant='outlined'
            id={`${props.id}_note`}
            value={trendEvent.note}
            helperText={`${trendEvent.note.length}/2000`}
            style={{padding: '3px !important'}}
            sx={{ minWidth: '50%'}}
            multiline rows={10}
            onChange={(event) => validateFieldChange('note', event.target.value, trendEvent, setTrendEvent, validateForm)}
            onBlur={(event) => validateFieldChange('note', event.target.value, trendEvent, setTrendEvent, validateForm)}
          />
          }
          { eventEditMode && trendEvent.eventType === 'NOTE' && trendEvent.note.length < 5 && 
            <FormHelperText error={true}>This field has a required minimum input of 5 characters.</FormHelperText>
          }
          { !eventEditMode && 
            <Typography sx={{whiteSpace: 'pre-wrap', minHeight: '200px', border: '1px dotted silver', padding: '4px'}}>{trendEvent.note}</Typography>
          }
          </Stack>
          </Grid>
          <Grid item md={5} sm={12}>
          <Stack spacing={1}>
          <Typography fontWeight='bold' color="text.secondary" sx={{alignContent: 'center'}}>Related Files:</Typography>
          {  trendEvent.id === '' && 
            <Typography>Please save this event to enable file upload(s).</Typography>
          }
          { trendEvent.id !== '' && 
          <FileListControl
            listFilesUrl={`${ApiGateway.complaintTrends}/${trend.id}/events/${trendEvent.id}/files`}
            showLastModified={true}
            showActions={eventEditMode}
            enableUpload={eventEditMode && trendEvent.id !== ''}
            enableDownload={true}
            getFileUrlServiceAddr={`${ApiGateway.complaintTrends}/${trend.id}/events/${trendEvent.id}/files/`}
            getPutFileUrlServiceAddr={`${ApiGateway.complaintTrends}/${trend.id}/events/${trendEvent.id}/files/new-file-url`}
            removeFileUrl={`${ApiGateway.complaintTrends}/${trend.id}/events/${trendEvent.id}/files`}
          />}
          </Stack>
          </Grid>
        </Grid>
    </Stack>
  </DialogContent>
  <DialogActions>
    { !eventEditMode && <LoadingButton variant='outlined' onClick={() => setEventEditMode(true)} >Edit</LoadingButton> }
    { eventEditMode && <><LoadingButton variant='outlined' onClick={() => saveEvent( false ) } loading={isLoading} disabled={ !valid }>Save</LoadingButton><LoadingButton variant='outlined' onClick={() => saveEvent(true) } loading={isLoading} disabled={ !valid }>Save and Close</LoadingButton></>}
    <LoadingButton variant='outlined' onClick={closeEvent} loading={isLoading}>Close</LoadingButton></DialogActions>
  </Dialog>
    }
    { eventList &&
      <MaterialReactTable
      data={eventList}
      columns={eventListColumns}
      layoutMode='grid'
      enableDensityToggle={false}
      enableHiding={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableColumnDragging={false}
      enableSorting={true}
      enableColumnActions={false}
      muiTableBodyCellProps={{  sx: { alignItems: 'self-start' }}}
      renderToolbarInternalActions={renderToolbarInternalActions}
      initialState={{
        sorting: [
          {
            id: 'eventDate',
            desc: true
          }
        ]
      }}
      />
    }
    </Stack>
  )
}