import { Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { getRequestConfig } from '../auth/auth.js';
import { ApiGateway } from '../config/config.js';
import { removeAllLocalStorageItemsStartingWith } from '../utils/storage.js'

export default function ListIMLogs(props = {}) {

  const [imLogRows, setIMLogRows] = useState([])
  const [columnVisibility, _setColumnVisibility] = useState({})
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([{ id: 'createdOn', desc: true }]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const setColumnVisibility = (_newModel) => {
    const newModel = typeof _newModel === 'function' ? _newModel() : _newModel
    if (Object.keys(newModel).length === 0) {
      removeAllLocalStorageItemsStartingWith('IMLogColumnVisibility-')
    }
    for (const field in newModel) {
      const value = newModel[field]
      const prefix = `IMLogColumnVisibility-${field}`
      localStorage.setItem(`${prefix}`, value)
    }
    _setColumnVisibility(newModel)
  }


  const columns = useMemo(() => {

    const phoneNumberFormatter = (phoneNumber) => {
      if (!phoneNumber) return ''
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    }

    const cellPhoneNumberFormatter = (cell) => {
      return phoneNumberFormatter(cell?.value)
    }

    const dateFormatter = ({ row }, field) => {
      const date = row.original?.[field]
      if (!date) return ''
      const dateObj = new Date(date)
      return dateObj.toLocaleDateString()
    }

    const nameFormatter = ({ row }, entity) => {
      if (!row.original?.[entity]) return ''
      return `${row.original?.[entity]?.firstName} ${row.original?.[entity]?.lastName} (${row.original?.[entity]?.koId})`
    }

    const titleFormatter = ({ row }) => {
      return <a href={`edit/${row.original.id}`}>{row.original.title}</a>
    }

    const truncateFormatter = ({ row }, field) => {
      return row.original[field].length > 30 ? row.original[field].slice(0, 30) + "..." : row.original[field];
    }

    const ownershipFormatter = ({ row }) => {
      return <span>
        {row.original.AffectedOwnerships?.map(o => {
          let val
          if (o?.Manufacturer) {
            val = o?.Manufacturer?.manufacturerName
          } else if (o?.Ownership) {
            val = o?.Ownership?.ownershipName
          } else if (o.otherOwnership) {
            val = o.otherOwnership
          } else if (o.distributionCenterId) {
            val = o.otherOwnership
          }
          return <span>{val}<br /></span>
        })}
      </span>
    }

    const phoneFormatter = ({ row }, field) => {
      const phoneNumber = row.original?.[field]
      if (!phoneNumber) return ''
      const cleaned = ('' + phoneNumber).replace(/\D/g, '');
      const formattedNumber = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
      return formattedNumber;
    }

    const rcaCapFormatter = ({ row }, field) => {
      return {
        '1': "Waiting",
        '2': "Received",
        '3': "Not Needed",
      }[row?.original?.[field]]
    }

    const coqFormatter = ({ row }, field) => {
      return {
        '1': "Pending",
        '2': "N/A",
        '3': "Enter Cost",
      }[row?.original?.[field]]
    }

    const lessonsLearnedFormatter = ({ row }, field) => {
      return {
        '1': "Waiting",
        '2': "Received",
        '3': "N/A",
      }[row?.original?.[field]]
    }

    const capValidationFormatter = ({ row }, field) => {
      return {
        '1': "Pending",
        '2': "Completed",
        '3': "N/A",
      }[row?.original?.[field]]
    }
    const rcaCapIsOverdue = (givenDate) => {
      const date = new Date(givenDate).getTime();
      const currentDate = new Date().getTime();
      const diffTime = currentDate - date;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return diffDays >= 7;
    };

    const rcaCapOverdueAccessor = (row, field) => {
      if (row.title === 'test 1') {
      }
      return row[field] === '2' ? false : rcaCapIsOverdue(row.eventDate);
    }

    const rcaCapOverdueFormatter = ({ row }, field) => {
      const isOverdue = rcaCapOverdueAccessor(row.original, field)
      return isOverdue ? 'YES' : ''
    }

    return [
      // { accessorKey: 'id', header: 'ID', size: 300 },
      { accessorKey: 'title', header: 'Title', grow: 1, Cell: titleFormatter },
      { accessorKey: 'ownerships', header: 'Ownerships', grow: 1, Cell: ownershipFormatter, enableSorting: false },
      { accessorKey: 'description', header: 'Description', grow: 1, Cell: (row) => truncateFormatter(row, 'description') },
      { accessorKey: 'IMEventType.label', header: 'Event Type', grow: 1, enableSorting: false },
      { accessorKey: 'ManagedBy.firstName', header: 'Managed By', Cell: (row) => nameFormatter(row, 'ManagedBy') },
      { accessorKey: 'eventDate', header: 'Event Date', Cell: (row) => dateFormatter(row, 'eventDate'), filterVariant: 'date-range', grow: 1 },
      { accessorKey: 'IMIssueType.label', header: 'Issue Type', grow: 1, enableSorting: false },
      { accessorKey: 'corpIMCRNumber', header: 'Corp IMCR Number', grow: 1 },
      { accessorKey: 'eventStatus', header: 'Event Status', grow: 1 },
      { accessorKey: 'GovernanceManagerAssigned.firstName', header: 'Governance Manager', grow: 1, Cell: (row) => nameFormatter(row, 'GovernanceManagerAssigned'), enableSorting: false },
      { accessorKey: 'rcaChoiceId', header: 'RCA', grow: 1, Cell: (row) => rcaCapFormatter(row, 'rcaChoiceId') },
      { accessorKey: 'capChoiceId', header: 'CAP', grow: 1, Cell: (row) => rcaCapFormatter(row, 'capChoiceId') },
      { accessorKey: 'costOfQualityStatus', header: 'Cost Of Quality Status', grow: 1, Cell: (row) => coqFormatter(row, 'costOfQualityStatus') },
      { accessorKey: 'costOfQuality', header: 'Cost of Quality', grow: 1, Cell: ({ row }) => row.original.costOfQuality || '' },
      { accessorKey: 'lessonsLearnedChoiceId', header: 'Lessons Learned', grow: 1, Cell: (row) => lessonsLearnedFormatter(row, 'lessonsLearnedChoiceId') },
      { accessorKey: 'capValidationChoiceId', header: 'CAP Validation', grow: 1, Cell: (row) => capValidationFormatter(row, 'capValidationChoiceId') },
      { accessorKey: 'imcrIatClosedDate', header: 'IMCR/IAT Closed Date', Cell: (row) => dateFormatter(row, 'imcrIatClosedDate'), grow: 1, filterVariant: 'date-range' },
      { accessorKey: 'rcaDueDate', header: 'RCA Due Date', Cell: (row) => dateFormatter(row, 'rcaDueDate'), grow: 1, filterVariant: 'date-range' },
      { accessorKey: 'capRecordsReceivedDate', header: 'CAP Records Received Date', Cell: (row) => dateFormatter(row, 'capRecordsReceivedDate'), grow: 1, filterVariant: 'date-range' },
      { accessorKey: 'capOverdue', accessorFn: (row) => rcaCapOverdueAccessor(row, 'capChoiceId'), header: 'CAP Overdue', grow: 1, Cell: (row) => rcaCapOverdueFormatter(row, 'capChoiceId') },
      { accessorKey: 'rcaOverdue', accessorFn: (row) => rcaCapOverdueAccessor(row, 'rcaChoiceId'), header: 'RCA Overdue', grow: 1, Cell: (row) => rcaCapOverdueFormatter(row, 'rcaChoiceId') },
      { accessorKey: 'notifiedBy', header: 'Notified By', grow: 1 },
      { accessorKey: 'dateNotified', header: 'Date Notified', Cell: (row) => dateFormatter(row, 'dateNotified'), grow: 1, filterVariant: 'date-range' },
      { accessorKey: 'submitterName', header: 'Submitter Name', grow: 1 },
      { accessorKey: 'submitterEmail', header: 'Submitter Email', grow: 1 },
      { accessorKey: 'submitterPhoneNumber', Cell: (row) => phoneFormatter(row, 'submitterPhoneNumber'), header: 'Submitter Phone Number', valueFormatter: cellPhoneNumberFormatter, grow: 1 },
      { accessorKey: 'ownershipIMProvidedTheFollowingInstructions', header: 'Ownership IM Instructions', grow: 1, Cell: (row) => truncateFormatter(row, 'ownershipIMProvidedTheFollowingInstructions') },
      { accessorKey: 'otherComments', header: 'Other Comments', grow: 1, Cell: (row) => truncateFormatter(row, 'otherComments') },
      { accessorKey: 'createdOn', header: 'Created On', Cell: (row) => dateFormatter(row, 'createdOn'), grow: 1, filterVariant: 'date-range' },
    ]
  }, [])

  const { stopLoading, startLoading } = props
  useEffect(() => {
    (async () => {
      startLoading()
      const search = {
        filters,
        sorting,
        pagination
      }
      const requestConfig = await getRequestConfig()
      const path = `${ApiGateway.imLogs}/search`
      const { data } = await axios.post(path, search, requestConfig)
      setIMLogRows(data.results)
      setRowCount(data.count)
      stopLoading()
    })()
  }, [
    sorting,
    filters,
    pagination,
  ])

  useEffect(() => {
    const getInitialColumnVisibility = () => {
      const initialColumnVisibility = {}
      columns.forEach((column) => {
        const { accessorKey } = column
        const prefix = `IMLogColumnVisibility-${accessorKey}`
        const value = localStorage.getItem(prefix)
        if (value !== undefined && value !== null) {
          initialColumnVisibility[accessorKey] = value === 'true'
        }
      })
      if (Object.keys(initialColumnVisibility).length === 0) {
        return {
          id: false,
          title: true,
          ownerships: false,
          description: false,
          'IMEventType.label': true,
          'ManagedBy.firstName': false,
          eventDate: true,
          'IMIssueType.label': true,
          corpIMCRNumber: false,
          eventStatus: false,
          'GovernanceManagerAssigned.firstName': false,
          rcaChoiceId: false,
          capChoiceId: false,
          costOfQualityStatus: false,
          costOfQuality: false,
          lessonsLearnedChoiceId: false,
          capValidationChoiceId: false,
          imcrIatClosedDate: false,
          rcaDueDate: false,
          capOverdue: false,
          rcaOverdue: false,
          capRecordsReceivedDate: false,
          notifiedBy: false,
          dateNotified: false,
          submitterName: false,
          submitterEmail: false,
          submitterPhoneNumber: false,
          ownershipIMProvidedTheFollowingInstructions: false,
          otherComments: false,
          createdOn: true
        }
      }
      return initialColumnVisibility
    }
    setColumnVisibility(getInitialColumnVisibility())
  })

  const table = useMaterialReactTable({
    layoutMode: 'semantic',
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    columns,
    rowCount,
    muiBottomToolbarProps: {
      sx: {
        '& .MuiBox-root': {
          justifyContent: 'start',
          right: '',
          left: 0,
        }
      }
    },
    enableColumnHiding: true,
    enableColumnResizing: true,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    data: imLogRows,
    enableColumnActions: true,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      sorting,
      filters,
      pagination,
      columnVisibility,
    },
    state: {
      sorting,
      filters,
      pagination,
      columnVisibility,
    },
    enablePagination: true,
    enableSorting: true,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: {
        overflowX: 'hidden !important',
        minHeight: 300
      }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: true,
  });

  return (
    <>
      <Paper sx={{ overflowX: 'auto', backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto' }}>
        <Grid>
          <MaterialReactTable table={table} />
        </Grid>
      </Paper>
    </>
  )
}