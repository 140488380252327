import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography'

const ProfileSectionNavigator = (props) => {
  const { profileSections, setCurrentSection } = props
  const [elementName, setElementName] = useState()
  const [section, setSection] = useState()
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the starting index based on the current pathname
  const getCurrentIndex = useCallback(() => {
    const currentPath = location.pathname;
    const matchingSection = profileSections.findIndex(section => section.href === currentPath);
    return matchingSection !== -1 ? matchingSection : 0; // Default to 0 if no match
  }, [location.pathname, profileSections])

  const [currentSectionIndex, setCurrentSectionIndex] = useState(getCurrentIndex());

  const handleNavigation = useCallback((direction) => {
    let newIndex = currentSectionIndex;
    if (direction === 'forward' && currentSectionIndex < profileSections.length - 1) {
      newIndex = currentSectionIndex + 1;
    } else if (direction === 'backward' && currentSectionIndex > 0) {
      newIndex = currentSectionIndex - 1;
    }
    // setCurrentSectionIndex(newIndex);
    navigate(profileSections?.[newIndex].href);
  }, [currentSectionIndex, navigate, profileSections])

  // Update the index whenever the location changes
  useEffect(() => {
    const _curIndex = getCurrentIndex()
    const Element = profileSections?.[_curIndex]?.el
      setCurrentSection(Element)
      setCurrentSectionIndex(_curIndex);
    // }
  }, [section, location.pathname, currentSectionIndex, getCurrentIndex, profileSections, setCurrentSection, elementName]);

  const showBack = currentSectionIndex > 0
  const showForward = currentSectionIndex < profileSections.length - 1

  return (
    <Box sx={styles.container}>
      {showBack && (
        <Button
          color="primary"
          onClick={() => handleNavigation('backward')}
          startIcon={<ArrowBackIcon />}
          sx={{ ...styles.button, justifyContent: 'left' }}
        >
          {profileSections?.[currentSectionIndex - 1]?.name}
        </Button>
      )}
      {!showBack && <div style={{ ...styles.button, justifyContent: 'right' }}>&nbsp;</div>}
      <Typography sx={{ flex: 1, textAlign: 'center' }} className='tis-form-title'>{profileSections?.[currentSectionIndex]?.name}</Typography>
      {showForward && (
        <Button
          color="primary"
          onClick={() => handleNavigation('forward')}
          endIcon={<ArrowForwardIcon />}
          sx={{ ...styles.button, justifyContent: 'right' }}
        >
          {profileSections?.[currentSectionIndex + 1]?.name} {/* Show next section */}
        </Button>
      )}
      {!showForward && <div style={{ ...styles.button, justifyContent: 'right' }}>&nbsp;</div>}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #ddd',
  },
  button: {
    textTransform: 'none', // Disable uppercase transformation of button text
    width: '240px',
  },
};

export default ProfileSectionNavigator;
