import { default as React, useContext, useEffect, useState, useCallback } from 'react'
import { Card } from '@mui/material'
import ContactTable from '../../facilityManagement/ContactTable';
import { ROLES } from '../../auth/role.js'
import RoleContext from '../../auth/RoleContext.js'
import { ParentType, getEntityContacts, getContactTypes } from '../../facilityManagement/data'

export default function ManufacturerContactsSection(props = {}) {

  const roleDetails = useContext(RoleContext)
  const { manufacturer = {} } = props

  const [loading, setLoading] = useState(true);

  const [contacts, setContacts] = React.useState([]);
  const [contactTypes, setContactTypes] = React.useState([]);

  const loadSection = async (mfrUuid) => {
    setLoading(true)
    const [_contacts, _contactTypes] = await Promise.all([
      getEntityContacts({
        facilityOrOwnershipUuid: mfrUuid,
        parentType: ParentType.manufacturer,
        category: 'assessment'
      }),
      getContactTypes('assessment')
    ])
    setContacts(_contacts)
    setContactTypes(_contactTypes)
    setLoading(false)
  }

  useEffect(() => {
    (async () => {
      if (manufacturer?.uuid) loadSection(manufacturer?.uuid)
    })()
  }, [manufacturer])

  const editingEnabled = roleDetails.hasRole([
    ROLES.BOTTLER_CORP,
    ROLES.QSE_MGR,
    ROLES.MFI_ADMIN,
    ROLES.ADMIN
  ])

  const afterSave = useCallback(() => {
    (async () => {
      if (manufacturer?.uuid) loadSection(manufacturer?.uuid)
    })()
  }, [manufacturer])


  return (

    <Card sx={{ padding: '12px' }}>

      <ContactTable
        loading={loading}
        parentType={ParentType.manufacturer}
        facilityOrOwnership={manufacturer}
        editMode={editingEnabled}
        contacts={contacts}
        setContacts={setContacts}
        contactTypes={contactTypes}
        afterSave={afterSave}
      />

    </Card>
  )
}