import { default as React, useEffect, useRef, useState, useMemo, useCallback} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Autocomplete, FormGroup, Switch, Box, Button, Grid, Paper, IconButton, FormControl, FormHelperText, FormLabel, InputAdornment, Radio, Tooltip, FormControlLabel, RadioGroup, FormElement, InputLabel, Select, MenuItem, Tabs, Tab, TextField, Typography } from '@mui/material'
import { Block, Edit, Info, MonitorHeart, ExpandLess, ExpandMore } from '@mui/icons-material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar.js'
import Page from '../layout/Page.js'
import { handleFieldChange, handleSelectChange, handleTextChange } from '../form/Form'
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js'
import { getRequestConfig, getToken } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js'
import axios from 'axios'
import AuthorizationDetailCard from '../authorizations/AuthorizationDetailCard'
import AuthorizationApprovalSection from '../authorizations/AuthorizationApprovalSection'
import { default as bus } from '../utils/EventBus.js'
import Event from '../utils/EventNames.js';
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { borderBottomColor } from '@mui/system'
import { GlobalStyles } from '@mui/system';
import Validate from '../validator/Validate'

const globalStyles = (
  <GlobalStyles
    styles={{
      fieldset: {
        '&.MuiFormControl-root': {
          width: '100% !important',
          borderTop: '1px dashed #CCC'
        }
      },
      ".FormControl-Label": {
        paddingTop: '.75em !important'
      },
      ".MuiInputBase-root.MuiOutlinedInput-root": {
        padding: '10px !important'
      }
    }}
  />
)

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#FFF', // Change the text color of the selected tab
          backgroundColor: '#d73925', // Change the background color of the selected tab
          border: '1px solid #FFF',
          borderRadius: '4px 4px 0 0',
          borderBottomWidth: 4,
          borderBottomColor: '#FFF',
          '&.Mui-selected': {
            color: '#FFF', // Change the text color of the selected tab
            backgroundColor: '#d73925', // Change the background color of the selected tab
            border: '1px solid #FFF',
            borderRadius: '4px 4px 0 0',
            borderBottomColor: '#FF0000',
            padding: '8px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#FFF',
          height: 0
        }
      }
    }
  },
});

function YesNoRadio(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);

  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = (result.isValid) ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  }

  const clearValidation = () => {
    setValidationState(null)
    setValidationMessage(null)
  }

  const reset = () => {
    clearValidation()
  }

  return (
    <FormControl component="fieldset">
      <Grid container xs={12}>
        <Grid item xs={12} sm={5}>
          <FormLabel component="legend" className="FormControl-Label">{props.labelText}</FormLabel>      
        </Grid>
        <Grid item xs={12} sm={5}>
          <RadioGroup 
            error={validationState === 'error'}
            helperText={validationState === 'error' ? validationMessage : ''}
            row value={props.value} onChange={props.onChange} onBlur={props.onBlur ?? validate}>
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  )
}

function TextInput(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);
  
  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = (result.isValid) ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  }

  const clearValidation = () => {
    setValidationState(null)
    setValidationMessage(null)
  }

  const reset = () => {
    clearValidation()
  }

  return (
    <FormControl component="fieldset" error={validationState === 'error'} variant="outlined" fullWidth>
      <Grid container spacing={2}>
         <Grid item xs={12} sm={5} style={props.noLabel ? {display:"none"} : {}}>
          <InputLabel htmlFor={props.id}>
            {props.labelText}
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={3}>
          {props.readOnly && props.value}
          {!props.readOnly && 
              <TextField 
                error={validationState === 'error'} 
                helperText={validationState === 'error' ? validationMessage : ''}
                disabled={props.disabled} 
                placeholder={props.placeholder} 
                value={props.value} 
                onChange={props.onChange} 
                onBlur={props.onBlur ?? validate}/>
          }
        </Grid> 
      </Grid>
    </FormControl>
  )
}



function NoInfoTextInput(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);
  const [prevValue, setPrevValue] = useState(' ');
  const [noInfoMode, setNoInfoMode] = useState(props.noInfoMode);
  const formControl = useRef(null);

  useEffect(() => {
    if (props.value) {
      setNoInfoMode(props.value == -1 || props.value === undefined || props.value === null);
    }
  }, [props.value]);

  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = (result.isValid) ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);  
    }
    return result.isValid
  };

  const clearValidation = () => {
    setValidationState(null);
    setValidationMessage(null);
  };

  const onIconClick = () => {
    setPrevValue(noInfoMode ? prevValue || ' ' : formControl.current.value);
    if(!noInfoMode) {
      clearValidation();
    }
    setNoInfoMode(!noInfoMode);
    
    const event = {
      target: {
        type: 'text',
        id: props.id,
        value: noInfoMode ? '-1' : (prevValue == '-1' ? ' ' : prevValue)
      }
    };
    props.onChange(event);
  };

  const reset = () => {
    clearValidation();
  };

    const formControlElement = (
    <TextField
      type="text"
      value={props.value}
      defaultValue="0"
      onChange={props.onChange}
      onBlur={props.onBlur ?? validate}
      inputRef={formControl}
      InputProps={{
        readOnly: props.readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onIconClick} disabled={props.readOnly}>
              <Block />
            </IconButton>
          </InputAdornment>
        )
      }}
      style={{ display: noInfoMode ? 'none' : '' }}
      error={validationState === 'error'}
      helperText={validationState === 'error' ? validationMessage : ''}
    />
  );

  const noInfoFormControl = (
    <TextField
      type="text"
      value="No Information"
      inputProps={{ readOnly: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onIconClick} disabled={props.readOnly}>
              <Edit />
            </IconButton>
          </InputAdornment>
        )
      }}
      style={{ display: noInfoMode ? '' : 'none' }}
      disabled
    />
  );

  return (
    <FormControl component="fieldset" error={validationState === 'error'} variant="outlined" fullWidth>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <InputLabel htmlFor={props.id}>
            {props.labelText}
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={3}>
          {formControlElement}
          {noInfoFormControl}
        </Grid>
      </Grid>
    </FormControl>
  );
};


 function CertificationStatusDropdown(props) {
  const [validationState, setValidationState] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);

  const onChange = (event) => {
    if (!props.readOnly) {
      props.onChange(event);
    }
  };

  const validate = (event) => {
    let result = {isValid: true}
    if(props.onValidate) {
      result = Validate.validateAll(props.onValidate, props.id, event?.target?.value)
      const validationState = result.isValid ? null : 'error';
      const validationMessage = result.message;
      setValidationState(validationState);
      setValidationMessage(validationMessage);
    }
    return result.isValid;
  };

  const clearValidation = () => {
    setValidationState(null);
    setValidationMessage(null);
  };

  const reset = () => {
    clearValidation();
  };
  

  return (
    <FormControl component="fieldset" sx={{ borderColor: validationState ? 'error.main' : 'grey.400', borderWidth: 1, borderRadius: 1, padding: 2, marginBottom: 2 }}>
      <Grid container alignItems="center" xs={12}>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <Typography component="label" className="FormControl-Label" id={props.id}>
            {props.labelText}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FormControl fullWidth error={validationState === 'error'} required={props.required} disabled={props.readOnly}>
            
            <Select
              labelId={`${props.id}-label`}
              id={props.id}
              value={props.value}
              onChange={onChange}
              onBlur={props.onBlur ?? validate}
              error={validationState === 'error'}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="0">No Information</MenuItem>
              <MenuItem value="1">Pass</MenuItem>
              <MenuItem value="2">Fail</MenuItem>
              <MenuItem value="3">None</MenuItem>
              {props.includeNa && <MenuItem value="4">N/A</MenuItem>}
            </Select>
            {validationState === 'error' &&
              <FormHelperText>{validationMessage}</FormHelperText>
            }
          </FormControl>
        </Grid>
      </Grid>
    </FormControl>
  );
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div 
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-panel-${index}`}
      style={{ 
        width: 'calc(100% - 2em)',
        marginBottom: '2em'
      }}
      {...other}
    >
      {value === index && (
        <Box width='100%' sx={{ 
          boxShadow: theme.shadows[1], 
          margin: '0 1em', 
          padding: '2em', 
          backgroundColor: '#FFF',
          verticalAlign: 'middle'
        }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

export default function FitnessIndexFormPage(props = {}) {
  const location = useLocation()
  const params = useParams()
  const [indexChangesOverTimeIcon, setIndexChangesOverTimeIcon] = useState((false) ? <ExpandLess/> : <ExpandMore/>)
  const [additionalManufacturerDetailsIcon, setAdditionalManufacturerDetailsIcon] = useState((false) ? <ExpandLess/> : <ExpandMore/>)
  
  const { fitnessIndexId } = params
  const { editable } = props
  const [qseManagerOptions, setQseManagerOptions] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [manufacturer, setManufacturer] = useState({})
  const [fitnessIndex, setFitnessIndex] = useState({})
  let isIndexChangesOverTimeExpandedExpanded = true
  let isAdditionalManufacturerDetailsExpanded = false
  const [indexChangesOverTimeExpanded, setIndexChangesOverTimeExpanded] = useState(isIndexChangesOverTimeExpandedExpanded)
  const [additionalManufacturerDetailsExpanded, setAdditionalManufacturerDetailsExpanded] = useState(isAdditionalManufacturerDetailsExpanded)
  
  isIndexChangesOverTimeExpandedExpanded = useMemo(() => indexChangesOverTimeExpanded === true,[indexChangesOverTimeExpanded])
  isAdditionalManufacturerDetailsExpanded = useMemo(() => additionalManufacturerDetailsExpanded === true,[additionalManufacturerDetailsExpanded])

  const ALERT_DURATION = 1000
  
  const toggleIndexChangesOverTime = () => {
    setIndexChangesOverTimeIcon(isIndexChangesOverTimeExpandedExpanded ? <ExpandMore/> : <ExpandLess/>)
    setIndexChangesOverTimeExpanded(!indexChangesOverTimeExpanded)
  }

  const toggleAdditionalManufacturerDetails = () => {
    setAdditionalManufacturerDetailsIcon(isAdditionalManufacturerDetailsExpanded ? <ExpandMore/> : <ExpandLess/>)
    setAdditionalManufacturerDetailsExpanded(!additionalManufacturerDetailsExpanded)
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const navigate = useNavigate()

  useEffect(() => {

  }, [])

  const loadFitnessIndex = useCallback(async () => {
    // if (fitnessIndexId) {
    //   const requestConfig = await getRequestConfig()
    //   const basePath = `${ApiGateway.fitnessIndexes}/${fitnessIndexId}`
    //   const { data } = await axios.get(basePath, requestConfig)
    //   const { result } = data
    //   if (result?.id) {
    //     setFitnessIndex(result)

    //     if (result?.manufacturerId) {
    //       const requestConfig = await getRequestConfig()
    //       const basePath = `${ApiGateway.manufacturer}/${result.manufacturerId}`
    //       const { data } = await axios.get(basePath, requestConfig)
    //       const { results } = data
    //       if (results?.length) {
    //         setManufacturer(results)
    //       }
    //     }
    //   }
    // } 
  }, [fitnessIndexId])

  useEffect(() => {
    (async () => {
      await loadOptionLists()
    })()
  }, [])

  const setDirty = () => {
    setIsDirty(true)
  }

  const setClean = () => {
    setIsDirty(false)
    // setIsValid(false)
  }


  const onTabSelect = (event, selectedTab) => {
    console.log('onTabSelect: ', selectedTab)
    setActiveTab(selectedTab)
  }

  const getQseManagers = async(requestConfig) => {
    const roleId = ROLES.QSE_MGR.id
    const response = await axios.post(`${ApiGateway.user}/search`, { roleId }, requestConfig)
    let results
    if(response.data && response.data.length) {
      results = response.data.map((q) => {return {label: [q.firstName, q.lastName].join(' '), value: q.koId}})
    }
    return results
  }

  const getRegions = async(requestConfig) => {

    // const response = await axios.get(`${ApiGateway.region}?format=label-value&sorting=label`, requestConfig)
    let results = []
    // if(response.data && response.data.length) {
    //   results = response.data.map((q) => {return {label: [q.firstName, q.lastName].join(' '), value: q.koId}})
    // }
    return results
  }

  const loadOptionLists = async(requestConfig) => {
    if ( !requestConfig ) requestConfig = await getRequestConfig()
    
    const allOptions = await Promise.all([getQseManagers(requestConfig), getRegions(requestConfig)])
    console.log('allOptions: ', allOptions)
    setQseManagerOptions(allOptions[0])
    setRegionOptions(allOptions[1])
  }

  const breadcrumb = <span><MonitorHeart />&nbsp;Edit Manufacturer Fitness Index</span>

  const onSave = () => {

  }

  const actionButtons = () => {
    return []
  }

  const confirmCancel = () => {

  }

  const onClose = () => {
    if (location.key === 'default') {
      navigate(`/fitness-indexes`)
    } else {
      navigate(-1)
    }
  }

  const notBlank = (val) => {
    return val !== undefined && val !== null && val !== ''
  }

  const getQseManagerName = (koId) => {
    const result = qseManagerOptions.find((a) => a.koId === koId)
    if (result) return [result.firstName, result.lastName].join(' ')
    return undefined
  }

  // const updateFitnessIndex = async (fitnessIndex) => {
  //   setIsSaving(true)
  //   if ( !requestConfig ) requestConfig = await getRequestConfig()
  //   const basePath = `${ApiGateway.fitnessIndexes}/${fitnessIndexId}`
  //   const { data } = await axios.put(basePath, fitnessIndex, requestConfig)
  //   bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": ALERT_DURATION })
  //   setIsSaving(false)
  // }


  return (
    <ThemeProvider theme={theme}>
      {globalStyles}
      <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading}
        commandBar={
          <FormViewCommandBar onSave={onSave} onCancel={confirmCancel}
            loading={isLoading || isSaving}
            enableSave={true}
            showClose={true}
            onClose={onClose}
            showCancel={false}
            showSave={false}
            actionButtons={actionButtons}
          />}>
            <Button onClick={() => { console.log('fitnessIndex: ', fitnessIndex)}}>Check State</Button>
        <Grid container spacing={0} xs={12} sm={12}>
          <Grid container spacing={0} xs={12} sm={6} style={{padding:'1em'}}>
            <Grid item xs={12} sm={5}><Typography fontWeight={'bold'}>MANUFACTURER </Typography></Grid>
            <Grid container spacing={0} xs={12} sm={3}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>QSE Manager</Typography></Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple={false}
                    id="qseManager"
                    size='small'
                    options={qseManagerOptions}
                    value={fitnessIndex.qseManager}
                    openOnFocus
                    loading={ !qseManagerOptions }
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') return getQseManagerName(option)
                      return option.label
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        placeholder="QSE Manager"
                      />
                    )}
                    onChange={(_event, value) => {
                      handleFieldChange("qseManager", value, fitnessIndex, setFitnessIndex, setDirty)
                    }}
                  />
                  {
                    !fitnessIndex.qseManager && 
                    <FormHelperText error={true}>This field is required</FormHelperText>
                  }
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid container spacing={0} xs={12} sm={3}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Region</Typography></Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple={false}
                    id="region"
                    size='small'
                    options={regionOptions}
                    value={fitnessIndex.region}
                    openOnFocus
                    loading={ !regionOptions }
                    // getOptionLabel={(option) => {
                    //   if (typeof option === 'string') return getQseManagerName(option)
                    //   return option.label
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        placeholder="Region"
                      />
                    )}
                    onChange={(_event, value) => {
                      handleFieldChange("region", value, fitnessIndex, setFitnessIndex, setDirty)
                    }}
                  />
                  {
                    !fitnessIndex.region && 
                    <FormHelperText error={true}>This field is required</FormHelperText>
                  }
                </FormControl>
              </Grid>
              
            </Grid>
            <Grid item xs={12} sm={12}><p></p></Grid>
            <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Comments</Typography></Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField 
                    inputProps={{ maxLength: 500 }} 
                    value={fitnessIndex.comments} 
                    aria-label="Comments" 
                    multiline 
                    rows={5}
                    onChange={(event) => {
                      handleFieldChange("comments", event?.target?.value, fitnessIndex, setFitnessIndex, setDirty)
                    }}
                    helperText={`${fitnessIndex.comments?.length ?? 0}/500`}/>
                </FormControl>
              </Grid>
            {/* <AuthorizationDetailCard
              readOnly={readOnly}
              mfrProfile={mfrProfile}
              authorization={authorization || {}}
              approvalStatus={authorization?.approvalStatus}
              approvalStatusHighlighted={changes?.includes('approvalStatus') && showChanges}
              onChangeApprovalStatus={onChangeApprovalStatus}
            /> */}
          </Grid>
          <Grid container spacing={0} xs={12} sm={6}>
            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Score</Typography></Grid>
            </Grid>
            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Ranked</Typography></Grid>
            </Grid>
            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Group</Typography></Grid>
            </Grid>

            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Commitment</Typography></Grid>
            </Grid>
            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Compliance</Typography></Grid>
            </Grid>
            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Capability</Typography></Grid>
            </Grid>

            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Authorization Status</Typography></Grid>
            </Grid>
            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Winning/Losing</Typography></Grid>
            </Grid>
            <Grid container spacing={0} xs={12} sm={4} style={{padding:'1em', textAlign:'center'}}>
              <Grid item xs={12} sm={12}><Typography fontWeight={'bold'}>Facility Risk</Typography></Grid>
            </Grid>
          </Grid>
          <Grid container spacing={0} xs={12} sm={12} style={{padding:'1em', textAlign:'center'}}>
            <Card className="index-changes-over-time" style={{width:'100%'}}>
                <CardHeader sx={{textAlign:'left'}} title="Index Changes Over Time" onClick={toggleIndexChangesOverTime} action={indexChangesOverTimeIcon}></CardHeader>
                <Collapse in={indexChangesOverTimeExpanded} timeout="auto" unmountOnExit={false}>
                    <CardContent >
                        
                      Chart
                    </CardContent>
                </Collapse>
            </Card>
          </Grid>  
          <Grid container spacing={0} xs={12} sm={12} style={{padding:'1em', textAlign:'center'}}>
            <Card className="additional-manufacturer-details" style={{width:'100%'}}>
                <CardHeader sx={{textAlign:'left'}} title="Additional Manufacturer Details" onClick={toggleAdditionalManufacturerDetails} action={additionalManufacturerDetailsIcon}></CardHeader>
                <Collapse in={additionalManufacturerDetailsExpanded} timeout="auto" unmountOnExit={false}>
                    <CardContent >
                        
                      additional details
                    </CardContent>
                </Collapse>
            </Card>
          </Grid>
          
          <Box sx={{ width:'100%', margin: '0 1em' }}>
            <Tabs 
              textColor="inherit"
              id="create-index-tabs" 
              value={activeTab} 
              variant="fullWidth"
              onChange={onTabSelect} 
              TabIndicatorProps={{
                style: {
                  transition: 'none', // Disable the transition animation
                }
              }}>
                
                <Tab label="Key Business Dependencies"/>
                <Tab label="Certification Status"/>
                <Tab label="Plant Capabilities"/>
                <Tab label="KORE/Authorization Compliance"/>
                <Tab label="GAO Q1"/>
                <Tab label="GAO E1"/>
                <Tab label="GAO S1"/>
            </Tabs>
          </Box>
          
          {
            //***************************************************************
            //*                   Key Business Dependencies
            //***************************************************************
          }
          <TabPanel value={activeTab} index={0}>
            <YesNoRadio id="allergens" labelText="Does the Facility Produce Allergens?" value={fitnessIndex.allergens} 
              onValidate={Validate.required}
              onChange={(_event, value)=>{
                handleFieldChange("allergens", value, fitnessIndex, setFitnessIndex, setDirty)
              }}/>
          
            <YesNoRadio id="highRisk" labelText="Does the facility have an on-site Regulatory Agent monitoring the process (i.e. dairy)?" 
              value={fitnessIndex.highRisk} 
              onValidate={Validate.required}
              onChange={(_event, value)=>{
                handleFieldChange("highRisk", value, fitnessIndex, setFitnessIndex, setDirty)
              }}/>

            <YesNoRadio id="singleSource" labelText="Is the plant a single source for any product or geography?" value={fitnessIndex.singleSource}
              onValidate={Validate.required}
              onChange={(_event, value)=>{
                handleFieldChange("singleSource", value, fitnessIndex, setFitnessIndex, setDirty)
              }}/>

          </TabPanel>
          { 
            //***************************************************************
            //*                   Certification Status
            //*************************************************************** 
          }
          <TabPanel value={activeTab} index={1}>
            <CertificationStatusDropdown id="fssc22000"
              labelText="FSSC 22000 or Approved GFSI?"
              onValidate={Validate.required}
              value={fitnessIndex.fssc22000}
              onChange={(_event, value) => {
                handleFieldChange("fssc22000", value, fitnessIndex, setFitnessIndex, setDirty)
              }}/>
          
            <CertificationStatusDropdown id="iso9001"
              labelText="ISO 9001?"
              onValidate={Validate.required}
              value={fitnessIndex.iso9001}
              onChange={(_event, value) => {
                handleFieldChange("iso9001", value, fitnessIndex, setFitnessIndex, setDirty)
              }}/>

            <CertificationStatusDropdown id="iso14001"
              labelText="ISO 14001 or Equivalent?"
              onValidate={Validate.required}
              value={fitnessIndex.iso14001}
              onChange={(_event, value) => {
                handleFieldChange("iso14001", value, fitnessIndex, setFitnessIndex, setDirty)
              }}/>

            <CertificationStatusDropdown id="ohsas18001"
              labelText="OHSAS 18001 or Equivalent?"
              onValidate={Validate.required}
              value={fitnessIndex.ohsas18001}
              onChange={(_event, value) => {
                handleFieldChange("ohsas18001", value, fitnessIndex, setFitnessIndex, setDirty)
              }}/>
          </TabPanel>

          {
            // ***************************************************************
            // *                   Plant Capabilities
            // ***************************************************************
          }
          <TabPanel value={activeTab} index={2}>
            <NoInfoTextInput id="marketActions"
              labelText="Number of Market Actions and Stock Recovery for Past 12 Months:"
              onChange={(event, value) => {
                handleFieldChange("marketActions", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              value={fitnessIndex.marketActions}
              noInfoMode={fitnessIndex.marketActions === undefined}
              />

            <NoInfoTextInput id="failuresEscalated"
              labelText="Number of Failures Leading to an Escalated Issue for Past 12 Months:"
              onChange={(event, value) => {
                handleFieldChange("failuresEscalated", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              value={fitnessIndex.failuresEscalated}
              noInfoMode={fitnessIndex.failuresEscalated === undefined}
              />

            <NoInfoTextInput id="citations"
              labelText="# NOV/Citations in the past 12 months that have not been closed through RCA/CAP:"
              onChange={(event, value) => {
                handleFieldChange("citations", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              value={fitnessIndex.citations}
              noInfoMode={fitnessIndex.citations === undefined}
              />     

            <NoInfoTextInput id="consentOrders"
              labelText="Number of Active Consent Orders (Federal, State or Local):"
              onChange={(event, value) => {
                handleFieldChange("consentOrders", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              value={fitnessIndex.consentOrders}
              noInfoMode={fitnessIndex.consentOrders === undefined}
              />    

            <YesNoRadio id="chilledAseptic" 
              labelText={<span>Does the plant run Chilled or Aseptic Products? <Tooltip title='See "Platforms" in the "Additional Manufacturer Details" panel above to change this value'><Info/></Tooltip></span>}
              onValidate={Validate.required}
              value={fitnessIndex.chilledAseptic} onChange={(_event, value)=>{
              handleFieldChange("chilledAseptic", value, fitnessIndex, setFitnessIndex, setDirty)
            }}/>    

            <TextInput 
              id="minSterility"
              disabled={fitnessIndex.chilledAseptic !== "1"}
              labelText="Chilled/Aseptic Sterility (for worst line):"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("minSterility", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.minSterility}
            />

            <TextInput 
              id="plantTrends"
              labelText="Number of Plant Caused Trends for Past 12 Months:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("plantTrends", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.plantTrends}
            />

            <TextInput 
              id="closedPlantTrends"
              labelText="Number of Closed Plant Caused Trends for Past 12 Months:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("closedPlantTrends", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.closedPlantTrends}
            />

            <TextInput 
              id="brix"
              labelText="Brix (avg):"
              onValidate={[Validate.nonNegativeFloat]}
              onChange={(_event, value)=>{
                handleFieldChange("brix", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.brix}
            />

            <TextInput 
              id="assay"
              labelText="Assay (avg):"
              onValidate={[Validate.nonNegativeFloat]}
              onChange={(_event, value)=>{
                handleFieldChange("assay", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.assay}
            />

            <TextInput 
              id="tds"
              labelText="Tds (avg):"
              onValidate={[Validate.nonNegativeFloat]}
              onChange={(_event, value)=>{
                handleFieldChange("tds", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.tds}
            />

            <TextInput 
              id="ozone"
              labelText="Ozone (avg):"
              onValidate={[Validate.nonNegativeFloat]}
              onChange={(_event, value)=>{
                handleFieldChange("ozone", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.ozone}
            />

            <TextInput 
              id="customerCreditsAndReturns"
              labelText="Monthly Customer Credits and Returns:"
              onChange={(_event, value)=>{
                handleFieldChange("customerCreditsAndReturns", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.customerCreditsAndReturns}
            />

          </TabPanel>
          {
            // ***************************************************************
            // *                   KORE/Authorization Compliance
            // ***************************************************************
          }
          <TabPanel value={activeTab} index={3}>
            {/*
               <div className="row delimited-row">
                    <Col componentClass={ControlLabel} lg={this.formGroupLayout.labelCols.lg} md={this.formGroupLayout.labelCols.md} sm={this.formGroupLayout.labelCols.sm} xs={this.formGroupLayout.labelCols.xs}>
                      Date of Last Authorization Assessment or Authorization Review?
                    </Col>
                    <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>                    
                      <DatePickerInput id="lastAuthAssessDate"
                        disabled={this.isReadOnly()}
                        syncDate={this.state.mfi.lastAuthAssessDateSyncDate}
                        labelText="Date of Last Authorization Assessment or Authorization Review?"
                        onChange={(event) => {
                          this.onInputChange({
                            target: {
                              value: event.toISOString(),
                              id: "lastAuthAssessDate"
                            }
                          });
                        }}
                        value={this.state.mfi.lastAuthAssessDate}
                        ref={(me) => {
                          this.formControls.lastAuthAssessDate = me;
                        }}/>
                    </Col>
                    <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>
                      <span style={{
                        lineHeight: '24px',
                        position: 'relative',
                        top: '.2em'
                      }}>
                        {authAssessDateSyncLogo}
                      </span>
                    </Col>                         
                  </div>

                  <div className="row delimited-row">
                    <AuthorizationStatusDropdown id="authStatus"
                      
                      labelText="Authorization Status:"
                      value={this.state.mfi.authStatus}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      ref={(me) => {
                        this.formControls.authStatus = me;
                      }}/>
                  </div>
            */}
            <TextInput 
              id="authTotalNc"
              labelText="Number of TOTAL Non-Conformances:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("authTotalNc", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.authTotalNc}
            />

            <TextInput 
              id="authRepeatNc"
              labelText="Number of REPEAT Non-Conformances:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("authRepeatNc", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.authRepeatNc}
            />

            <TextInput 
              id="authClosedNc"
              labelText="Number of CLOSED Non-Conformances:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("authClosedNc", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.authClosedNc}
            />

            <TextInput 
              id="authTotalHrNc"
              labelText="Number of TOTAL Conditional and High Risk Non-Conformances:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("authTotalHrNc", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.authTotalHrNc}
            />

            <TextInput 
              id="authRepeatHrNc"
              labelText="Number of REPEAT Conditional and High Risk Non-Conformances:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("authRepeatHrNc", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.authRepeatHrNc}
            />

            <TextInput 
              id="authClosedHrNc"
              labelText="Number of CLOSED Conditional and High Risk Non-Conformances:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("authClosedHrNc", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.authClosedHrNc}
            />
          </TabPanel>
          {
            // ***************************************************************
            // *                   GAO Q1
            // ***************************************************************
          }
          <TabPanel value={activeTab} index={4}>
            <TextInput 
              id="q1TotalMinor"
              labelText="Number of TOTAL Q1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1TotalMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1TotalMinor}
            />
            <TextInput 
              id="q1RepeatMinor"
              labelText="Number of REPEAT Q1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1RepeatMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1RepeatMinor}
            />  

            <TextInput 
              id="q1ClosedMinor"
              labelText="Number of CLOSED Q1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1ClosedMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1ClosedMinor}
            />          

            <TextInput 
              id="q1TotalMajor"
              labelText="Number of TOTAL Q1 Major Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1TotalMajor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1TotalMajor}
            />

            <TextInput 
              id="q1ClosedMajor"
              labelText="Number of CLOSED Q1 Major Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1ClosedMajor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1ClosedMajor}
            />

            <TextInput 
              id="q1TotalCritical"
              labelText="Number of TOTAL Q1 High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1TotalCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1TotalCritical}
            />

            <TextInput 
              id="q1RepeatMajorCritical"
              labelText="Number of REPEAT Q1 Major AND High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1RepeatMajorCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1RepeatMajorCritical}
            />

            <TextInput 
              id="q1ClosedCritical"
              labelText="Number of CLOSED Q1 High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("q1ClosedCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.q1ClosedCritical}
            />
          </TabPanel>
          {
            // ***************************************************************
            // *                   GAO E1
            // ***************************************************************
          }
          <TabPanel value={activeTab} index={5}>
            <TextInput 
              id="e1TotalMinor"
              labelText="Number of TOTAL E1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1TotalMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1TotalMinor}
            />
            <TextInput 
              id="e1RepeatMinor"
              labelText="Number of REPEAT E1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1RepeatMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1RepeatMinor}
            />  

            <TextInput 
              id="e1ClosedMinor"
              labelText="Number of CLOSED E1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1ClosedMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1ClosedMinor}
            />          

            <TextInput 
              id="e1TotalMajor"
              labelText="Number of TOTAL E1 Major Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1TotalMajor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1TotalMajor}
            />

            <TextInput 
              id="e1ClosedMajor"
              labelText="Number of CLOSED E1 Major Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1ClosedMajor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1ClosedMajor}
            />

            <TextInput 
              id="e1TotalCritical"
              labelText="Number of TOTAL E1 High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1TotalCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1TotalCritical}
            />

            <TextInput 
              id="e1RepeatMajorCritical"
              labelText="Number of REPEAT E1 Major AND High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1RepeatMajorCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1RepeatMajorCritical}
            />

            <TextInput 
              id="e1ClosedCritical"
              labelText="Number of CLOSED E1 High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("e1ClosedCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.e1ClosedCritical}
            />
          </TabPanel>
          {
            // ***************************************************************
            // *                   GAO S1
            // ***************************************************************
          }
          <TabPanel value={activeTab} index={6}>
            <TextInput 
              id="s1TotalMinor"
              labelText="Number of TOTAL S1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1TotalMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1TotalMinor}
            />
            <TextInput 
              id="s1RepeatMinor"
              labelText="Number of REPEAT S1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1RepeatMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1RepeatMinor}
            />  

            <TextInput 
              id="s1ClosedMinor"
              labelText="Number of CLOSED S1 Minor Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1ClosedMinor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1ClosedMinor}
            />          

            <TextInput 
              id="s1TotalMajor"
              labelText="Number of TOTAL S1 Major Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1TotalMajor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1TotalMajor}
            />

            <TextInput 
              id="s1ClosedMajor"
              labelText="Number of CLOSED S1 Major Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1ClosedMajor", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1ClosedMajor}
            />

            <TextInput 
              id="s1TotalCritical"
              labelText="Number of TOTAL S1 High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1TotalCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1TotalCritical}
            />

            <TextInput 
              id="s1RepeatMajorCritical"
              labelText="Number of REPEAT S1 Major AND High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1RepeatMajorCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1RepeatMajorCritical}
            />

            <TextInput 
              id="s1ClosedCritical"
              labelText="Number of CLOSED S1 High Risk and Above Findings:"
              onValidate={[Validate.required, Validate.nonNegativeInt]}
              onChange={(_event, value)=>{
                handleFieldChange("s1ClosedCritical", value, fitnessIndex, setFitnessIndex, setDirty)
              }}
              value={fitnessIndex.s1ClosedCritical}
            />
          </TabPanel>          
{/*}
           
                {// ***************************************************************
                 // *                         GAO Q1
                 // ***************************************************************}
                <Tab eventKey={5} title={this.state.gaoQ1Tab.title} tabClassName={this.state.gaoQ1Tab.className}>
                  <h4>GAO Q1</h4>
                  <br/>

                  <div className="row delimited-row">
                    <Col componentClass={ControlLabel} lg={this.formGroupLayout.labelCols.lg} md={this.formGroupLayout.labelCols.md} sm={this.formGroupLayout.labelCols.sm} xs={this.formGroupLayout.labelCols.xs}>
                      Date of Last Audit?
                    </Col>
                    <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>
                      <DatePickerInput id="lastGaoQ1Date"
                        disabled={this.isReadOnly()}
                        syncDate={this.state.mfi.lastGaoQ1DateSyncDate}
                        labelText="Date of Last Authorization Assessment or Authorization Review?"
                        onChange={(event) => {
                          this.onInputChange({
                            target: {
                              value: event.toISOString(),
                              id: "lastGaoQ1Date"
                            }
                          });
                        }}
                        value={this.state.mfi.lastGaoQ1Date}
                        ref={(me) => {
                          this.formControls.lastGaoQ1Date = me;
                        }}/>
                      </Col>
                      <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>
                        <span style={{
                          lineHeight: '24px',
                          position: 'relative',
                          top: '.2em'
                        }}>
                          {lastGaoQ1DateSyncLogo}
                        </span>
                      </Col>                       
                  </div>
                </Tab>
                {// ***************************************************************
                 // *                         GAO E1
                 // ***************************************************************}
                <Tab eventKey={6} title={this.state.gaoE1Tab.title} tabClassName={this.state.gaoE1Tab.className}>
                  <h4>GAO E1</h4>
                  <br/>
                  <div className="row delimited-row">
                    <Col componentClass={ControlLabel} lg={this.formGroupLayout.labelCols.lg} md={this.formGroupLayout.labelCols.md} sm={this.formGroupLayout.labelCols.sm} xs={this.formGroupLayout.labelCols.xs}>
                      Date of Last Audit?
                    </Col>
                    <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>                    
                      <DatePickerInput id="lastGaoE1Date"
                        disabled={this.isReadOnly()}
                        syncDate={this.state.mfi.lastGaoE1DateSyncDate}
                        labelText="Date of Last Authorization Assessment or Authorization Review?"
                        onChange={(event) => {
                          this.onInputChange({
                            target: {
                              value: event.toISOString(),
                              id: "lastGaoE1Date"
                            }
                          });
                        }}
                        value={this.state.mfi.lastGaoE1Date}
                        ref={(me) => {
                          this.formControls.lastGaoE1Date = me;
                        }}/>                    
                    </Col>
                    <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>
                        <span style={{
                          lineHeight: '24px',
                          position: 'relative',
                          top: '.2em'
                        }}>
                          {lastGaoE1DateSyncLogo}
                        </span>
                      </Col>                       
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1TotalMinor"
                      
                      labelText="Number of TOTAL E1 Minor Findings:"
                      syncDate={this.state.mfi.e1TotalMinorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.e1TotalMinor}
                      ref={(me) => {
                        this.formControls.e1TotalMinor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1RepeatMinor"
                      
                      labelText="Number of REPEAT E1 Minor Findings:"
                      syncDate={this.state.mfi.e1RepeatMinorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.e1RepeatMinor}
                      ref={(me) => {
                        this.formControls.e1RepeatMinor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1ClosedMinor"
                      
                      labelText="Number of CLOSED E1 Minor Findings:"
                      syncDate={this.state.mfi.e1ClosedMinorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeFloatValidation}
                      value={this.state.mfi.e1ClosedMinor}
                      ref={(me) => {
                        this.formControls.e1ClosedMinor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1TotalMajor"
                      
                      labelText="Number of TOTAL E1 Major Findings:"
                      syncDate={this.state.mfi.e1TotalMajorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.e1TotalMajor}
                      ref={(me) => {
                        this.formControls.e1TotalMajor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1ClosedMajor"
                      
                      labelText="Number of CLOSED E1 Major Findings:"
                      syncDate={this.state.mfi.e1ClosedMajorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeFloatValidation}
                      value={this.state.mfi.e1ClosedMajor}
                      ref={(me) => {
                        this.formControls.e1ClosedMajor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1TotalCritical"
                      
                      labelText="Number of TOTAL E1 High Risk and Above Findings:"
                      syncDate={this.state.mfi.e1TotalCriticalSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.e1TotalCritical}
                      ref={(me) => {
                        this.formControls.e1TotalCritical = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1RepeatMajorCritical"
                      
                      labelText="Number of REPEAT E1 Major AND High Risk and Above Findings:"
                      syncDate={this.state.mfi.e1RepeatMajorCriticalSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.e1RepeatMajorCritical}
                      ref={(me) => {
                        this.formControls.e1RepeatMajorCritical = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="e1ClosedCritical"
                      
                      labelText="Number of CLOSED E1 High Risk and Above Findings:"
                      syncDate={this.state.mfi.e1ClosedCriticalSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeFloatValidation}
                      value={this.state.mfi.e1ClosedCritical}
                      ref={(me) => {
                        this.formControls.e1ClosedCritical = me;
                      }}/>
                  </div>
                </Tab>
                {// ***************************************************************
                 // *                         GAO S1
                 // ***************************************************************}
                <Tab eventKey={7} title={this.state.gaoS1Tab.title} tabClassName={this.state.gaoS1Tab.className}>
                  <h4>GAO S1</h4>
                  <br/>
                  <div className="row delimited-row">
                    <Col componentClass={ControlLabel} lg={this.formGroupLayout.labelCols.lg} md={this.formGroupLayout.labelCols.md} sm={this.formGroupLayout.labelCols.sm} xs={this.formGroupLayout.labelCols.xs}>
                      Date of Last Audit?
                    </Col>
                    <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>
                      <DatePickerInput id="lastGaoS1Date"
                        disabled={this.isReadOnly()}
                        syncDate={this.state.mfi.lastGaoS1DateSyncDate}
                        labelText="Date of Last Authorization Assessment or Authorization Review?"
                        onChange={(event) => {
                          this.onInputChange({
                            target: {
                              value: event.toISOString(),
                              id: "lastGaoS1Date"
                            }
                          });
                        }}
                        value={this.state.mfi.lastGaoS1Date}
                        ref={(me) => {
                          this.formControls.lastGaoS1Date = me;
                        }}/>   
                    </Col>
                    <Col className = "p0" lg={this.formGroupLayout.inputCols.lg} md={this.formGroupLayout.inputCols.md} sm={this.formGroupLayout.inputCols.sm} xs={this.formGroupLayout.inputCols.xs}>
                      <span style={{
                        lineHeight: '24px',
                        position: 'relative',
                        top: '.2em'
                      }}>
                        {lastGaoS1DateSyncLogo}
                      </span>
                    </Col>   
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1TotalMinor"
                      
                      labelText="Number of TOTAL S1 Minor Findings:"
                      syncDate={this.state.mfi.s1TotalMinorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.s1TotalMinor}
                      ref={(me) => {
                        this.formControls.s1TotalMinor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1RepeatMinor"
                      
                      labelText="Number of REPEAT S1 Minor Findings:"
                      syncDate={this.state.mfi.s1RepeatMinorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.s1RepeatMinor}
                      ref={(me) => {
                        this.formControls.s1RepeatMinor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1ClosedMinor"
                      
                      labelText="Number of CLOSED S1 Minor Findings:"
                      syncDate={this.state.mfi.s1ClosedMinorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeFloatValidation}
                      value={this.state.mfi.s1ClosedMinor}
                      ref={(me) => {
                        this.formControls.s1ClosedMinor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1TotalMajor"
                      
                      labelText="Number of TOTAL S1 Major Findings:"
                      syncDate={this.state.mfi.s1TotalMajorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.s1TotalMajor}
                      ref={(me) => {
                        this.formControls.s1TotalMajor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1ClosedMajor"
                      
                      labelText="Number of CLOSED S1 Major Findings:"
                      syncDate={this.state.mfi.s1ClosedMajorSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeFloatValidation}
                      value={this.state.mfi.s1ClosedMajor}
                      ref={(me) => {
                        this.formControls.s1ClosedMajor = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1TotalCritical"
                      
                      labelText="Number of TOTAL S1 High Risk and Above Findings:"
                      syncDate={this.state.mfi.s1TotalCriticalSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.s1TotalCritical}
                      ref={(me) => {
                        this.formControls.s1TotalCritical = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1RepeatMajorCritical"
                      
                      labelText="Number of REPEAT S1 Major AND High Risk and Above Findings:"
                      syncDate={this.state.mfi.s1RepeatMajorCriticalSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeIntegerValidation}
                      value={this.state.mfi.s1RepeatMajorCritical}
                      ref={(me) => {
                        this.formControls.s1RepeatMajorCritical = me;
                      }}/>
                  </div>

                  <div className="row delimited-row">
                    <TextInput id="s1ClosedCritical"
                      
                      labelText="Number of CLOSED S1 High Risk and Above Findings:"
                      syncDate={this.state.mfi.s1ClosedCriticalSyncDate}
                      onChange={(event) => {
                        this.onInputChange(event);
                      }}
                      onValidate={NonNegativeFloatValidation}
                      value={this.state.mfi.s1ClosedCritical}
                      ref={(me) => {
                        this.formControls.s1ClosedCritical = me;
                      }}/>
                  </div> 
                </Tab>
              </Tabs>  */}
        </Grid>
      </Page>
    </ThemeProvider>
  )
}