import { Buffer } from 'buffer'
import { useEffect, useState } from "react"
import { getToken, logout } from './auth'
import { cleanup } from './role'
import ConfirmDialog from '../form/ConfirmDialog'
import EventBus from '../utils/EventBus'

const logLocal = (...args) => {
  if (window.location.hostname.includes('localhost')) {
    console.log(...args)
  }
}

let _timeout
let _interval

export default function SessionTimeout(props={token: undefined}) {
  const [isExtendSessionDialogVisible, setIsExtendSessionDialogVisible] = useState(false)

  const _logout = async () => {
    clearMonitors()
    setIsExtendSessionDialogVisible(false)
    cleanup()
    logout()
  }

  const parseJwt = (token) => {
    if(token) {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    } else {
      console.error("Trying to parse a jwt token, but no token was provided.")
      return false
    }
  }

  const clearMonitors = () => {
    if (_timeout) {
      clearTimeout(_timeout)
      _timeout = undefined 
    }
    if (_interval) {
      clearInterval(_interval)
      _interval = undefined
    }
  }

  const extendSession = () => {
    clearMonitors()
    setIsExtendSessionDialogVisible(false)
    login()
  }

  const login = () => {
    getToken()
  }

  const createSessionMonitor = () => {
    _interval = setInterval(async () => {
      const jwt = await getToken(true)
      const jwtExp = jwt?.idTokenClaims?.exp
      if (jwtExp) {
        let warningGracePeriod = 5 * 60 * 1000
        const exp = new Date(jwtExp * 1000).getTime()
        const now = new Date().getTime()
        logLocal(`Session Interval: warning in ${parseInt((exp - warningGracePeriod - now) / 60 / 1000)} minutes`)

        if (now > exp) {
          _logout()
        } else if (now + warningGracePeriod > exp) {
          if (! isExtendSessionDialogVisible) {
            setIsExtendSessionDialogVisible(true)
            _timeout = setTimeout(_logout, warningGracePeriod)
          }
        }
      } else {
        logLocal('Could not extract exp')
        _logout()
      }
    }, 30 * 1000)
  }

  const initialize = (_token) => {
    const jwt = parseJwt(_token?.idToken)
    clearMonitors()
    
    if(jwt) {
      let ttl = (jwt.exp - jwt.iat) / 60
      logLocal(`Server Session duration: ${ttl} minutes. Expire at ${new Date(jwt.exp * 1000).toLocaleString()}`)

      createSessionMonitor()

      } else {
        logLocal(`No Token`)
        _logout()
      }
  }

  const handleToken = (token) => {
    logLocal(`handleToken`, token)
    initialize(token)
  }

  useEffect(() => {
    EventBus.on('token', handleToken)
    return () => {
      EventBus.removeListener('token', handleToken)
    }
  }, [])

  return (
    <>
    {isExtendSessionDialogVisible && <ConfirmDialog title="Session Expiring Soon" text="Would you like to extend your session and keep working?" open={isExtendSessionDialogVisible}  handleConfirm={extendSession} hideDialog={_logout} yesLabel="Keep Working" noLabel="Log Out"  />}
    </>
  )
}