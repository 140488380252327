import { default as React, useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { Factory } from '@mui/icons-material'
import ConfirmDialog from '../form/ConfirmDialog'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate, useBlocker } from 'react-router-dom'
import ProfileSectionNavigator from '../form/ProfileSectionNavigator';
import { loadOwnership } from './data';
import OwnershipDetailsSection from './sections/OwnershipDetailsSection'
import OwnershipContactsSection from './sections/OwnershipContactsSection'
import EventBus from '../utils/EventBus';

export default function EditOwnershipPage(props = {}) {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [tryAgainDialog, setTryAgainDialog] = useState(false)
  const [ownership, setOwnership] = useState(false)
  const [CurrentSection, setCurrentSection] = useState(<OwnershipDetailsSection />)
  const [isEditAllowed, setIsEditAllowed] = useState(false)

  const [isValidForSave, setIsValidForSave] = useState(false)
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isValidForSave &&
      currentLocation.pathname !== nextLocation.pathname
  )

  const breadcrumb = <div><span><Factory />&nbsp;{ownership?.ownershipName || 'Ownerships'}</span></div>

  
  const onSave = async () => {
    EventBus.emit('saveRequested')
  }

  const onStateValidation = (inst, valid) => {
    setIsValidForSave(valid)
  }

  const tryAgainSave = async () => {
    // hideTryAgainDialog()
    await onSave()
  }

  const confirmCancel = () => {
    if (isValidForSave) { 
      setConfirmDialog(true)
    } else handleDiscard()
  }

  const handleRemain = () => {
    setConfirmDialog(false)
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
  }

  const handleDiscard = () => {
    setConfirmDialog(false)
    setIsValidForSave(false)
    setEditMode(false)
    if (blocker.state === 'blocked') {
      blocker.proceed()
    }
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/ownership/list`)
    } else {
      navigate(-1)
    }
  }

  let saveUnsuccessfulError
  const getSaveUnsuccessfulText = () => {
    return `Could not save due to ${saveUnsuccessfulError}, do you want to try again?`
  }
  const hideTryAgainDialog = () => {
    setTryAgainDialog(false)
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const initializePage = async () => {
    startLoading()
    const _own = await loadOwnership(params.ownershipUuid)
    setOwnership(_own)
    stopLoading()
  }

  const [editMode, setEditMode] = useState(false)
  const [openAssessment, setOpenAssessment] = useState()

  const onEdit = () => {
    setEditMode(true)
  }

  useEffect(() => {
    initializePage()
  }, [])

  const [profileSections, setProfileSections] = useState([])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirmCancel()
    }
  }, [blocker.state])

  useEffect(() => {
    const sectionProps = {
      setOwnership,
      ownership,
      editMode,
      setSectionIsEditable: setIsEditAllowed,
      onStateValidation
    }
    setProfileSections([
      {
        name: 'Ownership Details',
        href: `/ownership/edit/${params.ownershipUuid}/details`,
        el: <OwnershipDetailsSection {...sectionProps} />
      },
      {
        name: 'Contacts',
        href: `/ownership/edit/${params.ownershipUuid}/contacts`,
        el: <OwnershipContactsSection {...sectionProps} />
      }
    ])
  }, [params.ownershipUuid, ownership, editMode])

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
      <FormViewCommandBar
        onSave={onSave}
        onEdit={onEdit}
        onCancel={confirmCancel}
        onClose={handleClose}
        enableSave={isValidForSave}
        showClose={!editMode}
        showCancel={editMode}
        showEdit={!editMode && isEditAllowed}
        showSave={editMode}
      />
    }>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={handleRemain} handleConfirm={handleDiscard} yesLabel="Discard" noLabel="Stay on Page" />
      <ConfirmDialog title="Save was not successful" text={getSaveUnsuccessfulText()} open={tryAgainDialog} hideDialog={hideTryAgainDialog} handleConfirm={tryAgainSave} yesLabel="Try Again" noLabel="Cancel" />
      <ProfileSectionNavigator
        setCurrentSection={setCurrentSection}
        profileSections={profileSections}
      />
      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto' }}
      >
        { ownership && CurrentSection}
      </Paper>
    </Page>
  )
}