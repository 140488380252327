import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import SaveIcon from '@mui/icons-material/Save';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { getProperty, setProperty } from 'dot-prop';

export const checkRequiredError = (value) => {
    //console.log(value)
    return (value === "" || (Array.isArray(value) && value.length === 0) || value===null)
  }
  
export const checkStrictRequiredError = (value, required) => {
  if (value === undefined) return true
  if (typeof value === 'string' && value === '') return true
  if (typeof value === 'number') return false // if it's a number it can be 0 and it's not undefined
    //return value === [] || value === null || value === undefined || value === '' || !value.length
  if (Array.isArray(value) && value.length === 0) return true
    return !value
  }

export const checkEmptyArrayError = (value) => {
  return (!value || !value.length)
}
  
export const checkNumberError = (value) => {
    if (checkRequiredError(value))
      return "This field is required"
    else if (value < 0)
      return "Must be a non-negative number"
    return false
  }
  
export const checkYearError = (value, minYear=1900, maxYear=2200) => {
  if (checkRequiredError(value))
    return "This field is required"
  else if (value < minYear || value > maxYear)
    return "Must be a valid year (YYYY)"
  return false
}

export const checkPhoneError = (phone) => {
    if ((!phone || phone === "+1"))
      return false //phone number not required
    const re = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    //if (matchIsValidTel(phone)) {
    if ( re.test(phone.trim())) {
      return false
    }
    return true
  }
  
export const checkDateError = (value) => {
  console.log('checkDateError', value)
    if (value==null){
      //console.log(value)
      return true
    }
    else if (value.$d){
      //console.log(value.$d)
      if (value.$d === "Invalid Date")
        return true
      return false
    }
    return true
  }
  
export const checkEmailError = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === undefined || email === ""){
      //console.log("empty field")
      return false
    }
    else if ( re.test(email) ) {
      //console.log("valid email")
      return false
    }
    else {
      //console.log("invalid email")
      return true
    }
  }
  
export const checkColumnError = (_id, value, errorsList, errorsListSetter, required) => {
  const id = _id.indexOf('.' > -1) ? _id.split('.').pop() : _id
  let hasErrorCondition = false
  let errorCondition = ''
  if (required === undefined) required = true
  if (required && checkStrictRequiredError(value)) {    
    errorCondition = `This field is required`
    hasErrorCondition = true
  } 
  else if (id === "email" && checkEmailError(value)){
    errorCondition = `Not a valid email`
    hasErrorCondition = true
  }
  else if (id === "phone" && checkPhoneError(value, required)){
    errorCondition = `Not a valid phone`
    hasErrorCondition = true
  }
  if (hasErrorCondition) {
    errorsListSetter({...errorsList, [id]: errorCondition,})
  }
  else {
    delete errorsList[id]
    errorsListSetter({...errorsList,});
  }
  //console.log(`checkColumnError ${[id]} ${hasErrorCondition} errorCondition ${errorCondition}`)
  return hasErrorCondition
}
  
export const validateColumns = (columns, valuesList, errorsList, errorsListSetter) => {
    for (let i=0; i<columns.length; i++){
      const column = columns[i]
      if (column.columnDefType !== 'display'
        && checkColumnError(column.accessorKey, getProperty(valuesList, column.accessorKey), errorsList, errorsListSetter, column.required)){
        return false
      }
    }
    return true
  }
  
export const AddRecordModal = ({ open, columns, onClose, addRow, rowSetter, setDirty=false, onSubmit, preJsx }) => {
    const [values, setValues] = useState(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        return acc;
      }, {}),
    );
    const [errors, setErrors] = useState({});

    const [saving, setSaving] = useState(false)

    const getCustomSelectOptions = (customOptions) => {
      if (Array.isArray(customOptions)) {
        return customOptions.map((pt, ptidx) => {return (<MenuItem value={pt} key={ptidx}>{pt}</MenuItem>)})
      } else {
        return Object.keys(customOptions).map((pt, ptidx) => {return (<MenuItem value={customOptions[pt]} key={ptidx}>{pt}</MenuItem>)})
      }
    }
  
    const handleSubmit = async () => {
      if (validateColumns(columns, values, errors, setErrors)) {
        setSaving(true)
        if (onSubmit) {
          await onSubmit(values)
        } else {
          addRow(rowSetter, values)
        }
        setErrors({}); 
        setValues({});
        if (setDirty) { setDirty() }
        setSaving(false)
        onClose();
      }
    };
  
    return (
      <Dialog open={open} fullWidth maxWidth='md'>
        <DialogTitle textAlign="center">Add Record</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
            margin={1}
            spacing={2}
            >
              {preJsx ? preJsx : undefined}
              {columns.reduce((p, column) => {
                if (column.columnDefType === 'display') return p
                if(column.accessorKey === "phone"){
                  p.push(
                  <MuiTelInput 
                    size='small'
                    defaultCountry='US'
                    disableDropdown
                    forceCallingCode
                    value={values[column.accessorKey]}
                    onChange={(event) => {
                      setValues({ ...values, [column.accessorKey]: event })
                    }}
                    label={column.inputLabel ?? column.header}
                    placeholder={column.inputLabel ?? column.header}
                    name={column.accessorKey}
                    key={column.accessorKey}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    onBlur={()=>checkColumnError(column.accessorKey, values[column.accessorKey], errors, setErrors, false)}
                  />)
                } else 
                if (column.customType === 'Date') {
                  p.push(
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}>
                        <DateField
                          format='MM/DD/YYYY'
                          defaultValue={values[column.accessorKey] || undefined}
                          onBlur={(event) => {
                            setValues({ ...values, [column.accessorKey]: event.target.value})
                          }}
                          slotProps={{
                            textField: {
                              label: column.inputLabel ?? column.header ,
                              size: 'small',
                              // error: (checkRequiredError(values[column.accessorKey])),
                              helperText: (checkRequiredError(values[column.accessorKey]) ? "This field is required" : false)
                            }
                          }}
                        />
                      </LocalizationProvider>
                  )
                } else
                if(column.customType && column.customType === "Select"){
                  p.push(
                    <FormControl error={checkRequiredError(values[column.accessorKey])}
                      helperText={errors[column.accessorKey]}
                      size='small'>
                        <InputLabel>{column.inputLabel ?? column.header}</InputLabel>
                        <Select
                          value={values[column.accessorKey]}
                          onChange={(e) => setValues({ ...values, [column.accessorKey]: e.target.value })}
                          size='small'
                        >
                        { getCustomSelectOptions(column.customOptions) }
                      </Select>
                    </FormControl>
                  )
                } else
                if (column.customType && column.customType === "Autocomplete"){
                  const onChange = (_event, value) => {
                    setValues({ ...values, [column.accessorKey]: value });
                  }
                  p.push(
                    column.customElement(values[column.accessorKey], onChange)
                  )
                } else {
                //TODO handle date fields
                p.push(
                  <TextField
                    key={column.accessorKey}
                    label={column.inputLabel ?? column.header}
                    name={column.accessorKey}
                    size='small'
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    onBlur={()=>checkColumnError(column.accessorKey, values[column.accessorKey], errors, setErrors, column.required)}
                  />
              )}
              return p},[])}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={()=> {setErrors({}); setValues({}); onClose();}}>Cancel</Button>
          <LoadingButton
            size="small"
            color="primary"
            onClick={handleSubmit}
            loading={saving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>Add Record</span>
        </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  export const EditRecordModal = ({ open, columns, getCurrentValues, onClose, setDirty=false, onSubmit }) => {
    const [values, setValues] = useState(getCurrentValues())
    const [errors, setErrors] = useState({})

    const [saving, setSaving] = useState(false)
  
    const handleSubmit = async () => {
      if (validateColumns(columns, values, errors, setErrors)) {
        setSaving(true)
        if (onSubmit) {
          await onSubmit(values)
        }
        setErrors({}); 
        setValues({});
        if (setDirty) { setDirty() }
        setSaving(false)
        onClose();
      }
    }

    const getCustomSelectOptions = (customOptions) => {
      if (Array.isArray(customOptions)) {
        return customOptions.map((pt, ptidx) => {return (<MenuItem value={pt} key={ptidx}>{pt}</MenuItem>)})
      } else {
        return Object.keys(customOptions).map((pt, ptidx) => {return (<MenuItem value={customOptions[pt]} key={ptidx}>{pt}</MenuItem>)})
      }
    }

    const doOpen = () => {
      setValues(getCurrentValues())
    }
    
    useEffect(() => {
      doOpen()
    }, [open])
    return (
      <Dialog open={open} fullWidth maxWidth='md'>
        <DialogTitle textAlign="center">Edit Record</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
            margin={1}
            spacing={2}
            >
              {columns.reduce((p, column) => {
                if (column.columnDefType === 'display') return p
                if(column.accessorKey === "phone"){
                  p.push(
                  <MuiTelInput 
                    size='small'
                    defaultCountry='US'
                    disableDropdown
                    forceCallingCode
                    value={values[column.accessorKey]}
                    onChange={(event) => {
                      setValues({ ...values, [column.accessorKey]: event })
                    }}
                    label={column.inputLabel ?? column.header}
                    placeholder={column.inputLabel ?? column.header}
                    name={column.accessorKey}
                    key={column.accessorKey}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    onBlur={()=>checkColumnError(column.accessorKey, values[column.accessorKey], errors, setErrors, false)}
                  />)
                } else
                if (column.customType === 'Date') {
                  p.push(
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}>
                        <DateField
                          format='MM/DD/YYYY'
                          value={dayjs(values[column.accessorKey] || new Date())}
                          onChange={(value) => {
                            setValues({ ...values, [column.accessorKey]: value})
                          }}
                          slotProps={{
                            textField: {
                              label: column.inputLabel ?? column.header ,
                              size: 'small',
                              // error: (checkRequiredError(values[column.accessorKey])),
                              helperText: (checkRequiredError(values[column.accessorKey]) ? "This field is required" : false)
                            }
                          }}
                        />
                      </LocalizationProvider>
                  )
                } else
                if(column.customType && column.customType === "Select"){
                    p.push(
                      <FormControl error={checkRequiredError(values[column.accessorKey])}
                        helperText={errors[column.accessorKey]}
                        size='small'>
                          <InputLabel>{column.inputLabel ?? column.header}</InputLabel>
                          <Select
                            value={values[column.accessorKey]}
                            onChange={(e) => setValues({ ...values, [column.accessorKey]: e.target.value })}
                            size='small'
                          >
                          { getCustomSelectOptions(column.customOptions) }
                        </Select>
                      </FormControl>
                    )
                } else
                if (column.customType && column.customType === "Autocomplete"){
                  const onChange = (_event, value) => {
                    setValues({ ...values, [column.accessorKey]: value });
                  }
                  p.push(
                    column.customElement(values[column.accessorKey], onChange)
                  )
                } else {
                //TODO handle date fields
                p.push(
                  <TextField
                    key={column.accessorKey}
                    label={column.inputLabel ?? column.header}
                    name={column.accessorKey}
                    size='small'
                    value={values[column.accessorKey]}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    onBlur={()=>checkColumnError(column.accessorKey, values[column.accessorKey], errors, setErrors, column.required)}
                  />
              )}
              return p},[])}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={()=> {setErrors({}); setValues({}); onClose();}}>Cancel</Button>
          <LoadingButton
            size="small"
            color="primary"
            onClick={handleSubmit}
            loading={saving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>Save</span>
        </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

export const addRow = (setter, emptyRow={}) => {
    //emptyRow.id = randomId()
    console.log(emptyRow)
    setter(prev => {
      prev = prev && Array.isArray(prev) ? prev : []
      return [...prev, emptyRow]
    })
  }

export const deleteRow = (id, getter, setter) => {
    // console.log("delete ", id)
    setter(getter.filter((row) => row.id !== id))
  }
  
export const stringDate = (date) => {
    if (date && date.$D && date.$M && date.$y){
      const day = (date.$D > 9) ? '' + date.$D : '0' + date.$D
      const month = (date.$M > 8) ? '' + (date.$M + 1) : '0' + (date.$M +1)
      const year = '' + date.$y
      //console.log(year + '-' + month + '-' + day)
      return year + '-' + month + '-' + day
    }
    return date
  }

export const handleSaveRowEdits = async ({ exitEditingMode, row, values }, rowsObject, rowSetter, validationErrors, overrideColumns=[], setDirty=false) => {
    if (!Object.keys(validationErrors).length) {      
      for(let i=0; i<overrideColumns.length; i++){
        const column=overrideColumns[i]
        values[column]=rowsObject[row.index][column]
      }
      
      rowsObject[row.index] = values
      console.log('Edited value:', rowsObject)

      rowSetter([...rowsObject])
      if (setDirty) { setDirty() }
      exitEditingMode()
    }
  };

export const handleEditClick = (row, table, setTempRow) => {
    //console.log(row)
    table.setEditingRow(row)
    const originalRow=structuredClone(row.original)
    // console.log(originalRow)
    setTempRow(originalRow)
    // console.log(tempRow)
  }

export const handleCancelEdit = (row, prevRows, rowSetter, tempRow, setValidationErrors) => {
    // console.log(row)
    // console.log(tempRow)
    setValidationErrors({})
    const rows = prevRows
    rows[row.index]=tempRow
    // console.log(rows)
    rowSetter([...rows])
  }

export const setRowPhone = (value, rowIndex, prevRows, setter, id="phone") => {
    const rows = prevRows
    rows[rowIndex].phone=value
    setter([...rows])
  }

export const setRowSelect = (value, rowIndex, prevRows, setter, id) => {
    // console.log(value)
    const rows = prevRows
    rows[rowIndex][id]=value
    setter([...rows])
  }

export const setRowAutocomplete = (newValue, rowIndex, prevRows, setter, id, cell, validationErrors, setValidationErrors) => {
    //console.log(newValue)
    const value=newValue
    if (newValue && newValue.inputValue) {
      value=newValue.inputValue
    }
    else if (newValue && newValue.value) {
      value=newValue.value
    }
    const rows = prevRows
    // console.log(value)
    rows[rowIndex][id]=value
    setter([...rows])
    if (!newValue){
      //set error
      setValidationErrors({
        ...validationErrors,
        // [cell.id]: `${cell.column.columnDef.header} is required`,
        [cell.id]: `This field is required`,
      })
    }
    else {
      //remove error
      delete validationErrors[cell.id];
      setValidationErrors({
        ...validationErrors,
      });
    }
  }

export const handleTextChange = (event, object, setter, setDirty=false) => {
    const value = event.target.value
    const id = event.target.id
    handleFieldChange(id, value, object, setter, setDirty)
  }

export const handleMaybeFieldChange = (id, event, object, setter, setDirty=false) => {
  const value = event.target.value
  console.log('event', event, 'object', object, 'id', id)
  if (value  !==  object[id]) {
    console.log(value, object[id])
    handleFieldChange(id, value, object, setter, setDirty)
  }
}

export const handleSelectChange = (id, event, object, setter, setDirty=false) => {
    const value = event.target.value
    handleFieldChange(id, value, object, setter, setDirty)
  }

  // const handleDateChange = (id, value, errorSetter) => {
  //   manufacturerProfile[id] = value
  //   setManufacturerProfile({...manufacturerProfile}, {[[id]]: value})
  //   if (errorSetter)
  //     setError(value, errorSetter, checkDateError)
  // }

  // const handlePhoneChange = (event, id) => {
  //   manufacturerProfile[id] = event
  //   setManufacturerProfile({...manufacturerProfile}, {[[id]]: event})
  // }

export const handleFieldChange = (id, value, object, setter, setDirty=false) => {
  const _object = { ...object }
  _object[id] = value
  setter(_object)
  if (setDirty) { setDirty() }
}

export const handlePropertyChange = (id, value, object, setter, setDirty=false) => {
    const _object = structuredClone(object)
    setProperty(_object, id, value)
    setter(_object)
    if (setDirty) { setDirty() }
  }

export const validateFieldChange = (id, value, object, setter, validator ) => {
  const _object = { ...object }
  _object[id] = value
  setter(_object)
  validator(_object)
}

export const handleAutoCompleteChange = (id, value, object, setter, setDirty=false) => {
    handleFieldChange(id, value, object, setter, setDirty)
  }

export const componentTextValue = (prop, def='') => {
  if ([null, undefined].includes(prop)) {
    return def
  } else {
    return prop
  }
}